import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import Input from '../../components/Input';
import appConst from '../../config/const';


import * as types from '../../redux/futures/tools/types';




class MoveAllLadiPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedType: appConst.MOVE_ALL_LADIPAGE.COPY,
            selectedStatus: appConst.MOVE_ALL_LADIPAGE_DELETE.NOT_DELETE,
            from_email: "",

            to_email: "",

            from_store_id: "", // thông tin thanh toán

            to_store_id: "",
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        // this.defaultState = _.cloneDeep(this.state.ladiPage);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {


            if (nextProps.action == types.MOVE_ALL_LADIPAGE) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    const _seft = this;
                    window.LadiUI.toastr('success', '', nextProps.message);
                    setTimeout(function () {
                        window.LadiUI.closeModal(_seft.ref.current);
                    }, 1000);

                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { from_email, to_email, from_store_id, to_store_id, selectedType, selectedStatus } = this.state;
        const jsonData = {
            from_email,
            to_email,
            from_store_id,
            to_store_id,
            type: selectedType.value,
            is_delete: selectedStatus.value
        }
        this.props.moveLP(jsonData);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    onChangeType = (selected) => {
        this.setState({
            selectedType: selected
        });
    }

    onChangeStatus = (selected) => {
        this.setState({
            selectedStatus: selected
        });
    }
    onChangeInput = (event) => {

        this.setState({

            [event.target.name]: event.target.value

        });
    }


    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            ladiPage: baseHelper.resetState(this.state.ladiPage),
        });
        window.LadiUI.closeModal(this.ref.current);

        // window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
        //     e.classList.remove('error');
        // });
    }

    render() {
        const { from_email, to_email, from_store_id, to_store_id, selectedType, selectedStatus } = this.state;


        const title = 'Di chuyển Ladipage ';

        return (
            <div ref={this.ref} id="modal-move-all-ladipage" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-move-all-ladipage"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-move-all-ladipage"><i className="ladi-icon icon-e-remove" data-dismiss="modal-move-all-ladipage" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui form-group condition-form">

                                <h3>Lựa cách thức chuyển</h3>
                            </div>
                            <div className="ladiui-row">
                                {
                                    _.map(appConst.MOVE_ALL_LADIPAGE, typeObject => {
                                        return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + typeObject.name}>

                                            <input type="radio"

                                                id={"package-type-move" + typeObject.value}
                                                name="selectedTypeMove"
                                                value={typeObject.value}
                                                onChange={() => this.onChangeType(typeObject)}
                                                // defaultChecked
                                                checked={selectedType.value == typeObject.value}
                                            />

                                            <label htmlFor={"package-type-move" + typeObject.value}>{typeObject.name}</label>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="ladiui form-group condition-form">

                                <h3>Lựa trạng thái</h3>
                            </div>
                            <div className="ladiui-row">
                                {
                                    _.map(appConst.MOVE_ALL_LADIPAGE_DELETE, typeObject => {
                                        return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + typeObject.name}>

                                            <input type="radio"

                                                id={"package-type-move" + typeObject.value}
                                                name="selectedStatusMove"
                                                value={typeObject.value}
                                                onChange={() => this.onChangeStatus(typeObject)}
                                                // defaultChecked
                                                checked={selectedStatus.value == typeObject.value}
                                            />

                                            <label htmlFor={"package-type-move" + typeObject.value}>{typeObject.name}</label>
                                        </div>
                                    })
                                }
                            </div>

                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email chủ sở hữu LandingPage</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="from_email"
                                                validationName="Email"
                                                placeholder="Chủ sở hữu LandingPage"
                                                value={from_email}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, email: true }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email nhận Ladipage</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="to_email"
                                                validationName="Email"
                                                placeholder="Email nhận Ladipage"
                                                value={to_email}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, email: true }}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Store sở hữu landing page</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="from_store_id"
                                                validationName="Domain"
                                                placeholder="Domain"
                                                value={from_store_id}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, min: 24, max: 24 }}
                                                onKeyPress={this.onKeyPress}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Store nhận landing page</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="to_store_id"
                                                validationName="Ladingpage ID"
                                                placeholder="Ladingpage ID"
                                                value={to_store_id}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, min: 24, max: 24 }}
                                                onKeyPress={this.onKeyPress}

                                            />
                                        </div>
                                    </div>


                                </div>


                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-move-all-ladipage">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-move-all-ladipage" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                        </button>

                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        moveLP: (data) => dispatch(toolActions.moveAllLadiPage(data))
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(MoveAllLadiPage);