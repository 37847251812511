import React from 'react';
import partnerActions from '../../../redux/futures/partner/actions';
import storeActions from '../../../redux/futures/store/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import ConfirmModal from '../../../components/ConfirmModal';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import * as types from '../../../redux/futures/partner/types';
import * as storeTypes from '../../../redux/futures/store/types';
import Input from '../../../components/Input';

class ChangeCredit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                credit: 0,
                credit_app: 0,
                money: 0,

                status: "",

                created_at: "",

                _id: "",

                pkg_name: ""

            },
            isCustomCredit: false,
            creditPack: {
                name: appConst.CREDIT_PACKAGES.PACK_1.name,
                value: appConst.CREDIT_PACKAGES.PACK_1.value,
                selected: appConst.CREDIT_PACKAGES.PACK_1.value,
                bonus: appConst.CREDIT_PACKAGES.PACK_1.bonus,
            },
            creditType: {
                name: appConst.CREDIT_TYPES.APP.name,
                value: appConst.CREDIT_TYPES.APP.value,
                selected: appConst.CREDIT_TYPES.APP.value,
            },

            is_test: appConst.IS_TESTING.NO.value,
            is_ladiflow_aff: appConst.IS_LADIFLOW_AFFILIATE.NO.value,
            note: '',
            credit_change: 0,
            money_note: 0,
            month: 0,
            deals: [],
            selectedDeal: {
                id: -1,
                title: 'Không chọn'
            },
            packages: [],
            selectedPackage: { pkg_name: false },
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            title: `Nạp ${appConst.CREDIT_TYPES.APP.name} cho khách hàng?`,
            content: `Bạn đang nạp ${appConst.CREDIT_PACKAGES.PACK_1.value} ${appConst.CREDIT_TYPES.APP.name}, xác nhận nạp ?`
        }
        this.inputsRef = new Set();
        this.defaultState = _.cloneDeep(this.state);
        delete this.defaultState.currentUser;
        this.ref = React.createRef();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.store.action === storeTypes.LIST_DEALS) {

            if (nextProps.store.status && nextProps.store.deals.length > 0) {
                let { appType } = this.state;


                this.setState({
                    deals: nextProps.store.deals,
                    // selectedDeal: nextProps.store.deals[0],
                });
            } else {

            }

        }
        if (this.props.currentUser !== nextProps.currentUser) {
            // console.log(nextProps.currentUser);
            this.setState({
                currentUser: _.pick(nextProps.currentUser, _.keys(this.state.currentUser)),
                ...this.defaultState
            }, () => {
                const { currentUser } = this.state;
                const data = {
                    store_name: '',
                    app_code: appConst.STORE_CONFIG.APP_CODE.LF.value,
                    limit: 20,
                    offset: 0,
                    email: currentUser.email

                };
                this.props.listStore(data);
            })
        }

        if (nextProps.store.action == storeTypes.LIST_STORE) {
            let { currentUser, selectedPackage, packages } = this.state;
            if (nextProps.store.status) {
                for (let i = 0; i < nextProps.store.stores.length; i++) {
                    if (nextProps.store.stores[i].ladi_uid == currentUser._id) {
                        currentUser.pkg_name = nextProps.store.stores[i].pkg_name;
                    }
                }
                for (let i = 0; i < packages.length; i++) {

                    if (currentUser.pkg_name == packages[i].pkg_name) {
                        selectedPackage = packages[i];
                    }
                }
                this.setState({
                    currentUser,
                    selectedPackage
                });
            } else {
            }
        }

        // console.log(nextProps.action);
        if (nextProps.partner.action == types.CHANGE_CREDIT) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.partner.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.partner.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                }, 1000);

            } else {
                window.LadiUI.toastr('error', '', nextProps.partner.message);
            }
        }

        if (nextProps.store.action === storeTypes.LIST_PACKAGE) {

            if (nextProps.store.status && nextProps.store.packages.length > 0) {

                let list = [];
                // window.LadiUI.toastr('success', '', nextProps.message);
                // {
                //     "name": "STANDARD",
                //     "code": "STANDARD",
                //     "price": 5000000,
                //     "level": 2
                // },
                let { selectedPackage, currentUser } = this.state;
                for (let i = 0; i < nextProps.store.packages.length; i++) {
                    // if (nextProps.packages[i].price == 0) {
                    nextProps.store.packages[i].pkg_name = nextProps.store.packages[i].code;
                    list.push(nextProps.store.packages[i]);
                    // }
                    if (currentUser.pkg_name == nextProps.store.packages[i].pkg_name) {
                        selectedPackage = nextProps.store.packages[i];
                    }
                }

                list = list.sort(function (a, b) {
                    return a.level - b.level;
                });
                if (!selectedPackage.pkg_name) {
                    selectedPackage = list[0];
                }
                // console.log(list, selectedPackage);
                this.setState({
                    packages: list,
                    selectedPackage: selectedPackage,
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.store.message);
            }

            // this.list();
        }
    }

    onChangePackage = (event) => {
        console.log(' :onChangePackage', event);
        this.pickPackages(event.target.value);
        // this.setState({
        //     selectedPackage: {
        //         package_name: event.target.value
        //     }
        // }, () => {
        //     this.calculateMoney();
        // });
    }

    pickPackages(name) {
        const { packages } = this.state;

        for (let i = 0; i < packages.length; i++) {
            if (packages[i].pkg_name === name) {

                this.setState({
                    selectedPackage: packages[i]
                });
                break;
            }
        }

    }


    confirmAddCredit = () => {
        window.LadiUI.showModalById('confirm-change-credit');
    }

    submit = () => {

        // Validate
        let errors = [];
        _.map(this.inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        let { currentUser, creditPack, creditType, note, isCustomCredit, credit_change, money_note, month, is_test, selectedPackage, is_ladiflow_aff, selectedDeal } = this.state;
        let isAffFlow = false;
        if (is_ladiflow_aff) {
            isAffFlow = (is_ladiflow_aff == 'true' ? true : false);
        }
        let data = {
            ladi_uid: currentUser._id,
            credit: creditPack.selected,
            credit_type: creditType.selected,
            bonus: creditPack.bonus,
            description: note,
            money_note,
            month,
            pkg_name: selectedPackage.pkg_name,
            is_test,
            is_ladiflow_aff: isAffFlow,
            deal_id: selectedDeal.id > 0 ? selectedDeal.id : false
        };
        if (creditType.value != appConst.CREDIT_TYPES.APP.value) {
            is_ladiflow_aff = false;
        }
        if (isCustomCredit) {
            data.credit = parseInt(credit_change);
            data.bonus = 0;
        }
        window.LadiUI.closeModalById('confirm-change-credit');
        // console.log('data = ', data);
        // return;
        this.props.changeCredit(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    onChangeInput = (event) => {

        const { creditType, credit_change, currentUser } = this.state;
        const content = `Bạn đang nạp ${parseInt(event.target.value)} ${creditType.name} cho tài khoản: ${currentUser.fullname}, xác nhận nạp ?`;
        this.setState({
            credit_change: event.target.value,
            content
        });
    }

    onChangeMoneyNote = (event) => {
        this.setState({
            money_note: event.target.value,
        });
    }
    onChangeMonth = (event) => {
        this.setState({
            month: event.target.value,
        });
    }

    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value

        });
    }

    onChangeCreditPack = (option) => {
        const { creditType, currentUser } = this.state;
        const content = `Bạn đang nạp ${option.value} ${creditType.name} cho tài khoản: ${currentUser.fullname}, xác nhận nạp ?`;
        this.setState({
            isCustomCredit: false,
            creditPack: { ...this.state.creditPack, selected: option.value, bonus: option.bonus },
            content
        })
    }

    onChangeCreditType = (option) => {
        let { creditPack, creditType, currentUser } = this.state;
        let listPack = appConst.CREDIT_PACKAGES;

        if (option.value == appConst.CREDIT_TYPES.MONEY.value) {
            listPack = appConst.MONEY_PACKAGES;
        }
        if (option.value == appConst.CREDIT_TYPES.APP.value || option.value == appConst.CREDIT_TYPES.PARTNER.value) {
            listPack = appConst.CREDIT_PACKAGES;
        }
        creditPack = {
            name: listPack.PACK_1.name,
            value: listPack.PACK_1.value,
            selected: listPack.PACK_1.value,
            bonus: listPack.PACK_1.bonus,
        }
        creditType = {
            name: option.name,
            value: option.value,
            selected: option.value,
        }
        const content = `Bạn đang nạp ${creditPack.value} ${creditType.name} cho tài khoản: ${currentUser.fullname}, xác nhận nạp ?`;
        const title = `Nạp ${creditType.name} cho khách hàng?`;

        this.setState({
            creditType,
            creditPack,
            content,
            title
        })
    }

    customCredit = () => {
        const { isCustomCredit, creditType, credit_change, currentUser } = this.state;
        const content = `Bạn đang nạp ${parseInt(credit_change)} ${creditType.name} cho tài khoản: ${currentUser.fullname}, xác nhận nạp ?`;
        this.setState({
            isCustomCredit: !isCustomCredit,
            content
        })
    }
    onChangeIsTest = (event) => {
        this.setState({
            is_test: event.target.value,
        });
    }
    onChangeDeal = (option) => {
        this.setState({ selectedDeal: option });
    }
    onChangeIsAffiliate = (event) => {

        this.setState({
            is_ladiflow_aff: event.target.value,
        });
    }


    render() {
        const { currentUser, creditPack, creditType, isCustomCredit, credit_change, content, money_note, month, title, is_test, packages, selectedPackage, is_ladiflow_aff, deals, selectedDeal } = this.state;
        const titleModal = 'Nap credit cho tài khoản: ' + currentUser.fullname;

        let listPack = appConst.CREDIT_PACKAGES;
        if (creditType.selected == appConst.CREDIT_TYPES.MONEY.value) {
            listPack = appConst.MONEY_PACKAGES;
        }
        return (
            <div ref={this.ref} id="modal-user-addcredit" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-user-addcredit">
                <div className="ladiui-modal-content" style={{ width: '500px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-user-addcredit"><i className="ladi-icon icon-e-remove" data-dismiss="modal-user-addcredit" /></button>
                        <h3>{titleModal}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {/* <div className="ladiui-tab-content"> */}
                        <div className="point-box" style={{ margin: '15px' }}>
                            {/* <ul> */}
                            <div className="ladiui-row flex">
                                <div className="col-50" style={{ height: "40px" }}>
                                    <input type="radio"
                                        id="is-testing-credit"
                                        name="is_test_credit"
                                        value={appConst.IS_TESTING.YES.value}
                                        onChange={this.onChangeIsTest}
                                    />
                                    <label htmlFor="is-testing-credit">{appConst.IS_TESTING.YES.name}</label>
                                </div>


                                <div className="col-50" style={{ height: "40px" }}>
                                    <input type="radio"
                                        name="is_test_credit"
                                        id="is-not-testing-credit"
                                        value={appConst.IS_TESTING.NO.value}
                                        onChange={this.onChangeIsTest}
                                        defaultChecked
                                    />
                                    <label htmlFor="is-not-testing-credit">{appConst.IS_TESTING.NO.name}</label>
                                </div>

                            </div>
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">
                                    <strong>Email: </strong>{this.state.currentUser.email}
                                </div>
                            </div>
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">
                                    <strong>Partner Credit: </strong>
                                    {
                                        this.state.currentUser.credit && baseHelper.numberWithCommas(this.state.currentUser.credit.toFixed(2))
                                    }
                                </div>
                            </div>
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">
                                    <strong>Điểm : </strong>
                                    {
                                        this.state.currentUser.credit_app && baseHelper.numberWithCommas(this.state.currentUser.credit_app.toFixed(2))
                                    }
                                </div>
                            </div>
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">
                                    <strong>Số dư tài khoản: </strong>
                                    {
                                        this.state.currentUser.money && baseHelper.numberWithCommas(this.state.currentUser.money.toFixed(2))
                                    }
                                </div>
                            </div>
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">

                                    <label className="ladiui-label">Chọn hình thức nạp</label>
                                    <div className="ladiui-select-options" style={{ zIndex: 6 }}>

                                        <span className="ladiui-select-init">{
                                            creditType.name
                                        }</span>
                                        <input type="hidden" name="select" defaultValue />
                                        <ul>
                                            {
                                                _.map(appConst.CREDIT_TYPES, option =>
                                                    <li key={option.value} className="select-item" onClick={() => this.onChangeCreditType(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">

                                    <label className="ladiui-label">
                                        {
                                            (creditType.selected == appConst.CREDIT_TYPES.APP ||
                                                creditType.selected == appConst.CREDIT_TYPES.APP)
                                            &&
                                            `Số nạp 1 Credit tương đương 1000VND`
                                        }
                                        {
                                            creditType.selected == appConst.CREDIT_TYPES.MONEY &&
                                            `Số nạp chính là số tiền VND`
                                        }
                                    </label>
                                    <div className="ladiui-select-options">

                                        <span className="ladiui-select-init">{
                                            creditPack.name
                                        }</span>
                                        <input type="hidden" name="select" defaultValue />
                                        <ul>
                                            {
                                                _.map(listPack, option =>
                                                    <li key={option.value} className="select-item" onClick={() => this.onChangeCreditPack(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                )
                                            }
                                            <li className="select-item" onClick={this.customCredit}><a href="#" data-value="CUSTOM">Tùy chỉnh</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            {isCustomCredit &&
                                <div className="ladiui-row">
                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">Nhập tùy chỉnh credit</label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="point_change"
                                            validationName="CreditChange"
                                            placeholder="Nhập số từ 1000 đến 50000"
                                            value={credit_change || ""}
                                            onChange={this.onChangeInput}
                                            type="number"
                                            onKeyPress={this.onKeyPress}
                                            validations={{ isRequired: true, min: 0, max: 50000 }}
                                            style={{ width: '440px' }}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="ladiui-row" >


                                <div className="ladiui form-group">
                                    <strong>Lí do: </strong>
                                    <textarea name="note" className="ladiui textarea ladi-payment-info"
                                        value={this.state.note} onChange={this.onChangeTextArea} />
                                </div>

                            </div>
                            <div className="ladiui-row flex">
                                <div className="col-50" style={{ height: "40px" }}>
                                    <input type="radio"
                                        id="is-aff-ladiflow"
                                        name="is_aff_ladiflow"
                                        value={appConst.IS_LADIFLOW_AFFILIATE.YES.value}
                                        onChange={this.onChangeIsAffiliate}
                                    />
                                    <label htmlFor="is-aff-ladiflow">{appConst.IS_LADIFLOW_AFFILIATE.YES.name}</label>
                                </div>


                                <div className="col-50" style={{ height: "40px" }}>
                                    <input type="radio"
                                        name="is_aff_ladiflow"
                                        id="is-not-aff-ladiflow"
                                        value={appConst.IS_LADIFLOW_AFFILIATE.NO.value}
                                        onChange={this.onChangeIsAffiliate}
                                        defaultChecked
                                    />
                                    <label htmlFor="is-not-aff-ladiflow">{appConst.IS_LADIFLOW_AFFILIATE.NO.name}</label>
                                </div>

                            </div>
                            {deals.length > 0 && creditType.selected == appConst.CREDIT_TYPES.APP.value &&
                                <React.Fragment>

                                    <div className="ladiui form-group condition-form">

                                        <h3>Lựa chọn Deal</h3>
                                    </div>
                                    <div className="ladiui-row">
                                        <div className="ladiui form-group actions">
                                            <div className="action-item">
                                                <div className="ladiui-select-options">
                                                    <span className="ladiui-select-init">{selectedDeal.title}</span>
                                                    <input type="hidden" name="select" defaultValue />
                                                    <ul>
                                                        <li key={-1} className="select-item" onClick={() => this.onChangeDeal({
                                                            id: -2,
                                                            title: 'Không chọn'
                                                        })}><a href="#" data-value={-1}>Không chọn</a></li>
                                                        {
                                                            _.map(deals, option =>
                                                                <li key={option.id} className="select-item" onClick={() => this.onChangeDeal(option)}><a href="#" data-value={option.id}>{`${option.title} (${option.username})`}</a></li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {creditType.selected == appConst.CREDIT_TYPES.APP.value
                                &&
                                <React.Fragment>
                                    <div className="ladiui form-group condition-form">

                                        <h3>Lựa chọn gói dịch vụ</h3>
                                    </div>
                                    <div className="ladiui-row">
                                        {
                                            _.map(packages, (packageObj, index) => {
                                                // if (packageObj.price != undefined && packageObj.price == 0) {
                                                //     return <div key={"package-" + packageObj.name}></div>
                                                // }
                                                let displayName = packageObj.code;
                                                return <div className="ladiui form-group" style={{ height: "40px" }} key={index}>

                                                    <input type="radio"

                                                        id={"package-flow-" + packageObj.pkg_name}
                                                        name="selectedPackageFlow"
                                                        value={packageObj.pkg_name}
                                                        onChange={this.onChangePackage}
                                                        // defaultChecked
                                                        checked={selectedPackage.pkg_name == packageObj.pkg_name}
                                                    />

                                                    <label htmlFor={"package-flow-" + packageObj.pkg_name}>{displayName}</label>
                                                </div>
                                            })
                                        }
                                    </div>
                                </React.Fragment>
                            }

                            <div className="ladiui-row">
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Nhập Số tiền thực nhận của khách</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="money_note"
                                        validationName="Mone note"
                                        placeholder="Nhập số tiền (VND)"
                                        value={money_note + ""}
                                        onChange={this.onChangeMoneyNote}
                                        type="number"
                                        onKeyPress={this.onKeyPress}
                                        validations={{ isRequired: true, min: 1000 }}
                                        style={{ width: '440px' }}
                                    />
                                </div>
                            </div>
                            {
                                creditType.selected == appConst.CREDIT_TYPES.APP.value &&
                                <div className="ladiui-row">
                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">Nhập Số tháng </label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="month"
                                            validationName="Month"
                                            placeholder="Nhập Số tháng"
                                            value={month + ""}
                                            onChange={this.onChangeMonth}
                                            type="number"
                                            onKeyPress={this.onKeyPress}
                                            validations={{ isRequired: true, min: 1 }}
                                            style={{ width: '440px' }}
                                        />
                                    </div>
                                </div>
                            }

                        </div>
                        {/* </div> */}
                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-user-addcredit">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-user-addcredit" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.confirmAddCredit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                        </button>

                    </div>
                </div>
                <ConfirmModal
                    id="confirm-change-credit"
                    title={title}
                    content={content}
                    cancelText="Huỷ"
                    okText="Áp dụng"
                    onOk={this.submit}
                    isLoading={this.state.loading}
                />
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCredit: (data) => dispatch(partnerActions.changeCredit(data)),
        listStore: (data) => dispatch(storeActions.list(data)),
    }
}

const mapStateToProps = (state) => ({ partner: { ...state.partner }, store: { ...state.store }, });

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCredit);