import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const listPointLog = (data) => ({
    type: types.LIST_POINT_LOGS,
    meta: {
        endpoint: endpoint.LIST_POINT_LOGS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
 
const changePoint = (data) => ({
    type: types.CHANGE_POINT,
    meta: {
        endpoint: endpoint.CHANGE_POINT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    listPointLog,
    changePoint
}