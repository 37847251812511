import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.AFFILATE_LIST,
    meta: {
        endpoint: endpoint.AFFILATE_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const myAffilateInfo = () => ({
    type: types.MY_AFFILATE_INFO,
    meta: {
        endpoint: endpoint.MY_AFFILATE_INFO,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const updatePaymentInfo = (data) => ({
    type: types.UPDATE_PAYMENT_INFO,
    meta: {
        endpoint: endpoint.UPDATE_PAYMENT_INFO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const createAffilate = (data) => ({
    type: types.ACTIVE_AFFILATE,
    meta: {
        endpoint: endpoint.ACTIVE_AFFILATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const updateAffilate = (data) => ({
    type: types.UPDATE_AFFILATE,
    meta: {
        endpoint: endpoint.UPDATE_AFFILATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const checkEmail = (data) => ({
    type: types.CHECK_AFFILATE_EMAIL,
    meta: {
        endpoint: endpoint.CHECK_AFFILATE_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const checkCode = (data) => ({
    type: types.CHECK_AFFILATE_CODE,
    meta: {
        endpoint: endpoint.CHECK_AFFILATE_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const findAffilateInfo = (data) => ({
    type: types.GET_AFFILATE_INFO,
    meta: {
        endpoint: endpoint.GET_AFFILATE_INFO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    list,
    updatePaymentInfo,
    myAffilateInfo,
    createAffilate,
    updateAffilate,
    checkEmail,
    checkCode,
    findAffilateInfo
}