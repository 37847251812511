import React from 'react';
import routes from '../../../routes';
import _ from 'lodash';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';

export default class Sidebar extends React.Component {

    componentDidMount() {
        window.LadiUI.init();
    }
    render() {
        const adminInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        const availableItem = routes.getItemByScopes(adminInfo.scopes);
        return (
            <aside className="ladiui-aside">
                <nav className="ladiui nav nav-accordion">
                    <ul key="home_ul" className="ladiui menu">
                        {/* <SidebarItem
                            keyStr={['']}
                            key="home"
                            classLi="ladiui menu-item"
                            to="/profile"
                            classNav="ladiui-accordion"
                            classIcon="ladi-icon icon-home"
                            title="Profie"
                        /> */}
                        {
                            _.map(availableItem, item => {  
                                return item;
                            })
                        }
                    </ul>
                </nav>
                <div className="ladiui setting-bottom">
                    <a href="https://ladipage.com">© LadiPage 2019.</a>
                </div>
            </aside>
        );
    }
}
