import React from 'react';
import orderActions from '../../../redux/futures/order/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import * as types from '../../../redux/futures/order/types';

class Detail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            order: {
                ladi_uid: '',
                _id: '',
                reference_no: '',
                discount: '',
                total: '',
                note: '',
                status: ''
            },
            affilate_code: '',
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
        }
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    // componentDidMount() {
    //     this.getDetail();
    // }

    componentDidUpdate() {
        window.LadiUI.init();
    }


    componentWillReceiveProps(nextProps) {


        if (this.props.currentOrder !== nextProps.currentOrder) {
            this.setState({
                order: _.pick(nextProps.currentOrder, _.keys(this.state.order))
            })
        }
        if (nextProps.action == types.RE_CREATE_COMMISSION) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                }, 1000);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

    }

    submit = () => {

        // Validate
        // let errors = [];
        // const inputsRef = _.compact(Array.from(this.inputsRef));
        // _.map(inputsRef, ref => {
        //     // console.log(ref);
        //     if (ref.validate) {
        //         errors = errors.concat(ref.validate());
        //     }
        // });

        // _.compact(errors);
        // if (errors.length > 0) {
        //     const errorMessage = errors.join("<br/>");
        //     window.LadiUI.toastr('error', '', errorMessage);
        //     return;
        // }

        const { order, affilate_code } = this.state;
        const { affilate } = this.props;
        if ((!affilate_code || affilate_code.length == 0) && !affilate && !affilate.code) {
            // console.log('return affilate_code =', affilate_code);
            return;
        }

        const jsonData = {
            "order_id": order._id,
            "ladi_uid": order.ladi_uid,
        }
        if (affilate_code) {
            jsonData.affilate_code = affilate_code;
        } else {
            if (affilate && affilate.code) {
                jsonData.affilate_code = affilate.code;
            }
        }
        console.log('create: ', jsonData);
        this.props.reCreateCommission(jsonData);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    onChangeInput = (event) => {

        this.setState({
            affilate_code: event.target.value

        });
    }


    render() {
        const { affilate_code } = this.state;
        const { affilate } = this.props;

        return (
            <div id="modal-add-commission" ref={this.ref} className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-add-commission" style={{ zIndex: 21 }}>
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-add-commission"><i className="ladi-icon icon-e-remove" data-dismiss="modal-add-commission" /></button>
                        <h3>Mã đơn : {this.state.order.reference_no}</h3>
                    </div>
                    <div className="ladiui-modal-body">

                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">Affiliate Code</label>
                                        {
                                            affilate && affilate.code ?
                                                <input name="code" className="ladiui input" value={affilate.code}
                                                    disabled />
                                                :
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="code"
                                                    validationName="Code"
                                                    placeholder="Code"
                                                    value={affilate_code}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: true }}
                                                />
                                        }
                                    </div>


                                </div>
                                {
                                    affilate && affilate.code &&
                                    <div className="ladiui-row">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Affiliate email: {affilate.email}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </form>

                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-add-commission">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-add-commission" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                        </button>
                        {/* {(this.props.loading == types.UPDATE_PAYMENT_INFO) && <Spinner />} */}
                    </div>
                </div>

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reCreateCommission: (order) => dispatch(orderActions.reCreateCommission(order))
    }
}

const mapStateToProps = (state) => ({ ...state.order });

export default connect(mapStateToProps, mapDispatchToProps)(Detail);