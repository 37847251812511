import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.LIST_ORDER,
    meta: {
        endpoint: endpoint.ORDER_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listDetail = (data) => ({
    type: types.LIST_ORDER_DETAIL,
    meta: {
        endpoint: endpoint.LIST_ORDER_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const deleteOrders = (orderIds) => ({
    type: types.DELETE_ORDERS,
    meta: {
        endpoint: endpoint.ORDER_DELETE_IDS,
        method: REQUEST_METHOD.POST,
        body: {
            order_ids: orderIds
        },
        hasAuth: true,
    }
});

const deleteOrder = (orderId) => ({
    type: types.DELETE_ORDER,
    meta: {
        endpoint: endpoint.ORDER_DELETE_ID,
        method: REQUEST_METHOD.POST,
        body: {
            order_id: orderId
        },
        hasAuth: true,
    }
});

const create = (order) => ({
    type: types.CREATE_ORDER,
    meta: {
        endpoint: endpoint.ORDER_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            order: order
        },
        hasAuth: true,
    }
});

const update = (order) => ({
    type: types.UPDATE_ORDER,
    meta: {
        endpoint: endpoint.ORDER_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            order: order
        },
        hasAuth: true,
    }
});


const getDetail = (data) => ({
    type: types.DETAIL_ORDER,
    meta: {
        endpoint: endpoint.ORDER_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const myOrders = (data) => ({
    type: types.MY_ORDER,
    meta: {
        endpoint: endpoint.MY_ORDER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const cancel = (data) => ({
    type: types.CANCEL_ORDER,
    meta: {
        endpoint: endpoint.CANCEL_ORDER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const paidOrder = (data) => ({
    type: types.PAID_ORDER,
    meta: {
        endpoint: endpoint.PAID_ORDER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const reCreateCommission = (data) => ({
    type: types.RE_CREATE_COMMISSION,
    meta: {
        endpoint: endpoint.RE_CREATE_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    list,
    listDetail,
    deleteOrders,
    deleteOrder,
    create,
    update,
    getDetail,
    myOrders,
    cancel,
    paidOrder,
    reCreateCommission
}