import appConst from '../config/const';
import moment from 'moment';
import Cookies from 'universal-cookie';
import date from 'date-and-time';

class BaseHelper {

    formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    printObj(obj) {
        let string = '';

        for (let prop in obj) {
            if (typeof obj[prop] == 'string') {
                string += prop + ': ' + obj[prop] + '; </br>';
            }
        }

        return string;
    }


    addMonth(theDate, months) {
        // months phải là số nếu không trước khi truyền vào hãy dùng hàm parseInt(months)
        return date.addMonths(theDate, months);
    }

    formatStrToDate(strDate, format = appConst.DEFAULT_FORMAT_DATE) {
        try {
            if (strDate == '' || strDate == null) return '';
            const date = new Date(strDate);
            return moment(date).format(format);
        } catch (e) {

        }

        return '';
    }

    formatNumber(str, _default = 0) {
        if (!str) {
            return 0;
        }

        return str.toLocaleString('en-EN', { minimumFractionDigits: _default })
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    resetState(state) {
        const newState = { ...state }
        resetValueObject(newState);
        return newState;
    }

    setCookie(key, value, day) {
        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + day);
        const cookies = new Cookies();
        cookies.set(key, value, { path: '/', expires: tomorrow });
    }

    getCookie(key) {
        const cookies = new Cookies();
        return cookies.get(key);
    }

    removeCookie(key) {
        const cookies = new Cookies();
        cookies.remove(key);
    }

    getTimeZoneInfo(zone) {
        // create Date object for current location
        var d = new Date();

        // convert to msec
        // add local time zone offset
        // get UTC time in msec
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

        // create new Date object for different city
        // using supplied offset
        var nd = new Date(utc + (3600000 * zone));

        // return time as a string
        return {
            nd: nd.toLocaleString()
        };

    }

    getFirstLetter(name) {
        if (name) {
            const array1 = name.split(' ');
            let newarray1 = [];

            for (let x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].trim().charAt(0).toUpperCase());
            }
            const str = newarray1.join(' ');
            if (str.length > 2) {
                return str.substring(0, 3);
            }
            return str;
        }
        return 'UID';
    }

    imageExists(image_url) {
        const http = new XMLHttpRequest();

        http.open('HEAD', image_url, false);
        http.send();

        return http.status != 404;

    }

    getDayName(dateStr, locale) {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: 'long' }).substring(0, 3);
    }

    diffDateDay(dt1, dt2) { //So sánh ngày dt1 hơn ngày dt2 bao nhiêu ngay

        const diffTime = dt2.getTime() - dt1.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    parseTime(date) {

        let dd = date.getDate();
        let mm = date.getMonth() + 1; //January is 0!
        const yyyy = date.getFullYear();
        const hh = date.getHours();
        let minute = date.getMinutes();
        let ss = date.getSeconds();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        if (minute < 10) {
            minute = '0' + minute;
        }
        if (ss < 10) {
            ss = '0' + ss;
        }
        return yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + minute + ':' + ss;
    }

    parseSecondToTime(totalSeconds) {
        let days = 0;
        if (totalSeconds >= 86400) {
            days = Math.floor(totalSeconds / 86400);
            totalSeconds %= 86400;
        }
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;

        // If you want strings with leading zeroes:
        if (days > 0) {
            days = String(days).padStart(2, "0");
        }
        minutes = String(minutes).padStart(2, "0");
        hours = String(hours).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");

        return (days > 0) ? days + " - " + hours + ":" + minutes + ":" + seconds : hours + ":" + minutes + ":" + seconds;;
    }

    getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }
}

function resetValueObject(obj) {
    if (obj !== null && typeof obj === 'object') {
        for (const prop in obj) {
            if (typeof obj[prop] === 'object') {
                resetValueObject(obj[prop]);
            }

            if (typeof obj[prop] === 'string' || obj[prop] instanceof String) {
                obj[prop] = '';
            }
            if (typeof obj[prop] === 'number' || obj[prop] instanceof String) {
                obj[prop] = 0;
            }
            if (typeof obj[prop] === 'boolean' || obj[prop] instanceof String) {
                obj[prop] = true;
            }
        }
    }
}
export default new BaseHelper();