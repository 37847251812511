import React from 'react';

import invoiceActions from '../../redux/futures/invoice/actions';
import _ from 'lodash';
import { connect } from 'react-redux';

import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import Detail from './components/Detail';

import LoadingTable from '../../components/LoadingTable';
import * as types from '../../redux/futures/invoice/types';
import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import ConfirmModal from '../../components/ConfirmModal';
// import "react-datepicker/dist/react-datepicker.css";



class Invoice extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            fromDate: firstDay,
            toDate: new Date(),
            searchStatus: {
                name: appConst.INVOICE_STATUS.ALL.name,
                value: appConst.INVOICE_STATUS.ALL.value,
                selected: appConst.INVOICE_STATUS.ALL,
            },
            code: "",
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            invoiceList: [],
            excelDatas: [],
            invoiceID: '',
            // downloadUrl: '',
            submitData: false,
            isSendMail:false,
        };
        this.selectedInvoiceIds = [];
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentDidMount() {
        this.getList();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.INVOICE_LIST) {

            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
                this.setState({
                    excelDatas: nextProps.excelDatas
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
        if (nextProps.action == types.EXPORT_INVOICE) {
            setTimeout(() => {
                this.setState({
                    submitData: false
                });
            }, 200);
            if (nextProps.status) {

                // this.setState({
                //     downloadUrl: nextProps.url
                // });
            }
        }

        if (nextProps.action == types.PAID_INVOICE) {
            if (nextProps.status) {
                window.LadiUI.toastr('success', '', nextProps.message);
                const that = this;
                setTimeout(() => {
                    window.LadiUI.closeModalById('confirm-paid-invoice');
                     
                    that.getList();
                }, 200);
                
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

        if (nextProps.action == types.PAID_ALL_INVOICE) {
            if (nextProps.status) {
                window.LadiUI.toastr('success', '', nextProps.message);
                const that = this;
                setTimeout(() => {
                    window.LadiUI.closeModalById('confirm-paid-invoice');
                     
                    that.getList();
                }, 200);
                
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }


    paid = (selectedID, isSendMail) => {
      
        this.selectedID = selectedID;
        this.setState({
            isSendMail
        },() => {
            window.LadiUI.showModalById('confirm-paid-invoice');
        });
        
        


        // this.props.deleteOrders(this.selectedInvoiceIds);
    }

    exportExcelAll = () => {
        const { excelDatas, submitData } = this.state;
        if (submitData) return;
        if (excelDatas.length == 0) {
            window.LadiUI.toastr('error', '', 'Data empty');
            return;
        }
        const data = {
            ids: excelDatas
        };
        this.setState({
            submitData: true
        });
        this.props.exportExcel(data);


    }

    exportList = () => {
        const { submitData } = this.state;
        if (submitData) return;
        if (this.selectedInvoiceIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Hãy lựa chọn ít nhất 1 bản ghi');
            return;
        }
        const data = {
            ids: this.selectedInvoiceIds
        };
        this.setState({
            submitData: true
        });
        this.props.exportExcel(data);
    }

    paidAll = (isSendMail) => {
        const { submitData } = this.state;
        if (submitData) return;
        if (this.selectedInvoiceIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Hãy lựa chọn ít nhất 1 bản ghi');
            return;
        }
        
        this.setState({
            submitData: true,
            is_send_mail: isSendMail
        },() => {
            window.LadiUI.showModalById('confirm-paidall-invoice');
        });
        
    }

    getList = (resetActivePageFlag = true) => {


        const statusSelected = this.state.searchStatus.selected;

        const { searchOrderNo, selectedLimit, code, invoiceID } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
       
        let { fromDate } = this.state;
        const frDate = new Date(fromDate);
        frDate.setMonth(frDate.getMonth() + 1);
        const toDate = new Date(frDate);
        frDate.setHours(0, 0, 0, 0);
        toDate.setHours(23, 59, 59, 999);

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "status": statusSelected,
            "from_date": fromDate,
            "to_date": toDate,
            "code": code,
            "invoice_id":invoiceID
        };

        // this.props.myInvoice(data);
        this.props.list(data);

    }
    checkItem = (event, invoiceId) => {
        const { checked } = event.target;
        const { selectedInvoiceIds } = this;

        if (checked) {
            if (!_.includes(selectedInvoiceIds, invoiceId)) {
                selectedInvoiceIds.push(invoiceId);
            }

            if (selectedInvoiceIds.length == this.props.invoices.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedInvoiceIds, selectedCommissionId => selectedCommissionId == invoiceId);
        }


    }
    onChangeInput = (event) => {

        // this.setState({
        //     userInfo: {
        //         ...this.state.userInfo,
        //         [event.target.name]: event.target.value
        //     }
        // });
    }

    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.getList();
        })
    }

    view = (currentInvoice) => {
        const data = {
            "id": currentInvoice._id
        };
        this.setState({
            currentInvoice
        }, () => {
            this.props.invoiceDetail(data);
        });

    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.getList();
        }
    }


    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedInvoiceIds = [];
        if (checked) {
            this.selectedInvoiceIds = _.map(this.props.invoices, invoice => invoice._id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
        console.log('checkAllItem:',this.selectedInvoiceIds);
    }

    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.getList();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.getList(false);
        });
    }

    render() {
        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchStatus, searchOrderNo, resetForm, code, invoiceID } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.invoices || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.invoices.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.INVOICE_LIST);
        const isLoadingPaid = this.props.loading && (this.props.waitting == types.PAID_INVOICE);
        return (
            <div>
                <h1 className="page-title">Hoá đơn Affiliate</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">
                            <ul className="ladiui-navbar -horizontal">
                                <li className="ladiui-dropdown"><a href="#" className={
                                    "ladiui button outline-primary" + (this.state.submitData ? " loader" : "")

                                }>Thao tác <i
                                    className="ladi-icon icon-down-arrow"></i></a>
                                    <ul>
                                        <li><a onClick={this.exportExcelAll}>Lưu tất cả</a></li>
                                        <li><a onClick={this.exportList}>Lưu file các dòng đã chọn</a></li>
                                        <li><a onClick={()=>this.paidAll(true)}>Tất toán và gửi mail</a></li>
                                        <li><a onClick={()=>this.paidAll(false)}>Tất toán nhưng không gửi mail</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="action-item">
                            {
                                (this.state.downloadUrl != '') &&
                                <a href={this.state.downloadUrl}><button className="ladiui button primary">{this.state.downloadUrl != '' ? 'Tải xuống' : ''}</button>
                                </a>
                            }
                        </div> */}
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                        <div className="action-item">

                        </div>
                        {/* <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div> */}
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo mã code" name="code" value={code} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.getList} />
                            </div>
                        </div>
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo mã hoá đơn" name="invoiceID" value={invoiceID} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.getList} />
                            </div>
                            </div>
                        
                        {/* <div className="action-item">
                            <span className="title-date">Status</span>
                        </div> */}
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchStatus.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.INVOICE_STATUS, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchStatus(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>

                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th>
                                                <th className="text-left">Mã ref</th>
                                                {/* <th className="text-left">Email</th> */}
                                                <th className="text-left">Số tiền</th>
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Ngày tạo</th>
                                                {/* <th name="created_at" onClick={this.sort} className="text-left sorting">Ngày tất toán</th> */}
                                                <th name="status" onClick={this.sort} className="text-left sorting">Trạng thái</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.invoices, invoice => {

                                                    return <tr key={invoice._id}>
                                                        <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, invoice._id)} className="ladiui checkbox" />
                                                        </td>
                                                        <td>{invoice.code}</td>
                                                        {/* <td>{invoice.email}</td> */}
                                                        <td>{invoice.amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                        <td>{baseHelper.formatStrToDate(invoice.created_at)}</td>
                                                        {/* <td>{baseHelper.formatStrToDate(invoice.pay_date)}</td> */}

                                                        <td>{invoice.status}</td>
                                                        <td>

                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>
                                                                        {/* <li><a onClick={() => this.print(invoice)} className="ladiui open-modal" modal="modal-print" >Print</a></li> */}
                                                                        <li><a onClick={() => this.view(invoice)} className="ladiui open-modal" modal="modal-invoicedetail" >Chi tiết</a></li>
                                                                        <li><a onClick={() => this.paid(invoice._id, true)} >Tất toán và gửi mail</a></li>
                                                                        <li><a onClick={() => this.paid(invoice._id, false)}>Tất toán nhưng không gửi mail</a></li>
                                                                        

                                                                    </ul>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                                totalRecord <= 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="100%">Không có bản ghi nào</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    {
                        totalRecord > 0 &&
                        <div className="ladiui pagination-block">
                            <div className="ladiui search-actions">
                                <div className="ladiui form-filter">
                                    <div className="ladiui data-limit">
                                        <span>Show</span>
                                        <div className="ladiui per-page">
                                            <div className="ladiui-select-options">
                                                <span className="ladiui-select-init">{selectedLimit}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui showing">{msgShowItem}</div>
                                </div>
                            </div>
                            <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>


                        </div>
                    }
                </div>
                <Detail
                    currentInvoice={this.state.currentInvoice}
                // mode={this.state.mode}
                />
                <ConfirmModal
                    id="confirm-paid-invoice"
                    title="Tất toán hoá đơn và gửi email"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.props.paidInvoice({
                        id: this.selectedID,
                        is_send_mail: this.state.isSendMail
                    })}
                    isLoading={isLoadingPaid}
                />

                <ConfirmModal
                    id="confirm-paidall-invoice"
                    title="Tất toán hoá đơn và gửi email"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.props.paidAll({
                        ids: this.selectedInvoiceIds,
                        is_send_mail: this.state.isSendMail
                    })}
                    isLoading={isLoadingPaid}
                />

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(invoiceActions.list(data)),
        invoiceDetail: (data) => dispatch(invoiceActions.invoiceDetail(data)),
        exportExcel: (data) => dispatch(invoiceActions.exportExcel(data)),
        paidInvoice: (data) => dispatch(invoiceActions.paidInvoice(data)),
        paidAll: (data) => dispatch(invoiceActions.paidAll(data)),

    }
}

const mapStateToProps = (state) => ({ ...state.invoice });

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);