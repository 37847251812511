import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';

import { connect } from 'react-redux';
import storeActions from '../../redux/futures/store/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/store/types';
import AddStorage from './components/AddStorage';
import LoadingTable from '../../components/LoadingTable';
import ConfirmModal from '../../components/ConfirmModal';

class Store extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,

            searchApp: {
                name: appConst.STORE_CONFIG.APP_CODE.LP.name,
                value: appConst.STORE_CONFIG.APP_CODE.LP.value,
                selected: appConst.STORE_CONFIG.APP_CODE.LP,
            },
            searchType: {
                name: appConst.STORE_CONFIG.TYPE.ALL.name,
                value: appConst.STORE_CONFIG.TYPE.ALL.value,
                selected: appConst.STORE_CONFIG.TYPE.ALL,
            },
            searchExprire: {
                name: appConst.EXPRIRE_PACKAGES_TIME.WEEK.name,
                value: appConst.EXPRIRE_PACKAGES_TIME.WEEK.value,
                selected: appConst.EXPRIRE_PACKAGES_TIME.WEEK,
            },
            searchStoreName: '',
            searchEmail: '',
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            currentStore: {},
            copyiedStoreID: "",
            formVisible: false,
            mode: appConst.FORM_MODE.CREATE,
        }

        this.sortBy = {
        };
        this.selectedStoreIds = [];
        this.muteActions = [types.LIST_STORE];
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action && !_.includes(this.muteActions, nextProps.action)
            && nextProps.action === types.LIST_STORE) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
        if (nextProps.action == types.UPGRADE_STORE) {
            if (nextProps.status) {
                setTimeout(() => {
                    this.list();
                }, 600);
            }

        }
        if (nextProps.action && nextProps.action === types.CLEAR_TOKEN) {
            if (nextProps.status) {
                const that = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(() => {
                    window.LadiUI.closeModalById('confirm-clear-token');

                    that.list();
                }, 200);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {
        // document.getElementById("checkAllItem").checked = false;
        // window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        //     e.checked = false;
        // });


        const { searchStoreName, selectedLimit, searchType, searchEmail } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        // Reset selectedStoreIds
        this.selectedStoreIds = [];

        const appSelected = this.state.searchApp.selected;

        const data = {

            store_name: searchStoreName,
            app_code: appSelected.value,
            limit: selectedLimit,
            offset: (activePage - 1),

        };

        if (searchEmail != '') {
            data.email = searchEmail;
        }
        // console.log(data);return;
        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list(false);
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchApp = (option) => {
        //    console.log(option);
        this.setState({
            searchApp: { ...this.state.searchApp, selected: option },
        }, () => {
            // console.log(this.state.searchApp);
            this.list();
        })
    }
    onChangeSearchType = (option) => {
        this.setState({
            searchType: { ...this.state.searchType, selected: option },
        }, () => {
            this.list();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedStoreIds = [];
        if (checked) {
            this.selectedStoreIds = _.map(this.props.stores, store => store.store_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, storeId) => {
        const { checked } = event.target;
        const { selectedStoreIds } = this;

        if (checked) {
            if (!_.includes(selectedStoreIds, storeId)) {
                selectedStoreIds.push(storeId);
            }

            if (selectedStoreIds.length == this.props.stores.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedStoreIds, selectedStoreId => selectedStoreId == storeId);
        }


    }

    deleteAllItems = () => {
        if (this.selectedStoreIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }

        this.props.deleteStores(this.selectedStoreIds);
    }

    deleteItem = (storeId) => {
        this.props.deleteStore(storeId);
    }

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), item => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });
        this.sortBy = {};
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    openCreateForm = () => {
        this.setState({
            currentStore: {},
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentStore) => {
        this.setState({
            currentStore,
            mode: appConst.FORM_MODE.EDIT
        })
    }

    getListPackages = () => {
        const { searchApp, currentStore } = this.state;

        const data = {
            app_code: searchApp.selected.value
        };
        this.props.listPackages(data);
        this.props.getUserinfo({ ladi_uid: currentStore.ladi_uid });

    }

    upgradeStore = (currentStore) => {

        // this.formRef.current.resetForm();
        this.setState({
            currentStore: null
        }, () => {
            this.setState({
                currentStore
            }, () => {
                this.getListPackages();
            })

        });

    }
    onCancel = () => {
        this.setState({
            formVisible: false
        });
    }
    clearToken = (selectedID) => {

        this.selectedID = selectedID;
        window.LadiUI.showModalById('confirm-clear-token');

    }

    openStorage = (selectedID) => {
        this.setState({
            copyiedStoreID: selectedID
        }, () => {
            window.LadiUI.showModalById('modal-buy-storage');
        })
    }



    copyToClipboard = (store) => {
        // console.log(store.store_id);
        this.setState({
            copyiedStoreID: store.store_id
        }, () => {
            this.textAreaRef.select();
            document.execCommand('copy');
            window.LadiUI.toastr('success', '', 'Đã copy vào clipboard');
        })

    };
    /****************************************************************/


    render() {
        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchApp, searchStoreName, searchType, resetForm, searchEmail, formVisible } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.stores || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.stores.length - 1;
        }
        // console.log('this.selectedID:', this.selectedID);

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.LIST_STORE);
        const isLoadingClear = this.props.loading && (this.props.waitting == types.CLEAR_TOKEN);
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        return (
            <div>
                <h1 className="page-title">Stores</h1>

                <div>

                    <div className="ladiui-table-actions">
                        {/* <div className="action-item">
                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Search store" name="searchStoreName" value={searchStoreName} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div> */}
                        <div className="action-item">
                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo Email" name="searchEmail" value={searchEmail} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchApp.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.STORE_CONFIG.APP_CODE, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchApp(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchApp.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.STORE_CONFIG.APP_CODE, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchApp(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchType.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.STORE_CONFIG.TYPE, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchType(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>
                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>

                                                {/* <th className="text-left">ID</th> */}
                                                <th className="text-left">Tên</th>
                                                <th className="text-left">Email</th>
                                                <th className="text-left">Package Name</th>
                                                <th className="text-left">Ngày kích hoạt</th>
                                                <th className="text-left">Ngày hết hạn</th>
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Thời gian tạo</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {


                                                _.map(this.props.stores, store => {
                                                    let activedAt = store.pkg_actived_at || store.pkg_active_at;
                                                    return <tr

                                                        key={store.store_id || store._id}
                                                    >
                                                        <td>{store.name}</td>
                                                        <td>{store.email}</td>
                                                        <td>{store.pkg_name}</td>
                                                        <td>
                                                            {
                                                                activedAt
                                                                    ? baseHelper.formatStrToDate(activedAt)
                                                                    : ""
                                                            }
                                                        </td>
                                                        <td>{baseHelper.formatStrToDate(store.pkg_exp)}</td>
                                                        <td>{baseHelper.formatStrToDate(store.created_at)}</td>

                                                        <td>

                                                            {/* {
                                                                userInfo.roleName == appConst.ROLE.ADMIN && 
                                                                <button className="ladiui button primary open-modal" onClick={() => this.upgradeStore(store)} modal="modal-upgrade-store" >Nâng cấp / Gia hạn</button>
                                                            } */}
                                                            {/* {
                                                                userInfo.roleName == appConst.ROLE.ADMIN && */}
                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>


                                                                        <li><a onClick={() => this.copyToClipboard(store)} className="ladiui">Lấy storeID</a></li>
                                                                        {/* <li><a onClick={() => this.upgradeStore(store)} modal="modal-upgrade-store"  className="ladiui open-modal">Nâng cấp/Gia hạn</a></li> */}
                                                                        <li><a onClick={() => this.clearToken(store.store_id)}>Xoá cached store</a></li>
                                                                        {searchApp.selected.value == appConst.STORE_CONFIG.APP_CODE.LS.value &&

                                                                            <li><a onClick={() => this.openStorage(store.store_id)}>Cộng dung lượng upload</a></li>
                                                                        }
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            {/* } */}
                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                                totalRecord == 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="100%">Không có bản ghi nào</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    {
                        totalRecord > 0 &&
                        <div className="ladiui pagination-block">
                            <div className="ladiui search-actions">
                                <div className="ladiui form-filter">
                                    <div className="ladiui data-limit">
                                        <span>Show</span>
                                        <div className="ladiui per-page">
                                            <div className="ladiui-select-options">
                                                <span className="ladiui-select-init">{selectedLimit}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui showing">{msgShowItem}</div>
                                </div>
                            </div>
                            {
                                totalRecord > 0 && <div className="ladiui pagination">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={selectedLimit}
                                        totalItemsCount={totalRecord}
                                        pageRangeDisplayed={5}
                                        onChange={this.onPageChange}
                                        prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                        nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                        firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                        lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                    />
                                </div>
                            }

                        </div>
                    }
                </div>
                {/* {formVisible && */}

                {/* } */}
                <AddStorage store_id={this.state.copyiedStoreID}></AddStorage>
                <ConfirmModal
                    id="confirm-clear-token"
                    title="Đặt lại token đăng nhập của mọi nhân viên trong store?"
                    content="Bạn chắc chắn đặt lại token?"
                    cancelText="Huỷ"
                    okText="Áp dụng"
                    onOk={() => this.props.clearToken({
                        store_id: this.selectedID,
                    })}
                    isLoading={isLoadingClear}
                />
                <input
                    readOnly
                    type="text"
                    className="ladiui input"
                    value={this.state.copyiedStoreID}
                    ref={(textarea) => this.textAreaRef = textarea}
                    style={{
                        position: "absolute",
                        left: "-9999px"
                    }}
                />

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(storeActions.list(data)),
        listPackages: (data) => dispatch(storeActions.listPackages(data)),
        clearToken: (data) => dispatch(storeActions.clearToken(data)),
        getUserinfo: (data) => dispatch(storeActions.getUserinfo(data))
    }
}

const mapStateToProps = (state) => ({ ...state.store });

export default connect(mapStateToProps, mapDispatchToProps)(Store);