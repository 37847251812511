import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.INVOICE_LIST,
    meta: {
        endpoint: endpoint.INVOICE_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const invoiceDetail = (data) => ({
    type: types.INVOICE_DETAIL,
    meta: {
        endpoint: endpoint.INVOICE_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const exportExcel = (data) => ({
    type: types.EXPORT_INVOICE,
    meta: {
        endpoint: endpoint.EXPORT_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const paidAll = (data) => ({
    type: types.PAID_ALL_INVOICE,
    meta: {
        endpoint: endpoint.PAID_ALL_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const paidInvoice = (data) => ({
    type: types.PAID_INVOICE,
    meta: {
        endpoint: endpoint.PAID_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
 
export default {
    list,
    invoiceDetail,
    exportExcel,
    paidInvoice,
    paidAll
}