import React from 'react';

import { connect } from 'react-redux';
import referralActions from '../../redux/futures/referral/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/referral/types';

import LoadingTable from '../../components/LoadingTable';
// import Form from './components/Form';
// import View from './components/View';

import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import "react-datepicker/dist/react-datepicker.css";

class Referral extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            searchAmount: {
                name: appConst.REFERRAL_AMOUNT.ALL.name,
                value: appConst.REFERRAL_AMOUNT.ALL.value,
                selected: appConst.REFERRAL_AMOUNT.ALL,
            },
            fromDate: firstDay,
            toDate: new Date(),
            email: "",
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            referralList: [],
            mode: appConst.FORM_MODE.CREATE,
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.selectedreferralIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.REFERRAL_LIST) {
            if (nextProps.status) {

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
        // if (nextProps.action == types.REFERRAL_LIST) {

        //     if (nextProps.status) {
        //         setTimeout(() => {
        //             this.list();    
        //         }, 500);

        //     }  
        // }
    }

    /**
     * list store
     */
    getList = (resetActivePageFlag = true) => {


        // document.getElementById("checkAllItem").checked = false;
        // window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        //     e.checked = false;
        // });


        const { selectedLimit, email } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        let { fromDate, toDate } = this.state;
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);
        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            email,
            "from_date": fromDate,
            "to_date": toDate
        };

        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.getList();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.getList();
        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.getList();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.getList(false);
        });
    }
    /****************************************************************/

    openCreateForm = () => {
        this.setState({
            currentReferral: false,
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentReferral) => {
        this.setState({
            currentReferral,
            mode: appConst.FORM_MODE.EDIT
        })
    }
    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedreferralIds = [];
        if (checked) {
            this.selectedreferralIds = _.map(this.props.referrals, store => store.store_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, referralId) => {
        const { checked } = event.target;
        const { selectedreferralIds } = this;

        if (checked) {
            if (!_.includes(selectedreferralIds, referralId)) {
                selectedreferralIds.push(referralId);
            }

            if (selectedreferralIds.length == this.props.referrals.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedreferralIds, selectedreferralId => selectedreferralId == referralId);
        }


    }

    deleteAllItems = () => {
        if (this.selectedreferralIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }

        this.props.deleteOrders(this.selectedreferralIds);
    }

    deleteItem = (referralId) => {
        this.props.deleteOrder(referralId);
    }

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.getList());
    }

    view = (currentReferral) => {
        this.setState({
            currentReferral,
            mode: appConst.FORM_MODE.EDIT
        })
    }

    /****************************************************************/


    render() {

        const { totalRecord } = this.props;
        const { activePage, selectedLimit, email } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.referrals || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.referrals.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.REFERRAL_LIST);
        return (
            <div>
                <h1 className="page-title">Giới thiệu</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">

                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                        <div className="action-item">

                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div>
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo email" name="email" value={email} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.getList} />
                            </div>
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>
                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                {/* <th className="ladiui checkall text-center">
                                        <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                    </th> */}
                                                {/* <th name="store_id" onClick={this.sort} className="text-left sorting">ID</th> */}

                                                <th className="text-left">Email</th>

                                                <th className="text-left">Email người giới thiệu</th>
                                                <th className="text-left">Tặng xác thực tài khoản</th>
                                                 <th className="text-left">Tặng nâng cấp</th>
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Thời gian đăng kí</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                _.map(this.props.referrals, referral => {

                                                    return <tr key={referral._id}>
                                                        {/* <td className="text-center">
                                                <input type="checkbox" onClick={(event) => this.checkItem(event, referral._id)} className="ladiui checkbox" />
                                            </td> */}
                                                        {/* <td>{store.store_id}</td> */}
                                                        <td>{referral.userInfo.email}</td>
                                                        <td>{referral.userRef.email}</td>
                                                        <td>{referral.verify_reward? referral.verify_reward +' tháng': '--'}</td>
                                                        <td>{referral.purchased_reward? referral.purchased_reward +' tháng': '--'}</td>
                                                        {/* <td>{referral.commission_tier_2}</td> */}
                                                        <td>{baseHelper.formatStrToDate(referral.created_at)}</td>

                                                        <td>

                                                            {/* <ul className="ladiui-navbar -horizontal row-actions">
                                                    <li className="ladiui-dropdown">
                                                        <a><i className="ladi-icon icon-dots"></i></a>
                                                        <ul>
                                                            <li><a onClick={() => this.openEditForm(referral)} className="ladiui open-modal" modal="modal-referral" >Edit</a></li>
                                                            <li><a onClick={() => this.view(referral)} className="ladiui open-modal" modal="modal-referralview" >View</a></li>
                                                            

                                                        </ul>
                                                    </li>
                                                </ul> */}

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                            totalRecord <= 0 &&                                                
                                            <tr className="text-center"> 
                                                <td colSpan="100%">Không có bản ghi nào</td> 
                                            </tr>                                             
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    {
                        totalRecord >0 &&
                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Show</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                    }
                </div>
                {/* <View 
                     currentReferral={this.state.currentReferral} 
                 />

                <Form
                    currentReferral={this.state.currentReferral}
                    mode={this.state.mode}
                /> */}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(referralActions.list(data)),
        myReferrals: (data) => dispatch(referralActions.list(data))
    }
}

const mapStateToProps = (state) => ({ ...state.referral });

export default connect(mapStateToProps, mapDispatchToProps)(Referral);