import React from 'react';

import { connect } from 'react-redux';
import orderActions from '../../redux/futures/order/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import Detail from './components/Detail';
import Print from './components/Print';
import Cancel from './components/Cancel';
import * as types from '../../redux/futures/order/types';
import LoadingTable from '../../components/LoadingTable';
import Paid from './components/Paid';
import moment from 'moment';
// import Form from './components/Form';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

// import "react-datepicker/dist/react-datepicker.css";

class Order extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            fromDate: moment(firstDay),
            toDate: moment(new Date()),
            searchStatus: {
                name: appConst.PAYMENT_STATUS_OPTIONS.ALL.name,
                value: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_STATUS_OPTIONS.ALL,
            },
            searchType: {
                name: appConst.PAYMENT_TYPE_OPTIONS.ALL.name,
                value: appConst.PAYMENT_TYPE_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_TYPE_OPTIONS.ALL,
            },
            searchOrderNo: '',
            searchEmail: '',
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            currentOrder: {},
            mode: appConst.FORM_MODE.CREATE,
            orderList: [],
            focusedInput: null
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.selectedOrderIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.LIST_ORDER) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                // console.log(nextProps, nextProps.message);
                window.LadiUI.toastr('error', '', nextProps.message);
            }

        }
        if (nextProps.action == types.CANCEL_ORDER) {
            // console.log(' PARENT   = types.CANCEL_ORDER');
            if (nextProps.status) {
                setTimeout(() => {
                    this.getList();
                }, 600);
            }

        }

        if (nextProps.action == types.PAID_ORDER) {
            if (nextProps.status) {
                window.LadiUI.toastr('success', '', nextProps.message);
                const that = this;
                setTimeout(() => {
                    window.LadiUI.closeModalById('confirm-paid-order');

                    that.getList();
                }, 200);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            // this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            // this.getList();
        });

    }

    /**
     * list store
     */
    getList = (resetActivePageFlag = true) => {


        document.getElementById("checkAllItem").checked = false;
        window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
            e.checked = false;
        });


        const { searchOrderNo, selectedLimit, searchEmail } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        // Reset selectedOrderIds
        this.selectedOrderIds = [];

        const statusSelected = this.state.searchStatus.selected;
        const typeSelected = this.state.searchType.selected;
        let { fromDate, toDate } = this.state;
        fromDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            milisecond: 0
        });

        toDate.set({
            hour: 23,
            minute: 59,
            second: 59,
            milisecond: 999
        });

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "status": statusSelected,
            "type": typeSelected,
            "reference_no": searchOrderNo,
            "from_date": fromDate,
            "to_date": toDate,
            "email": searchEmail
        };

        // this.props.myOrders(data);
        this.props.list(data);
    }



    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.getList();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.getList();
        })
    }
    onChangeSearchType = (option) => {
        this.setState({
            searchType: { ...this.state.searchType, selected: option.value },
        }, () => {
            this.getList();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.getList();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.getList(false);
        });
    }
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedOrderIds = [];
        if (checked) {
            this.selectedOrderIds = _.map(this.props.orders, store => store.store_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, orderId) => {
        const { checked } = event.target;
        const { selectedOrderIds } = this;

        if (checked) {
            if (!_.includes(selectedOrderIds, orderId)) {
                selectedOrderIds.push(orderId);
            }

            if (selectedOrderIds.length == this.props.orders.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedOrderIds, selectedOrderId => selectedOrderId == orderId);
        }


    }

    deleteAllItems = () => {
        if (this.selectedOrderIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }

        this.props.deleteOrders(this.selectedOrderIds);
    }

    deleteItem = (orderId) => {
        this.props.deleteOrder(orderId);
    }

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.getList());
    }

    print = (currentOrder) => {
        const data = {
            "id": currentOrder._id
        };
        this.setState({
            currentOrder
        });
        this.props.getDetail(data);
    }

    view = (currentOrder) => {
        const data = {
            "id": currentOrder._id
        };
        this.setState({
            currentOrder
        });
        this.props.getDetail(data);
    }

    cancel = (currentOrder) => {
        const data = {
            "id": currentOrder._id
        };
        this.setState({
            currentOrder
        });
        this.props.getDetail(data);
    }

    paid = (selectedID) => {

        this.selectedID = selectedID;
        window.LadiUI.showModalById('confirm-paid-order');



        // this.props.deleteOrders(this.selectedInvoiceIds);
    }

    /****************************************************************/


    render() {

        const { totalRecord } = this.props;
        const { activePage, selectedLimit, searchStatus, searchType, searchOrderNo, searchEmail } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.orders || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.orders.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.LIST_ORDER);
        const isLoadingPaid = this.props.loading && (this.props.waitting == types.PAID_ORDER);
        return (
            <div>
                <h1 className="page-title">Đơn hàng</h1>

                <div>
                    <div className="ladiui-table-actions">
                        {/* <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo mã đơn" name="searchOrderNo" value={searchOrderNo} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.getList} />
                            </div>
                        </div> */}
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo email" name="searchEmail" value={searchEmail} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.getList} />
                            </div>
                        </div>
                        {/* <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input date"
                                selected={this.state.fromDate}
                                // onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                        <div className="action-item">
                        
                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input date"
                                selected={this.state.toDate}
                                // onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div> */}
                        <div className="action-item">
                            <DateRangePicker
                                startDateId="fromDate"
                                endDateId="toDate"
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState({
                                        fromDate: startDate,
                                        toDate: endDate,
                                    }, () => {
                                        if (endDate) {
                                            this.getList();
                                        }
                                    })
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={
                                    <i className="ladi-icon icon-calendar" />
                                }
                                displayFormat={() => appConst.DEFAULT_FORMAT_DATE_NEW}
                                // startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                // endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => {
                                    return moment(new Date()).isSame(date, 'day');
                                }}
                            />
                        </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchStatus.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.PAYMENT_STATUS_OPTIONS, (option, idx) =>
                                                <li key={idx} className="select-item" onClick={() => this.onChangeSearchStatus(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchType.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.PAYMENT_TYPE_OPTIONS, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchType(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>

                                <div className="ladi-card">


                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th>
                                                <th name="name" onClick={this.sort} className="text-left sorting">Mã đơn</th>
                                                <th name="email" onClick={this.sort} className="text-left sorting">Email</th>

                                                <th className="text-left">Giảm giá</th>
                                                <th className="text-left">Point</th>
                                                <th className="text-left">Credit</th>
                                                <th className="text-left">Tổng tiền</th>
                                                {/* <th className="text-left">Ghi chú</th> */}
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Thời gian tạo</th>
                                                <th name="status" onClick={this.sort} className="text-left sorting">Trạng thái</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.orders, order => {

                                                    return <tr key={order._id}>
                                                        <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, order._id)} className="ladiui checkbox" />
                                                        </td>
                                                        <td>{order.reference_no}</td>
                                                        <td>{order.email}</td>

                                                        <td>{baseHelper.formatNumber(order.discount)} VND</td>
                                                        <td>{baseHelper.formatNumber(order.point_used)}</td>
                                                        <td>{baseHelper.formatNumber(order.credit_used)}</td>

                                                        <td>{baseHelper.formatNumber(order.total)} VND</td>
                                                        {/* <td>{order.note}</td> */}
                                                        <td>{baseHelper.formatStrToDate(order.created_at)}</td>
                                                        <td>{appConst.PAYMENT_STATUS_OPTIONS[order.status].name}</td>
                                                        <td>

                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>
                                                                        {/* <li><a onClick={() => this.print(order)} className="ladiui open-modal" modal="modal-print" >Print</a></li> */}
                                                                        <li><a onClick={() => this.view(order)} className="ladiui open-modal" modal="modal-detail" >Chi tiết</a></li>
                                                                        {
                                                                            (order.status === appConst.PAYMENT_STATUS_OPTIONS.PENDING.value) &&
                                                                            <li><a onClick={() => this.cancel(order)}
                                                                                className="ladiui open-modal" modal="modal-cancel">Huỷ đơn</a></li>
                                                                        }
                                                                        {
                                                                            (order.status === appConst.PAYMENT_STATUS_OPTIONS.PENDING.value) &&
                                                                            <li><a onClick={() => this.paid(order._id)} >Xác nhận thanh toán</a></li>
                                                                        }

                                                                    </ul>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                                totalRecord <= 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="100%">Không có bản ghi nào</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    {totalRecord > 0 &&
                        <div className="ladiui pagination-block">
                            <div className="ladiui search-actions">
                                <div className="ladiui form-filter">
                                    <div className="ladiui data-limit">
                                        <span>Show</span>
                                        <div className="ladiui per-page">
                                            <div className="ladiui-select-options">
                                                <span className="ladiui-select-init">{selectedLimit}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui showing">{msgShowItem}</div>
                                </div>
                            </div>
                            {
                                totalRecord > 0 && <div className="ladiui pagination">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={selectedLimit}
                                        totalItemsCount={totalRecord}
                                        pageRangeDisplayed={5}
                                        onChange={this.onPageChange}
                                        prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                        nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                        firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                        lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                    />
                                </div>
                            }

                        </div>
                    }
                </div>
                <Detail
                    currentPayment={this.state.currentOrder}
                    mode={this.state.mode}
                />
                <Print
                    currentPayment={this.state.currentOrder}
                    mode={this.state.mode}
                />
                <Cancel
                    currentPayment={this.state.currentOrder}
                    mode={this.state.mode}
                />

                {/* <Form
                    currentOrder={this.state.currentOrder}
                    mode={this.state.mode}
                /> */}
                <Paid
                    id="confirm-paid-order"
                    title="Xác nhận thanh toán?"
                    content="Hành động này rất quan trọng. Bạn có chắc chắn áp dụng?"
                    cancelText="Huỷ"
                    okText="Đồng ý"
                    onOk={(method) => this.props.paidOrder({
                        id: this.selectedID,
                        status: method
                    })}
                    isLoading={isLoadingPaid}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(orderActions.list(data)),
        myOrders: (data) => dispatch(orderActions.myOrders(data)),
        deleteOrders: (orderIds) => dispatch(orderActions.deleteOrders(orderIds)),
        deleteOrder: (orderId) => dispatch(orderActions.deleteOrder(orderId)),
        getDetail: (data) => dispatch(orderActions.getDetail(data)),
        paidOrder: (data) => dispatch(orderActions.paidOrder(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.order });

export default connect(mapStateToProps, mapDispatchToProps)(Order);