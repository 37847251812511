import React from 'react';
import Topbar from './Topbar';
import Footer from './Footer';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../../../../routes';
import _ from 'lodash';
import appConst from '../../../../config/const';
import baseHelper from '../../../../helpers/BaseHelper';


export default class Main extends React.Component {

    componentDidUpdate() {
        window.LadiUI.init();
    }
    render() {
       
        const adminInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
              
        const availableRoutes = routes.getRoutesByScopes(adminInfo.scopes);
        console.log()
        return (
            <div className="ladiui-main">
                <Topbar />

                <div className="ladiui-content">
                    <Switch>
                        {
                            _.map(availableRoutes, route => { 
                                return <Route key={route.name} path={route.path} exact={route.exact} component={route.component} /> 
                            })
                        }
                        <Route render={() => <Redirect to="/" />} />
                    </Switch>
                </div>

                <Footer />
            </div>
        )
    }
}