import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import Input from '../../components/Input';
import appConst from '../../config/const';

import * as types from '../../redux/futures/tools/types';




class VerifyEmail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            actionObj: {

                email: "",

                status: 1,
            },
            selectedAction: appConst.EMAIL_VERIFY_STATUS.ACTIVE,
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: '',
            selectedType: appConst.VERIFY_LADIUID.VERIFIED,

        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {


            if (nextProps.action == types.VERIFY_EMAIL_LADIFLOW) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    const _seft = this;
                    window.LadiUI.toastr('success', '', nextProps.message);
                    setTimeout(function () {
                        window.LadiUI.closeModal(_seft.ref.current);
                    }, 1000);

                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { actionObj, selectedAction, selectedType } = this.state;
        const jsonData = {
            "email": actionObj.email,
            "status": selectedAction.value,
            "is_verify_ladiuid": (selectedType.value == appConst.VERIFY_LADIUID.VERIFIED.value)
        }
        this.props.verifyEmail(jsonData);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    onChangeType = (selected) => {
        this.setState({
            selectedType: selected
        });
    }


    onChangeInput = (event) => {

        this.setState({
            actionObj: {
                ...this.state.actionObj,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeAction = (option) => {
        // console.log(option);
        this.setState({
            selectedAction: option,
        })
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            user: baseHelper.resetState(this.state.user),
        });
        window.LadiUI.closeModal(this.ref.current);

        // window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
        //     e.classList.remove('error');
        // });
    }

    render() {
        const { actionObj, selectedAction, selectedType } = this.state;


        const title = 'Đóng/mở xác thực cho email';

        return (
            <div ref={this.ref} id="modal-verify-email-flow" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-verify-email-flow"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-verify-email-flow"><i className="ladi-icon icon-e-remove" data-dismiss="modal-verify-email-flow" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body" >
                        <form>
                            <div className="ladiui-tab-content" style={{ height: "160px" }}>
                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="email"
                                                validationName="Email"
                                                placeholder="Email"
                                                value={actionObj.email}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, email: true }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Hành động</label>
                                            <div className="ladiui-select-options">

                                                <span className="ladiui-select-init">{
                                                    selectedAction.name
                                                }</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.EMAIL_VERIFY_STATUS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeAction(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-verify-email-flow">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-verify-email-flow" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                        </button>

                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        verifyEmail: (data) => dispatch(toolActions.verifyEmailLadiFlow(data))
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);