import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                action: '',
            }
        }
        case types.LIST_PACKAGE: {
            return {
                ...state,
                action: types.LIST_PACKAGE,
                packages: action.status ? action.payload.packages : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }

        case types.DETAIL_PACKAGE: {
            return {
                ...state,
                action: types.DETAIL_PACKAGE,
                details: action.status ? action.payload.details : [],
                status: action.status,
                message: action.message,
            }
        }
        case commonTypes.ASYNC_END: {
            return {
                ...state,
                loading: false,
                action: '',
            }
        }
        default:
            return state;
    }
}

