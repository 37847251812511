import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';

import moment from 'moment';
import * as types from '../../redux/futures/tools/types';
import appConst from '../../config/const';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "react-datepicker/dist/react-datepicker.css";

class ReportApota extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            fromDate: moment(firstDay),
            toDate: moment(new Date()),
            submitData: false,
            excelDatas: [],
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.muteActions = [];

    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {

            if (nextProps.action == types.EXPORT_REPORT_APOTA) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    this.setState({
                        message: nextProps.result
                    })
                } else {
                }
            }

        }
    }
    submit = () => {

        const { fromDate, toDate } = this.state;
        const jsonData = {
            from_date: fromDate,
            to_date: toDate
        }
        this.props.reportApota(jsonData);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        });

    }

    render() {

        const title = 'Chọn thời gian để tải báo cáo';
        return (
            <div ref={this.ref} id="modal-report-apota" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-report-apota">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-report-apota"><i className="ladi-icon icon-e-remove" data-dismiss="modal-report-apota" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">

                        <div className="ladiui-tab-content" style={{ height: "500px" }}>

                            <DateRangePicker
                                startDateId="fromDate"
                                endDateId="toDate"
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState({
                                        fromDate: startDate,
                                        toDate: endDate,
                                    }, () => {
                                        if (endDate) {

                                        }
                                    })
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={
                                    <i className="ladi-icon icon-calendar" />
                                }
                                displayFormat={() => appConst.DEFAULT_FORMAT_DATE_NEW}
                                // startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                // endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => {
                                    return moment(new Date()).isSame(date, 'day');
                                }}
                            />

                        </div>


                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-report-apota">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-report-apota" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Tải file Exel
                        </button>

                    </div>
                </div>

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reportApota: (data) => dispatch(toolActions.reportApota(data)),

    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(ReportApota);