import React from 'react';

import { connect } from 'react-redux';
import userActions from '../../redux/futures/user/actions';
import storeActions from '../../redux/futures/store/actions';
import _, { cloneDeep } from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as userTypes from '../../redux/futures/user/types';
import * as partnerTypes from '../../redux/futures/partner/types';

import Form from './components/Form';
import GetPassword from './components/GetPassword';
import View from './components/View';
import ChangeCredit from './components/ChangeCredit';
import ChangePoint from './components/ChangePoint';
import ActivePartner from './components/ActivePartner';
import LoadingTable from '../../components/LoadingTable';

import "react-datepicker/dist/react-datepicker.css";
import ConfirmModal from '../../components/ConfirmModal';

class User extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            searchStatus: {
                name: appConst.USER_STATUS.ACTIVE.name,
                value: appConst.USER_STATUS.ACTIVE.value,
                selected: appConst.USER_STATUS.ACTIVE,
            },
            searchType: {
                name: appConst.USER_TYPE.ALL.name,
                value: appConst.USER_TYPE.ALL.value,
                selected: appConst.USER_TYPE.ALL,
            },
            fullname: "",
            id: "",
            email: "",
            phone: "",
            fromDate: firstDay,
            toDate: new Date(),
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            userList: [],
            copyiedStoreID: "",
            mode: appConst.FORM_MODE.CREATE,
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                status: "",

                point: "",

                credit: "",

                created_at: "",

                _id: "",

                code: "",
                is_verify_email: false,
                is_verify_domain: false
            }
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.selectedOrderIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        // this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.user.action && !_.includes(this.muteActions, nextProps.user.action) && nextProps.user.action === userTypes.USER_LIST) {
            if (nextProps.user.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.user.message);
            }
        }

        if (nextProps.partner.action == partnerTypes.CHANGE_CREDIT || nextProps.partner.action == partnerTypes.CREATE_PARTNER) {
            if (nextProps.partner.status) {
                const that = this;
                setTimeout(() => {
                    window.LadiUI.closeModalById('modal-user-addcredit');

                    that.list();
                }, 200);
            }
        }

        if (nextProps.user.action && nextProps.user.action === userTypes.HARD_DELETE_USER) {
            if (nextProps.user.status) {
                const that = this;
                window.LadiUI.toastr('success', '', nextProps.user.message);
                setTimeout(() => {
                    window.LadiUI.closeModalById('confirm-delete-user');

                    that.list();
                }, 200);

            } else {
                window.LadiUI.toastr('error', '', nextProps.user.message);
            }
        }

        if (nextProps.user.action && nextProps.user.action === userTypes.ADMIN_UPDATE_USER) {
            if (nextProps.user.status) {
                const that = this;
                window.LadiUI.toastr('success', '', nextProps.user.message);
                setTimeout(() => {
                    that.list();
                }, 200);

            } else {
                window.LadiUI.toastr('error', '', nextProps.user.message);
            }
        }

    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {
        const { selectedLimit, email, fullname, id, searchStatus, searchType, phone } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        let { fromDate, toDate } = this.state;
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "from_date": fromDate,
            "to_date": toDate,
            "email": escape(email.trim()),
            phone: phone.trim(),
            "fullname": fullname.trim(),
            "id": id,
            "status": searchStatus.selected.value,
            "is_partner": searchType.selected.value
        };
        // console.log(data);
        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {

            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option },
        }, () => {
            this.list();
        })
    }

    onChangeSearchType = (option) => {
        this.setState({
            searchType: { ...this.state.searchType, selected: option },
        }, () => {
            this.list();
        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

    openCreateForm = () => {
        this.setState({
            currentUser: false,
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentUser) => {
        this.setState({
            currentUser,
            mode: appConst.FORM_MODE.EDIT
        })
    }
    openGetPassword = (currentUser) => {
        this.setState({
            currentUser
        });

        if (currentUser._id) {
            // console.log({ladi_uid: currentUser._id});
            this.props.getToken({ ladi_uid: currentUser._id, email: currentUser.email });
        }
    }
    /*******************************CHECK AND ACTIONS***************************/

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (currentUser) => {
        this.setState({
            currentUser
        })
    }

    addPoint = (currentUser) => {
        this.setState({
            currentUser
        })
    }

    addCredit = (currentUser) => {
        this.setState({
            ldfUser: cloneDeep(currentUser)
        }, () => {

            const data = {
                app_code: 'LF'
            };
            this.props.listPackages(data);
            this.props.listDeals({ ladi_uid: currentUser._id });
        })
    }

    activePartner = (currentUser) => {
        this.setState({
            currentUser
        })
    }


    deleteUser = (selectedID) => {

        this.selectedID = selectedID;
        window.LadiUI.showModalById('confirm-delete-user');

    }

    copyToClipboard = (user) => {
        // console.log(store.store_id);
        this.setState({
            copyiedUserID: user._id
        }, () => {
            this.textAreaRef.select();
            document.execCommand('copy');
            window.LadiUI.toastr('success', '', 'Đã copy vào clipboard');
        })

    };
    /****************************************************************/


    render() {

        const { totalRecord } = this.props.user;
        const { activePage, selectedLimit, searchStatus, searchType, fullname, email, id, phone, currentUser } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.user.users || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.user.users.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.user.loading && (this.props.user.waitting == userTypes.USER_LIST);
        const isLoadingPaid = this.props.user.loading && (this.props.user.waitting == userTypes.HARD_DELETE_USER);
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

        const partnerInfo = {
            email: "",

            credit: 0,

            _id: ""
        };
        if (currentUser._id) {
            partnerInfo.email = currentUser.email;
            partnerInfo.credit = currentUser.credit;
            partnerInfo._id = currentUser._id;
        }

        return (
            <div>
                <h1 className="page-title">Khách hàng</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo email" name="email" value={email} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="number" id="search" placeholder="Tìm theo số ĐT" name="phone" value={phone} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        {/* <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo tên" name="fullname" value={fullname} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div> */}
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo UID" name="id" value={id} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options" style={{ minWidth: '150px' }}>
                                    <span className="ladiui-select-init">{searchType.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.USER_TYPE, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchType(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchStatus.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.USER_STATUS, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchStatus(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>
                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>

                                                {/* <th className="text-left">UID</th> */}
                                                <th className="text-left">Tên</th>
                                                <th className="text-left">Email</th>
                                                <th className="text-left">Affiliate Code</th>
                                                <th className="text-left">LadiFlow Ref</th>
                                                <th className="text-left">Số điện thoại</th>
                                                <th className="text-left">Credit</th>
                                                <th className="text-left">Điểm</th>
                                                <th className="text-left">Money</th>
                                                <th className="text-left">Partner Code</th>

                                                {/* <th className="text-left">Đặng nhập gần nhất</th> */}
                                                {/* <th className="text-left">Trạng thái</th> */}
                                                {/* <th className="text-left">Commission Tier2</th> */}
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Thời gian đăng kí</th>
                                                {/* <th className="text-left">Affilate Email</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.user.users, user => {
                                                    return <tr key={user._id}>
                                                        <td>{user.fullname.trim()}{user.is_partner && <span title="Partner ship"> <i className="ladi-icon icon-d-check" style={{ cursor: "pointer", color: "#0C61F2", marginLeft: "10px", fontSize: "17px" }} /> </span>}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.code}</td>
                                                        <td>{user.ladiflow_ref}</td>
                                                        <td>{user.phone}</td>
                                                        <td>{user.credit != undefined ? baseHelper.numberWithCommas(user.credit.toFixed(2)) : `--`}</td>
                                                        <td>{user.credit_app != undefined ? baseHelper.numberWithCommas(user.credit_app.toFixed(2)) : `--`}</td>
                                                        <td>{user.money != undefined ? baseHelper.numberWithCommas(user.money) : `--`}</td>
                                                        <td >{user.partner_code != undefined ? user.partner_code : `--`}</td>
                                                        <td>{baseHelper.formatStrToDate(user.created_at)}</td>
                                                        {/* <td>{user.affilate_id}</td> */}
                                                        <td>

                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>
                                                                        {
                                                                            (userInfo.roleName == appConst.ROLE.ADMIN || userInfo.roleName == appConst.ROLE.SUPPORT || userInfo.roleName == appConst.ROLE.SUPPORT_TOOL) &&
                                                                            <li><a onClick={() => this.openEditForm(user)} className="ladiui open-modal" modal="modal-unserinfoform" >Edit</a></li>
                                                                        }

                                                                        <li><a onClick={() => this.view(user)} className="ladiui open-modal" modal="modal-userview" >Chi tiết</a></li>
                                                                        {
                                                                            (userInfo.roleName == appConst.ROLE.ADMIN || userInfo.roleName == appConst.ROLE.SUPPORT ||
                                                                                userInfo.roleName == appConst.ROLE.AFFILATE_CARE || userInfo.roleName == appConst.ROLE.SUPPORT_TOOL) &&
                                                                            <li><a onClick={() => this.addCredit(user)} className="ladiui open-modal" modal="modal-user-addcredit" >Cộng credit</a></li>
                                                                        }
                                                                        {
                                                                            (!user.is_partner) && (userInfo.roleName == appConst.ROLE.ADMIN || userInfo.roleName == appConst.ROLE.AFFILATE_CARE || userInfo.roleName == appConst.ROLE.SUPPORT_TOOL) &&
                                                                            <li><a onClick={() => this.activePartner(user)} className="ladiui open-modal" modal="modal-user-active-partner" >Active Partner</a></li>
                                                                        }
                                                                        {
                                                                            (userInfo.roleName == appConst.ROLE.ADMIN || userInfo.roleName == appConst.ROLE.SUPPORT || userInfo.roleName == appConst.ROLE.SUPPORT_MANAGER || userInfo.roleName == appConst.ROLE.SUPPORT_TOOL) &&
                                                                            <li><a onClick={() => this.openGetPassword(user)} className="ladiui open-modal" modal="modal-temppassword" >Lấy mật khẩu</a></li>
                                                                        }
                                                                        <li><a onClick={() => this.copyToClipboard(user)} className="ladiui">Lấy ID</a></li>
                                                                        {
                                                                            (userInfo.roleName == appConst.ROLE.ADMIN || userInfo.roleName == appConst.ROLE.SUPPORT || userInfo.roleName == appConst.ROLE.SUPPORT_TOOL) &&
                                                                            <li><a onClick={() => this.addPoint(user)} className="ladiui open-modal" modal="modal-user-addpoint" >Cộng point</a></li>
                                                                        }

                                                                    </ul>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Hiển thị</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                </div>
                <View
                    currentUser={this.state.currentUser}
                />
                <ChangeCredit
                    currentUser={this.state.ldfUser}
                />
                <ActivePartner
                    currentUser={this.state.currentUser}
                />
                <GetPassword
                    currentUser={this.state.currentUser}
                />
                {/* <RenewPackages 
                     currentUser={this.state.currentUser} 
                 /> */}
                <ChangePoint currentUser={this.state.currentUser}
                />
                <Form
                    currentUser={this.state.currentUser}
                    mode={this.state.mode}
                />
                <ConfirmModal
                    id="confirm-delete-user"
                    title="Delete user information?"
                    content="This action cannot be undone. Do you still want to continue?"
                    cancelText="Cancel"
                    okText="Delete"
                    onOk={() => this.props.deleteUser({
                        ladi_uid: this.selectedID,

                    })}
                    isLoading={isLoadingPaid}
                />

                <input
                    readOnly
                    type="text"
                    className="ladiui input"
                    value={this.state.copyiedUserID}
                    ref={(textarea) => this.textAreaRef = textarea}
                    style={{
                        position: "absolute",
                        left: "-9999px"
                    }}
                />

            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(userActions.list(data)),
        getToken: (data) => dispatch(userActions.getTempPassword(data)),
        deleteUser: (data) => dispatch(userActions.deleteUser(data)),
        listPackages: (data) => dispatch(storeActions.listPackages(data)),
        listDeals: (data) => dispatch(storeActions.listDeals(data)),
    }
}

const mapStateToProps = (state) => ({ user: { ...state.user }, partner: { ...state.partner } });

export default connect(mapStateToProps, mapDispatchToProps)(User);