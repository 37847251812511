import React from 'react';
import * as types from '../../../redux/futures/store/types';
import storeActions from '../../../redux/futures/store/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";


class DownGrade extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();

        this.state = {
            currentDetail: {
                // ladi_uid: '',
                _id: '',
                store_id: '',
                store_name: '',
                pkg_type: '',
                pkg_name: '',
                course_pkg_name: '',
                pkg_month: '',
                addition_month: '',
                subtotal: '',
                created_at: '',
                order_id: ''
            },
            selectedPackage: '',
            exprireDate: date,
            activedDate: date,
            type: props.typeUpgrade,
            note: '',
            currentUser: {
                email: "",
                fullname: "",
                _id: "",
            },
            loading: false,
            loaderPackages: true,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        }
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();

        this.ref = React.createRef();

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.typeUpgrade !== nextProps.typeUpgrade) {
            this.setState({ type: nextProps.typeUpgrade })
        }
        // console.log(this.props.currentDetail);
        if (this.props.currentDetail !== nextProps.currentDetail) {
            if (!nextProps.currentDetail) {
                this.resetForm();
            } else {
                const monthAdd = parseInt(nextProps.currentDetail.pkg_month);
                const exprireDate = baseHelper.addMonth(new Date(), -monthAdd);

                this.setState({
                    currentDetail: _.pick(nextProps.currentDetail, _.keys(this.state.currentDetail)),
                    currentUser: nextProps.currentUser,
                    loaderPackages: true,
                    exprireDate,
                    activedDate: exprireDate,
                    packages: []
                })
            }
        }

        if (nextProps.action === types.LIST_PACKAGE) {

            this.setState({ loaderPackages: false });
            if (nextProps.status && nextProps.packages.length > 0) {
                const lsPkg = _.cloneDeep(nextProps.packages);
                let append = true;
                for (let i = 0; i < lsPkg.length; i++) {
                    if (lsPkg[i].price == 0) {
                        append = false;
                        break;
                    }
                }
                if (append) {
                    lsPkg.unshift({
                        "is_delete": false,
                        "_id": "5d00840514655620104fc8e8",
                        "package_id": "5d00840514655620104fc8e8",
                        "package_name": "STARTER",
                        "name": "STARTER",
                        "alias": "starter",
                        "price": 0,
                        "description": {
                            "sub_title": "Gói starter"
                        },
                        "commission": [],
                        "status": true,
                        "created_at": "2019-06-12T04:48:05.838Z",
                        "updated_at": "2019-06-12T04:48:05.838Z"
                    });
                }
                // console.log(list);
                this.setState({
                    packages: lsPkg,
                    selectedPackage: nextProps.packages[0],
                    loaderPackages: false
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

            // this.list();
        }

        // if (_.includes([orderTypes.DETAIL_ORDER], nextProps.action)) {
        //     if (nextProps.status) {

        //         this.setState({
        //             details: nextProps.details,
        //         });
        //     } else {
        //     }
        // }
        if (nextProps.action == types.DOWNGRADE_STORE) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                }, 1000);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

    }

    pickPackages(name) {

        const { packages } = this.state;

        for (let i = 0; i < packages.length; i++) {
            if (packages[i].name === name) {

                this.setState({
                    selectedPackage: packages[i]
                });
                break;
            }
        }

    }

    resetForm = (event) => {
        // console.log('===resetForm===');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
    }


    onChangeInput = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) > max || parseInt(event.target.value) < min) {
                return false;
            }

        }

        this.setState({
            month: event.target.value

        });
    }

    submit = () => {
        // Validate  
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { currentDetail, selectedPackage, note, activedDate, exprireDate, currentUser, type } = this.state;

        // store_id;
        // - pkg_name;
        // - pkg_actived_at;
        // - pkg_exp;
        // console.log(currentDetail);
        const data = {
            web_id: currentDetail.web_id || false,
            store_id: currentDetail.store_id,
            detail_id: currentDetail._id,
            order_id: currentDetail.order_id,
            ladi_uid: currentUser._id,
            email: currentUser.email,
            app_code: currentDetail.pkg_type,
            pkg_actived_at: activedDate,
            pkg_exp: exprireDate,
            pkg_name: selectedPackage.package_name,
            note,
            type
        };

        // console.log(data); return;
        if (currentDetail.course_pkg_name != '') {
            data.is_course = true;
        }
        this.props.downgrade(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangePackage = (event) => {

        this.pickPackages(event.target.value);

    }

    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value

        });
    }

    handleClickOutside = () => {
        this.props.onCancel();
    }

    changeActivedate = (date) => {
        this.setState({
            activedDate: date
        }, () => {
            // this.getList();
        });

    }

    changeExpriredate = (date) => {
        this.setState({
            exprireDate: date
        }, () => {
            // this.getList();
        });

    }

    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value
        });
    }

    render() {
        this.inputsRef = new Set();
        const { packages, selectedPackage, loaderPackages, currentDetail } = this.state;
        // console.log(this.state.order);
        const dtdate = new Date(currentDetail.created_at);
        let formatted_date = baseHelper.parseTime(dtdate);
        const title = "Huỷ order";
        return (
            <div ref={this.ref} id="modal-downgrade-store" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-downgrade-store" style={{ zIndex: 21 }}>
                <div className={`ladiui-modal-content ${loaderPackages ? 'loader' : ''}`} style={{ maxWidth: '1200px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-downgrade-store"><i className="ladi-icon icon-e-remove" data-dismiss="modal-downgrade-store" onClick={this.props.onCancel} /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className={"ladiui-modal-body"}>

                        <div className="order-box">
                            {/* <ul> */}
                            <div className="ladiui-row" style={{ height: '50px' }}>

                                <div className="col-50">
                                    <strong>Tổng tiền: </strong>
                                    {
                                        currentDetail.subtotal && currentDetail.subtotal.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                    }
                                </div>


                                <div className="col-50">
                                    <strong>Ngày gia hạn: </strong>
                                    {formatted_date}
                                </div>
                            </div>
                            <div className="ladiui-row" style={{ height: '50px' }}>
                                <div className="col-50">
                                    <strong>Storeid: </strong>{currentDetail.store_id}
                                </div>
                                <div className="col-50">
                                    <strong>Store name: </strong>{currentDetail.store_name}
                                </div>
                            </div>
                            <div className="ladiui-row" style={{ height: '50px' }}>
                                <div className="col-50">
                                    <strong>Tên gói: </strong>{currentDetail.pkg_name}
                                </div>
                                <div className="col-50">
                                    <strong>Số tháng: </strong>{currentDetail.pkg_month}
                                </div>
                            </div>

                            {/* <li><strong>admin_id: ''</strong>{this.state.admin_id}</li> */}

                            {/* </ul> */}
                            {this.props.loading && <Spinner />}
                        </div>
                        <form>

                            <div>
                                <div className="ladiui-row" >
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <h3>Ngày hết hạn</h3>
                                        </div>
                                        <div className="ladiui form-group">
                                            <CustomDatePicker
                                                customClass="ladiui input date"
                                                selected={this.state.exprireDate}
                                                // onSelect={this.handleSelect} //when day is clicked
                                                onChange={this.changeExpriredate} //only when value has changed
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <h3>Ngày kích hoạt</h3>
                                        </div>
                                        <div className="ladiui form-group">
                                            <CustomDatePicker
                                                customClass="ladiui input date"
                                                selected={this.state.activedDate}
                                                // onSelect={this.handleSelect} //when day is clicked
                                                onChange={this.changeActivedate} //only when value has changed
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="ladiui-form-group">

                                    <textarea name="note" className="ladiui textarea ladi-payment-info"
                                        value={this.state.note} onChange={this.onChangeTextArea}
                                        placeholder="Lí do huỷ order này"
                                    />
                                </div>
                                <div className="ladiui form-group condition-form">

                                    <h3>Lựa chọn gói dịch vụ</h3>
                                </div>
                                <div className="ladiui-row">
                                    {
                                        _.map(packages, (packageObj, idx) => {
                                            return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + packageObj.name + "-" + idx}>

                                                <input type="radio"

                                                    id={"package-downgrade" + packageObj.package_name}
                                                    name="selectedPackage"
                                                    value={packageObj.package_name}
                                                    onChange={this.onChangePackage}
                                                    // defaultChecked
                                                    checked={selectedPackage.package_name == packageObj.package_name}
                                                />

                                                <label htmlFor={"package-downgrade" + packageObj.package_name}>{packageObj.name}</label>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </form>


                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-downgrade-store"><i className="ladi-icon icon-e-remove" data-dismiss="modal-downgrade-store" onClick={this.props.onCancel} />Close</button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i> Áp dụng </button>

                        {/* {this.props.loading && <Spinner />} */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        downgrade: (store) => dispatch(storeActions.downgrade(store)),
    }
}

const mapStateToProps = (state) => ({ ...state.store });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DownGrade);