import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';

import moment from 'moment';
import * as types from '../../redux/futures/tools/types';
import appConst from '../../config/const';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "react-datepicker/dist/react-datepicker.css";

class ReportTraffic extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            fromDate: moment(firstDay),
            toDate: moment(new Date()),
            submitData: false,
            listIds: '',
            email: '',
            excelDatas: [],
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.muteActions = [];
        this.inputsRef = new Set();

    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {

            if (nextProps.action == types.REPORT_TRAFFIC) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    // this.setState({
                    //     message: nextProps.result
                    // })
                    window.LadiUI.toastr('success', '', nextProps.message);
                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }

        }
    }
    submit = () => {

        let { fromDate, toDate, listIds, email } = this.state;
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        listIds = listIds.split(',');
        _.map(listIds, email => {
            email = email.trim();
        });
        const jsonData = {
            from_date: fromDate,
            to_date: toDate,
            ladi_ids: listIds,
            email
        }
        this.props.reportApota(jsonData);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        });

    }

    onChangeTextArea = (event) => {

        this.setState({
            listIds: event.target.value
        });
    }

    onChangeInput = (event) => {

        this.setState({
            email: event.target.value
        });
    }

    render() {
        const { listIds, email } = this.state;
        const title = 'Chọn thời gian để xuất báo cáo';
        return (
            <div ref={this.ref} id="modal-report-traffic" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-report-traffic">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-report-traffic"><i className="ladi-icon icon-e-remove" data-dismiss="modal-report-traffic" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body" style={{ height: "600px" }}>
                        <div className="ladiui-tab-content" >

                            <DateRangePicker
                                startDateId="fromDate"
                                endDateId="toDate"
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState({
                                        fromDate: startDate,
                                        toDate: endDate,
                                    }, () => {
                                        if (endDate) {

                                        }
                                    })
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={
                                    <i className="ladi-icon icon-calendar" />
                                }
                                displayFormat={() => appConst.DEFAULT_FORMAT_DATE_NEW}
                                // startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                // endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => {
                                    return moment(new Date()).isSame(date, 'day');
                                }}
                            />

                        </div>

                        <div className="ladiui form-group">
                            <label className="ladiui-label">Email</label>
                            <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                name="email"
                                validationName="Email"
                                placeholder="Email"
                                value={email}
                                onChange={this.onChangeInput}
                                validations={{ isRequired: true, email: true }}
                            />
                        </div>
                        <div className="ladiui-row">
                            <h3>Danh sách id landingpage phân biệt bằng dấu chấm phẩy(,): </h3>
                        </div>
                        <div className="ladiui-form-group">
                            <textarea name="note" className="ladiui textarea ladi-payment-info"
                                value={listIds} onChange={this.onChangeTextArea} />
                        </div>


                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-report-traffic">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-report-traffic" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Gửi email
                        </button>

                    </div>
                </div>

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reportApota: (data) => dispatch(toolActions.reportTraffic(data)),

    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(ReportTraffic);