import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../components/Spinner';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import Input from '../../components/Input';
import { NavLink } from 'react-router-dom';

import * as types from '../../redux/futures/auth/types';




class ForgottenPassword extends React.Component {

    static propTypes = {
        email: PropTypes.string
    }

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillMount() {
        this.redirectAuth(this.props);
    }
    redirectAuth(props) {

        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        if (userInfo) {
            
            props.history.push('/');
             
        }

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action == types.FORGOTTEN_PASSWORD) {
            this.setState({
                loading : false,
                classApply : this.state.classNormal
            });
            if (nextProps.status) {

                window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    submit = () => {

        // Validate

        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {

            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }


        const { email } = this.state;
        const jsonData = {
            "email": email
        }
        this.props.forgottenPassword(jsonData);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangeInput = (event) => {

        this.setState({
            email: event.target.value
        });
    }


    render() {
        const { email } = this.state;
        // console.log(email);
        const search = this.props.location.search;
        return (
            <div className="ladiui auth">
                <div className="ladiui-logo">
                    <img src="https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg" alt='Ladipage.vn' />
                </div>
                <div className="ladiui-login">
                    <h1 className="form-title"></h1>
                    <h4>Vui lòng cung cấp email để lấy lại mật khẩu.</h4>
                    <div className="ladiui-login-form">
                        <div className="ladiui-form">
                            <div className="ladiui form-group">
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="email"
                                    validationName="Email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true, email: true }}
                                />
                            </div>
                            <div className="ladiui-form-group">
                                
                                <button 
                                    ref={this.ref}
                                    className={this.state.classApply} 
                                    onClick={this.submit}
                                    disabled={this.state.loading}
                                    >
                                    <i style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i> 
                                Gửi Email</button>
                            </div>
                        </div> 
                        <div className="question-form">
                            <NavLink to={{
                                pathname:"/signin",
                                search: search
                            }}> Quay lại trang đăng nhập </NavLink>
                        </div>
                    </div>
                </div>
                <div className="ladipage-terms">
                    <ul>
                        <li><a target='_blank' href={appConst.SUPPORT_URL.SUPPORT}>Trợ giúp</a></li>
                        <li><a target="_blank" href="https://docs.ladipage.vn/article/68-chinh-sach-bao-mat" >Chính sách bảo mật</a></li>
                            
                    </ul>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        forgottenPassword: (email) => dispatch(authActions.forgottenPassword(email)),
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);