import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import queryString from 'query-string';


import * as types from '../../redux/futures/auth/types';




class FacebookCallback extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props); 
        const callbackObj = queryString.parse(this.props.location.hash);
        
        this.signin(callbackObj);
    }

    componentWillMount() {
        this.redirectAuth(this.props);
      }

    redirectAuth(props) {
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        if (userInfo) {
            props.history.push('/');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action == types.OAUTH2_FACEBOOK) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
                this.props.history.push('/');
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    signin = (callbackObj) => { 
        this.props.signin(callbackObj); 
    }

    render() {
         
        return (
            <div className="ladiui auth">
                 
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signin: (callbackObj) => dispatch(authActions.oauth2Facebook(callbackObj))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(FacebookCallback);