import { applyMiddleware, createStore, compose } from 'redux';

import createLogger from '../middlewares/logger';
import { serviceMiddleware, localStorageMiddleware } from '../middlewares/services';

import thunk from 'redux-thunk';

import createRootReducer from '../futures';

import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
const getMiddleware = () => {

    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(routerMiddleware(history), thunk, serviceMiddleware, localStorageMiddleware);
    } else {
        // Enable additional logging in non-production environments.
        return applyMiddleware(routerMiddleware(history), thunk, serviceMiddleware, localStorageMiddleware);//, createLogger);
    }

};

export const store = createStore(createRootReducer(history), compose(getMiddleware()));
