import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.ACTIVITY_LIST,
    meta: {
        endpoint: endpoint.ACTIVITY_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});   

const exportExcel = (data) => ({
    type: types.EXPORT_ACTIVITY,
    meta: {
        endpoint: endpoint.EXPORT_ACTIVITY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    list,
    exportExcel
}