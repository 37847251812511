import React from 'react';

import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import authActions from '../../redux/futures/auth/actions';
import * as types from '../../redux/futures/auth/types';
import queryString from 'query-string';
import Input from '../../components/Input';
import ReCAPTCHA from "react-google-recaptcha";
import _ from 'lodash';
import appConst from '../../config/const';
// import baseHelper from '../../helpers/BaseHelper';

class Signin extends React.Component {

    constructor(props) {
        super(props);
        const email = localStorage.getItem('email');
        const parsed = queryString.parse(this.props.location.search);
        this.state = {
            email: (email && email != '') ? email : '',
            password: '',
            refObject: parsed,
            loading: false,
            typeInput: 'password',
            iconClass: 'icon-preview',
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            recaptchaToken: "",
            recaptchaLoaded: false,
        }

        this.recaptchaRef = React.createRef();
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action === types.SIGNIN) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                localStorage.setItem('email', this.state.email);
                this.props.history.push('/');
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
                this.refreshCaptcha();
            }
        }
        if (nextProps.action === types.GET_URL_FACEBOOK || nextProps.action === types.GET_URL_GOOGLE) {
            if (nextProps.status) {
                window.location.href = nextProps.url;
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    signIn = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {

            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { token } = this.state;
        const data = {
            email: this.state.email.toLowerCase(),
            password: this.state.password,
            token
        }
        this.props.signIn(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    geturlFaceBook = () => {
        this.props.getUrlFacebook(this.state.refObject);
    }

    geturlGoogle = () => {
        this.props.getUrlGoogle(this.state.refObject);
    }

    showPassword = () => {
        const { typeInput } = this.state;
        if (typeInput == 'password') {
            this.setState({
                typeInput: 'text',
                iconClass: 'icon-b-preview'
            });
        }
        if (typeInput == 'text') {
            this.setState({
                typeInput: 'password',
                iconClass: 'icon-preview'
            });

        }
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.signIn();
        }
    }

    captchaSuccess = (token) => {
        const that = this;
        return new Promise(function (resolve, reject) {
            that.setState(
                {
                    recaptchaToken: token,
                },
                () => {
                    resolve();
                }
            );
            //Your code logic goes here

            //Instead of using 'return false', use reject()
            //Instead of using 'return' / 'return true', use resolve()
        }); //end promise

        // return;
        // this.recaptchaDone(token);
    }
    refreshCaptcha = () => {
        this.recaptchaRef.reset();
    }

    recaptchaLoaded(token) {
        // console.log("recaptchaLoaded:", token);
    }

    onChangeCaptcha = (value) => {
        this.setState({
            token: value,
        });
    }



    render() {

        const { email, password, typeInput, iconClass } = this.state;
        const search = this.props.location.search;

        return (
            <div className="ladiui auth">
                <div className="ladiui-logo">
                    <img src="https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg" alt='Ladipage.vn' />
                </div>
                <div className="ladiui-login">
                    <h1 className="form-title">Đăng nhập</h1>
                    <div className="ladiui-login-form">
                        <div className="ladiui-form">
                            <div className="ladiui-form-group">
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="email"
                                    validationName="Email"
                                    placeholder="Email đăng nhập"
                                    value={email}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true, email: true }}
                                    onKeyDown={this._handleKeyDown}
                                />
                                {/* <input name="email" type="text" className="ladiui input" placeholder="Your Email" value={email} onChange={this.handleChangeInput} onKeyDown={this._handleKeyDown} /> */}
                            </div>
                            <div className="ladiui-form-group">
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="password"
                                    validationName="Password"
                                    placeholder="Mật khẩu đăng nhập"
                                    value={password}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true, min: 6, max: 50 }}
                                    type={typeInput}
                                    onKeyDown={this._handleKeyDown}
                                />
                                <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                                {/* <input name="password" type="password" className="ladiui input" placeholder="Your password" value={password} onChange={this.handleChangeInput} onKeyDown={this._handleKeyDown} /> */}
                            </div>
                            <div className="ladiui-form-group">
                                <ReCAPTCHA
                                    ref={(ref) => (this.recaptchaRef = ref)}
                                    sitekey={appConst.KEY_CAPCHA}
                                    onChange={this.onChangeCaptcha}
                                    onExpired={this.refreshCaptcha}
                                    type={"audio"}
                                />
                            </div>
                            <div className="ladiui-form-group password-box">
                                <span className="forgot-password">
                                    <NavLink to={{
                                        pathname: "/forgottenpass",
                                        search: search
                                    }}> Quên mật khẩu? </NavLink>
                                </span>
                            </div>
                            <div className="ladiui-form-group">
                                <button
                                    ref={this.ref}
                                    className={this.state.classApply}
                                    onClick={this.signIn}
                                    disabled={this.state.loading}
                                >
                                    <i className="" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                                    Đăng nhập</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ladipage-terms">
                    <ul>
                        <li><a target='_blank' href={appConst.SUPPORT_URL.SUPPORT}>Trợ giúp</a></li>
                        <li><a target="_blank" href="https://docs.ladipage.vn/article/68-chinh-sach-bao-mat" >Chính sách bảo mật</a></li>

                    </ul>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (data) => dispatch(authActions.signIn(data)),
        getUrlFacebook: (refObject) => dispatch(authActions.getUrlFacebook(refObject)),
        getUrlGoogle: (refObject) => dispatch(authActions.getUrlGoole(refObject)),
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signin));