import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: action.currentType,
                action: '',
            }
        }
        case types.SIGNUP: {
            return {
                ...state,
                action: types.SIGNUP,
                status: action.status,
                message: action.message,
            }
        }
        case types.SIGNIN: {
            return {
                ...state,
                action: types.SIGNIN,
                status: action.status,
                message: action.message,
            }
        }
        case types.COMPLETE_REGISTER: {
            return {
                ...state,
                action: types.COMPLETE_REGISTER,
                status: action.status,
                message: action.message,
            }
        }
        case types.GET_ADMIN_INFO: {
            return {
                ...state,
                verifed: action.status,
            }
        }
        case types.UPDATE_ADMIN_INFO: {
            return {
                ...state,
                action: types.UPDATE_ADMIN_INFO,
                userInfo: (action.status && action.payload) ? action.payload : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.CHANGE_PASSWORD: {
            return {
                ...state,
                action: types.CHANGE_PASSWORD,
                status: action.status,
                message: action.message,
            }
        }
        case types.FORGOTTEN_PASSWORD: {
            return {
                ...state,
                action: types.FORGOTTEN_PASSWORD,
                status: action.status,
                message: action.message,
            }
        }
        case types.RESET_PASSWORD: {
            return {
                ...state,
                action: types.RESET_PASSWORD,
                status: action.status,
                url: action.status ? action.payload.url : '',
                message: action.message,
            }
        }
        case types.GET_URL_FACEBOOK: {
            return {
                ...state,
                action: types.GET_URL_FACEBOOK,
                status: action.status,
                url: action.payload.url,
                message: action.message,
            }
        }
        case types.GET_URL_GOOGLE: {
            return {
                ...state,
                action: types.GET_URL_GOOGLE,
                status: action.status,
                message: action.message,
            }
        }
        case types.OAUTH2_FACEBOOK: {
            return {
                ...state,
                action: types.OAUTH2_FACEBOOK,
                status: action.status,
                message: action.message,
            }
        }
        case types.OAUTH2_GOOGLE: {
            return {
                ...state,
                action: types.OAUTH2_GOOGLE,
                status: action.status,
                message: action.message,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                loading: false,
            }
        }

        default:
            return state;
    }
}

