export const LIST_STORE = 'LIST_STORE';
export const LIST_PACKAGE = 'LIST_PACKAGE';
export const LIST_DEALS = 'LIST_DEALS';
export const LIST_PACKAGE_LADIBOOST = 'LIST_PACKAGE_LADIBOOST';
export const LIST_PACKAGE_COURSE = 'LIST_PACKAGE_COURSE';
export const UPGRADE_STORE = 'UPGRADE_STORE';
export const INFO_LADIBOOST = 'INFO_LADIBOOST';
export const ACTIVE_LADIBOOST = 'ACTIVE_LADIBOOST';
export const DOWNGRADE_STORE = 'DOWNGRADE_STORE';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const GET_USERINFO = 'GET_USERINFO';
export const ACTIVE_STORE = 'ACTIVE_STORE';
export const UPGRADE_STORE_LIST = 'UPGRADE_STORE_LIST';
export const CHANGE_PACKAGE_STORE = 'CHANGE_PACKAGE_STORE';