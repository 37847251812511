import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';

import * as types from '../../redux/futures/tools/types';
import ConfirmModal from '../../components/ConfirmModal';




class DeleteDomain extends React.Component {
 
    constructor(props) {
        super(props);

        this.state = {
            domain:'',
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: '',
            message: ""
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.initialState = _.cloneDeep(this.state);
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            
             
            if (nextProps.action == types.DELETE_DOMAIN) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });  
                if (nextProps.status) {
                    window.LadiUI.toastr('success', '', nextProps.message);
                    this.setState({
                        message: nextProps.result
                    })
                    setTimeout(() => {
                        window.LadiUI.closeModalById('confirm-delete-domain');
                          
                    }, 500);
                    // setTimeout(function () {
                    //     window.LadiUI.closeModal(_seft.ref.current);
                    // },1000);
                    
                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            } 
        }
    } 
    submit = (selectedID) => {
      
        this.selectedID = selectedID;
        
        window.LadiUI.showModalById('confirm-delete-domain');
         
    }
 
    onChangeInput = (event) => { 
         
        this.setState({
            domain: event.target.value
        });
    } 
 

    resetForm = (event) => {
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
        
    }

    render() {
        const { domain,message } = this.state;

         
        const title = 'Xoá tên miền';
        const isLoadingDelete = this.props.loading && (this.props.waitting == types.DELETE_DOMAIN);
        return (
            <div ref={this.ref} id="modal-delete-domain" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-delete-domain">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-delete-domain"><i className="ladi-icon icon-e-remove" data-dismiss="modal-delete-domain" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Tên miền</label> 
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="domain"
                                        validationName="Domain"
                                        placeholder="Tên miền"
                                        value={domain}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}
                                    /> 
                                       
                                </div> 
                                
                            </div>
                        </form>
                        {
                            message && 
                            <label className="ladiui-label">{message}</label> 
                        }

                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-delete-domain">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-delete-domain" />Đóng
                        </button>
                        <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.submit}
                        disabled={this.state.loading}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i>  
                                    Xoá domain
                         </button>
                         
                    </div>
                </div>
                <ConfirmModal
                    id="confirm-delete-domain"
                    title="Xoá tên miền"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.props.deleteDomain({
                        domain: this.state.domain
                    })}
                    isLoading={isLoadingDelete}
                />

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDomain: (data) => dispatch(toolActions.deleteDomain(data)) 
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDomain);