import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';

import { connect } from 'react-redux';
import timeOnsiteActions from '../../redux/futures/timeonsite/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/timeonsite/types';
import LoadingTable from '../../components/LoadingTable';
import moment from 'moment';
// import Form from './components/Form';
import View from '../users/components/View';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import "react-datepicker/dist/react-datepicker.css";

class TimeOnSite extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            searchPkg: {
                name: appConst.PKG_TIME_ONSITE.FREE.name,
                value: appConst.PKG_TIME_ONSITE.FREE.value,
                selected: appConst.PKG_TIME_ONSITE.FREE,
            },
            activePage: 1,
            fromDate: moment(firstDay),
            toDate: moment(new Date()),
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            focusedInput: null,
            store_id:"",
            params:{}
        }

        this.sortBy = {
            'created_at': 'ASC',
            'status': 'ASC',
        }
      
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.SESSION_LIST) {
            
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            } 
        }
        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.SESSION_USER_INFO) {
            
            if (nextProps.status) {
                console.log(nextProps.user)
                this.setState({
                    currentUser: nextProps.user
                })
            }
        }
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {

        const { selectedLimit, store_id, searchPkg } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        
        let {fromDate,toDate} =  this.state;
        fromDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            milisecond: 0
        });

        toDate.set({
            hour: 23,
            minute: 59,
            second: 59,
            milisecond: 999
        });

        const data = {
            "limit": selectedLimit,
            "page": (activePage - 1) * selectedLimit,
            "from_date": fromDate,
            "to_date": toDate,
            "pkg_name": searchPkg.selected.value
        };
        if (store_id != '') {
            data.store_id = store_id;
            delete data.pkg_name;
        }
        console.log(data);
        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {

            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangesearchPkg = (option) => {
        
        this.setState({
            searchPkg: { ...this.state.searchPkg, selected: option },
        }, () => {
            this.list();
        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/
 
     
    /*******************************CHECK AND ACTIONS***************************/

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (ladiUID) => {
        const data = {
            "ladi_uid": ladiUID
        };
        this.props.getUserInfo(data);
    } 

    copyToClipboard = (log) => {
        // console.log(store.store_id);
        this.setState({
            params: log.params
        },()=> {
            this.textAreaRef.select();
            document.execCommand('copy');
            window.LadiUI.toastr('success', '', 'Đã copy vào clipboard');
        })
        
      };
    /****************************************************************/


    render() {

        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchPkg, store_id } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.sessions || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.sessions.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.SESSION_LIST);


        return (
            <div>
                <h1 className="page-title">Lịch sử hoạt động</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo store ID" name="store_id" value={store_id} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">
                            <DateRangePicker
                                startDateId="fromDate"
                                endDateId="toDate"
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState({
                                        fromDate: startDate,
                                        toDate: endDate,
                                    }, () => {
                                       if (endDate) {
                                            this.list();
                                        }
                                        
                                    })
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={
                                    <i className="ladi-icon icon-calendar" />
                                }
                                displayFormat={()=>appConst.DEFAULT_FORMAT_DATE_NEW}
                                // startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                // endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => {
                                    return moment(new Date()).isSame(date, 'day');
                                }}
                            />
                        </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchPkg.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.PKG_TIME_ONSITE, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangesearchPkg(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>
                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                            {/* "ladi_uid": "5d92f3eaecf2795fcb4ebe18",
                "session_count": 103,
                "time_on_site": 99921092 */}
                                                {/* <th className="text-left">UID</th> */}
                                                <th className="text-left">LadiUID</th>
                                                <th className="text-left">Số phiên</th>
                                                
                                                <th className="text-left">Thời gian</th>
                                                <th className="text-left">Thời gian trung bình</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.sessions, log => {

                                                    return <tr key={log.ladi_uid}> 
                                                        <td>{log.ladi_uid}</td>
                                                        <td>{log.session_count}</td>
                                                        <td>{baseHelper.parseSecondToTime(Math.floor(log.total_time_on_site /1000))}</td>
                                                        <td>{baseHelper.parseSecondToTime(Math.floor(log.avg_time /1000))}</td>
                                                        <td>

                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>
                                                                        {/* <li><a onClick={() => this.print(invoice)} className="ladiui open-modal" modal="modal-print" >Print</a></li> */}
                                                                        <li><a onClick={() => this.view(log.ladi_uid)} className="ladiui open-modal" modal="modal-userview" >Chi tiết</a></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Hiển thị</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                </div>
                <View 
                     currentUser={this.state.currentUser} 
                 />
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(timeOnsiteActions.list(data)),
        getUserInfo: (data) => dispatch(timeOnsiteActions.getUserinfo(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.timeonsite });

export default connect(mapStateToProps, mapDispatchToProps)(TimeOnSite);