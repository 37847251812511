import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import Input from '../../../components/Input';


import * as types from '../../../redux/futures/auth/types';
const FormData = require('form-data');



class EditProfile extends React.Component {

    static propTypes = {
        userInfo: PropTypes.object
    }

    constructor(props) {
        super(props);
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

        this.state = {
            file: null,
            userInfo: userInfo,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            

        };
        this.inputsRef = new Set();
        this.ref = React.createRef();

    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.userInfo && this.state.userInfo !== nextProps.userInfo) {
            if (nextProps.userInfo.admin_id) {
                this.setState({
                    userInfo: nextProps.userInfo
                });
            }
        }
        if (nextProps.action == types.UPDATE_ADMIN_INFO) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });

            if (nextProps.status) {
                this.setState({
                    userInfo: nextProps.userInfo
                });

                // window.LadiUI.closeModal(this.ref.current);
                window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { userInfo } = this.state;
        const jsonData = {
            "fullname": userInfo.fullname,
            "phone": userInfo.phone,
            "time_zone": userInfo.tiomezone,
            "language": userInfo.language
        }

        const form = new FormData();
        form.append('jsonData', JSON.stringify(jsonData));
        if (this.state.file) {
            form.append('avatar', this.state.file, this.state.file.name);
        }
        this.props.update(form);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangeInput = (event) => {

        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [event.target.name]: event.target.value
            }
        });
    }

    fileChangedHandler = (event) => {
        const that = this;
        if (event.target.files && event.target.files[0]) {

            const reader = new FileReader();
            reader.onload = function () {

                that.setState({
                    userInfo: {
                        ...that.state.userInfo,
                        avatar: reader.result
                    }
                });

            };
            reader.readAsDataURL(event.target.files[0]);
        }
        this.setState({ file: event.target.files[0] })
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

        this.state = {
            userInfo: userInfo ? userInfo : {}
        }

        window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
            e.classList.remove('error');
        });
    }

    onError = () => {
         
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                avatar: appConst.DEFAULT_AVATAR
            }
        });
    }

    render() {
        const { userInfo } = this.state;
        // const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        const title = 'Edit Profile';

        return (
            <div ref={this.ref} id="modal-profile" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-profile"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content">
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-profile"><i className="ladi-icon icon-e-remove" data-dismiss="modal-profile" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div className="col-50 text-center">
                                        <div className="profile-avatar rounded" >
                                            <img
                                                src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR}
                                                onError={this.onError}
                                                className="ladi-profile-avatar" />
                                        </div>
                                        <div className="ladiui-button-upload text-center">
                                            <button className="ladiui button success icon sm">
                                                <i className="ladi-icon icon-file-upload" /> Choose Files</button>
                                            <input type="file" name="file" onChange={this.fileChangedHandler} />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Full Name</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="fullname"
                                                validationName="FullName"
                                                placeholder="FullName"
                                                value={userInfo.fullname}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                            />
                                            {/* <input name="full_name" className="ladiui input" value={userInfo.fullname} /> */}
                                        </div>

                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Phone</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="phone"
                                                validationName="Phone"
                                                placeholder="Phone"
                                                value={userInfo.phone}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: false }}
                                            />
                                            {/* <input name="phone" className="ladiui input" value={userInfo.phone} /> */}
                                        </div>
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email</label>

                                            <input name="email" className="ladiui input" value={userInfo.email} disabled="disable" />
                                        </div>
                                        {/* <div className="ladiui form-group">
                                            <label className="ladiui-label">Language</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="language"
                                                validationName="Language"
                                                placeholder="Language"
                                                value={userInfo.language}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                            /> */}
                                        {/* <input name="language" className="ladiui input" value={userInfo.language} /> */}
                                        {/* </div> */}
                                        {/* <div className="ladiui form-group">
                                            <label className="ladiui-label">Timezone</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="name"
                                                validationName="Name"
                                                placeholder="Name"
                                                value={userInfo.name}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                            /> */}
                                        {/* <input name="timezone" className="ladiui input" value={userInfo.timezone} /> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-profile">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-profile" />Close
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Save
                         </button>

                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (userInfo) => dispatch(authActions.updateAdminInfo(userInfo))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);