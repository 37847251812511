import React from 'react';
import PropTypes from 'prop-types';
import affilateActions from '../../../redux/futures/affilate/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import appConst from '../../../config/const';
import Input from '../../../components/Input';

import * as types from '../../../redux/futures/affilate/types';




class Form extends React.Component {

    static propTypes = {
        affilateInfo: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            affilateInfo: {
                _id: "",
                ladi_uid: "",

                email: "",

                code: "",

                payment_info: "", // thông tin thanh toán

                level: '1',

                commission_1: '30',

                commission_2: '15',

                customer_commission: '0',

                commission_tier_2: '0'
            },
            lastCode:'',
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: ''
        };
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.defaultState = _.cloneDeep(this.state.affilateInfo);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {

            // if (nextProps.currentAffilate && this.state.affilateInfo !== nextProps.currentAffilate) {
            // this.setState({
            //     affilateInfo: nextProps.currentAffilate
            // });
             
            if (nextProps.mode == appConst.FORM_MODE.EDIT || nextProps.mode == appConst.FORM_MODE.CREATE) {
                // if (nextProps.action != types.CHECK_AFFILATE_CODE && nextProps.action != types.CHECK_AFFILATE_EMAIL 
                //     && nextProps.action != commonTypes.ASYNC_END && nextProps.action != commonTypes.ASYNC_START) {
                if (nextProps.mode == appConst.FORM_MODE.CREATE
                    && this.props.currentAffilate != nextProps.currentAffilate){
                    this.resetForm();
                }
                if (nextProps.currentAffilate && nextProps.mode == appConst.FORM_MODE.EDIT
                    && this.props.currentAffilate != nextProps.currentAffilate){
                    const { lastCode } = this.state;
                     
                    console.log('nextProps.action:', nextProps.action,'-lastCode:',lastCode);
                    this.setState({
                        affilateInfo: _.pick(nextProps.currentAffilate, _.keys(this.state.affilateInfo)),
                        mode: nextProps.mode
                    });
                    

                }
          
            }

            // }
            if (nextProps.action == types.ACTIVE_AFFILATE
                || nextProps.action == types.UPDATE_AFFILATE) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    const _seft = this;
                    window.LadiUI.toastr('success', '', nextProps.message);
                    setTimeout(function () {
                        window.LadiUI.closeModal(_seft.ref.current);
                        _seft.setState({
                            ..._.cloneDeep(_seft.initialState)
                        })
                    }, 1000);

                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
            if (nextProps.action == types.CHECK_AFFILATE_CODE || nextProps.action == types.CHECK_AFFILATE_EMAIL) {
                const inputsRef = _.compact(Array.from(this.inputsRef));
                _.map(inputsRef, ref => {

                    if (ref.props.name == nextProps.err_name) {
                        ref.hardErr(!nextProps.status);
                    }
                });
                if (nextProps.status) {
                    window.LadiUI.toastr('success', '', nextProps.message);
                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { affilateInfo } = this.state;
        // console.log(affilateInfo);
        if (affilateInfo.commission_1 < affilateInfo.commission_2) { 
            window.LadiUI.toastr('error', '', 'Hoa hồng level 2 không thể lớn hơn level 1');
            return;
        }
        // if (affilateInfo.commission_1 < affilateInfo.customer_commission ||  affilateInfo.commission_2 < affilateInfo.customer_commission) { 
        //     window.LadiUI.toastr('error', '', 'Hoa hồng cho khách  không thể lớn hơn hoa hồng của affilate');
        //     return;
        // }
        if (this.props.mode === appConst.FORM_MODE.CREATE) {
            const jsonData = {
                "email": affilateInfo.email.toLowerCase(),
                "code": affilateInfo.code.toLowerCase(),
                "payment_info": affilateInfo.payment_info || '',
                "level": affilateInfo.level,
                "commission_1": affilateInfo.commission_1,
                "commission_2": affilateInfo.commission_2,
                "customer_commission": affilateInfo.customer_commission,
                // "commission_tier_2": affilateInfo.commission_tier_2
            }
            this.props.create(jsonData);
        } else {
            const jsonData = {
                "id": affilateInfo._id,
                "code": affilateInfo.code.toLowerCase(),
                "payment_info": affilateInfo.payment_info || '',
                "level": affilateInfo.level,
                "commission_1": affilateInfo.commission_1,
                "commission_2": affilateInfo.commission_2,
                "customer_commission": affilateInfo.customer_commission,
                // "commission_tier_2": affilateInfo.commission_tier_2
            }
            this.props.update(jsonData);
        }
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    checkCode = () => {
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate && ref.props.name == 'code') {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { affilateInfo } = this.state;
        const jsonData = {
            "code": affilateInfo.code
        }
        this.props.checkCode(jsonData);
    }

    checkEmail = () => {
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate && ref.props.name == 'email') {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { affilateInfo } = this.state;
        const jsonData = {
            "email": affilateInfo.email
        }
        this.props.checkEmail(jsonData);

    }

    onChangeInput = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) > max || parseInt(event.target.value) < min) {
                return false;
            }

        }
        
        this.setState({
            affilateInfo: {
                ...this.state.affilateInfo,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeTextArea = (event) => {

        this.setState({
            affilateInfo: {
                ...this.state.affilateInfo,
                [event.target.name]: event.target.value
            }
        });
    }


    resetForm = (event) => {
        // console.log('resetForm');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            ..._.cloneDeep(this.initialState)
        })
        // window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
        //     e.classList.remove('error');
        // });
    }

    render() {
        const { affilateInfo } = this.state;

        // console.log(affilateInfo);
        const title = 'Thông tin Affiliate ';
        this.inputsRef = new Set();
        return (
            <div ref={this.ref} id="modal-affilateform" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-affilateform">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-affilateform"><i className="ladi-icon icon-e-remove" data-dismiss="modal-affilateform" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email</label>


                                            {  
                                                (this.props.mode == appConst.FORM_MODE.EDIT) ?
                                                     <input name="email" className="ladiui input" value={affilateInfo.email} disabled="disable"/> : 
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="email"
                                                    validationName="Email"
                                                    placeholder="Email"
                                                    value={affilateInfo.email}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: true, email: true }}
                                                />
                                            }

                                            {
                                                (this.props.mode == appConst.FORM_MODE.EDIT) ?
                                                    <div> </div> :
                                                    <i className="ladi-icon icon-zoom ladi-date-picker-icon" onClick={this.checkEmail}></i>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Affiliate Code</label>
                                            
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="code"
                                                validationName="Code"
                                                placeholder="Code"
                                                value={affilateInfo.code}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                            />
                                             
                                            <i className="ladi-icon icon-zoom ladi-date-picker-icon" onClick={this.checkCode}></i>
                                             

                                        </div>
                                    </div>

                                </div>
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Thông tin thanh toán</label>
                                    <textarea name="payment_info" className="ladiui textarea ladi-payment-info" value={affilateInfo.payment_info} onChange={this.onChangeTextArea} />

                                </div>

                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Hoa hồng C1</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="commission_1"
                                                validationName="Commission1"
                                                placeholder="Commission1"
                                                value={affilateInfo.commission_1 + ""}
                                                onChange={this.onChangeInput}
                                                type="number"
                                                onKeyPress={this.onKeyPress}
                                                validations={{ isRequired: true, min: 0, max: 100 }}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Hoa hồng C2</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="commission_2"
                                                validationName="Commission2"
                                                placeholder="Commission2"
                                                value={affilateInfo.commission_2 + ""}
                                                onChange={this.onChangeInput}
                                                type="number"
                                                onKeyPress={this.onKeyPress}
                                                validations={{ isRequired: true, min: 0, max: 100 }}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="col-50">

                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Commission Tier2</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="commission_tier_2"
                                                validationName="Commission Tier2"
                                                placeholder="Commission Tier2"
                                                value={affilateInfo.commission_tier_2+""}
                                                onChange={this.onChangeInput}
                                                type="number" 
                                                onKeyPress={this.onKeyPress}
                                                validations={{ isRequired: true, min: 0, max: 100 }}
                                            />
                                        </div>
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Customer Commission </label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="customer_commission"
                                                validationName="CustomerCommission"
                                                placeholder="CustomerCommission"
                                                value={affilateInfo.customer_commission+""}
                                                onChange={this.onChangeInput}
                                                type="number"
                                                onKeyPress={this.onKeyPress}
                                                validations={{ isRequired: true, min: 0, max: 100 }}
                                            />
                                        </div>
                                    </div> */}


                                </div>
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Hoa Hồng cho khách hàng</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="customer_commission"
                                        validationName="CustomerCommission"
                                        placeholder="Hoa Hồng cho khách hàng"
                                        value={affilateInfo.customer_commission+""}
                                        onChange={this.onChangeInput}
                                        type="number"
                                        onKeyPress={this.onKeyPress}
                                        validations={{ isRequired: true, min: 0, max: 100 }}
                                    />
                                </div>   

                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-affilateform">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-affilateform" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                         </button>
                        {/* {(this.props.loading == types.UPDATE_PAYMENT_INFO) && <Spinner />} */}
                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create: (data) => dispatch(affilateActions.createAffilate(data)),
        update: (data) => dispatch(affilateActions.updateAffilate(data)),
        checkEmail: (data) => dispatch(affilateActions.checkEmail(data)),
        checkCode: (data) => dispatch(affilateActions.checkCode(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.affilate });

export default connect(mapStateToProps, mapDispatchToProps)(Form);