import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.COMMISSION_LIST: {
            return {
                ...state,
                action: types.COMMISSION_LIST,
                commissions: action.status ? action.payload.commissions : [],
                excelDatas: action.status ? action.payload.excelDatas : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.COMMISSION_LADIFLOW_LIST: {
            return {
                ...state,
                action: types.COMMISSION_LADIFLOW_LIST,
                commissions: action.status ? action.payload.commissions : [],
                excelDatas: action.status ? action.payload.excelDatas : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.COMMISSION_DETAIL: {
            return {
                ...state,
                action: types.COMMISSION_DETAIL,
                commission: action.status ? action.payload.commission : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.MY_COMMISSION: {
            return {
                ...state,
                action: types.MY_COMMISSION,
                commissions: action.status ? action.payload.commissions : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.EXPORT_COMMISSION: {
            return {
                ...state,
                action: types.EXPORT_COMMISSION,
                url: action.status ? action.payload.url : '',
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

