import React from 'react';
import PropTypes from 'prop-types';
import affilateActions from '../../../redux/futures/affilate/actions';
import partnerActions from '../../../redux/futures/partner/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import appConst from '../../../config/const';
import Input from '../../../components/Input';

import * as affilateTypes from '../../../redux/futures/affilate/types';
import * as partnerTypes from '../../../redux/futures/partner/types';




class ActivePartner extends React.Component {

    static propTypes = {
        affilateInfo: PropTypes.object,
        currentUser: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                status: "",

                point: "",

                credit: "",

                created_at: "",

                _id: "",

                code: ""

            },
            affilateInfo: {
                _id: "",
                ladi_uid: "", 

                code: "",

                payment_info: "", // thông tin thanh toán

                level: '1',

                commission_1: '30',

                commission_2: '15',

                customer_commission: '0',

                commission_tier_2: '0',

                credit: '0'
            },
            creditPack: {
                name: appConst.CREDIT_PACKAGES.PACK_1.name,
                value: appConst.CREDIT_PACKAGES.PACK_1.value,
                selected: appConst.CREDIT_PACKAGES.PACK_1.value,
                bonus: appConst.CREDIT_PACKAGES.PACK_1.bonus,
            },
            lastCode:'',
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: ''
        };
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.defaultState = _.cloneDeep(this.state.affilateInfo);
    } 
    componentDidMount() {
        
    }

    componentDidUpdate() {
        window.LadiUI.init();
        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {  
            if (nextProps.currentUser && this.state.currentUser !== nextProps.currentUser  && nextProps.currentUser._id && nextProps.currentUser._id != "") {
                this.setState({
                    currentUser: nextProps.currentUser
                });
                this.props.findAffilateInfo({ ladi_uid: nextProps.currentUser._id });
            }
            if (nextProps.affilate.status && nextProps.affilate.action == affilateTypes.GET_AFFILATE_INFO) {
                if (nextProps.affilate.status) {
                    this.setState({
                        affilateInfo: nextProps.affilate.affilateInfo,
                        lastCode: nextProps.affilate.affilateInfo.code
                    });
                }else {
                    this.setState({
                        affilateInfo: this.defaultState,
                        lastCode: ''
                    })
                }
            }
            if (nextProps.partner.action == partnerTypes.CREATE_PARTNER) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.partner.status) {
                    const _seft = this;
                    window.LadiUI.toastr('success', '', nextProps.partner.message);
                    setTimeout(function () {
                        window.LadiUI.closeModal(_seft.ref.current);
                        _seft.setState({
                            ..._.cloneDeep(_seft.initialState)
                        })
                    }, 1000);

                } else {
                    window.LadiUI.toastr('error', '', nextProps.partner.message);
                }
            }
            if (nextProps.affilate.action == affilateTypes.CHECK_AFFILATE_CODE || nextProps.affilate.action == affilateTypes.CHECK_AFFILATE_EMAIL) {
                const inputsRef = _.compact(Array.from(this.inputsRef));
                _.map(inputsRef, ref => {

                    if (ref.props.name == nextProps.affilate.err_name) {
                        ref.hardErr(!nextProps.affilate.status);
                    }
                });
                if (nextProps.affilate.status) {
                    window.LadiUI.toastr('success', '', nextProps.affilate.message);
                } else {
                    window.LadiUI.toastr('error', '', nextProps.affilate.message);
                }
            }
        }
    }

    submit = () => {
        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { affilateInfo, currentUser, lastCode, creditPack } = this.state;
        // console.log(affilateInfo);
        if (affilateInfo.commission_1 < affilateInfo.commission_2) { 
            window.LadiUI.toastr('error', '', 'Hoa hồng level 2 không thể lớn hơn level 1');
            return;
        }
        // if (affilateInfo.commission_1 < affilateInfo.customer_commission ||  affilateInfo.commission_2 < affilateInfo.customer_commission) { 
        //     window.LadiUI.toastr('error', '', 'Hoa hồng cho khách  không thể lớn hơn hoa hồng của affilate');
        //     return;
        // }
         
        const jsonData = {
            "email": currentUser.email.toLowerCase(),
            "code": lastCode.toLowerCase(),
            "payment_info": affilateInfo.payment_info || '',
            "level": affilateInfo.level,
            "commission_1": affilateInfo.commission_1,
            "commission_2": affilateInfo.commission_2,
            "customer_commission": affilateInfo.customer_commission,
            "credit": creditPack.selected,
            "bonus": creditPack.bonus,
        }
        this.props.createPartner(jsonData);
         
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    checkCode = () => {
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate && ref.props.name == 'code') {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { lastCode } = this.state;
        const jsonData = {
            "code": lastCode
        }
        this.props.checkCode(jsonData);
    }

    checkEmail = () => {
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate && ref.props.name == 'email') {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { affilateInfo } = this.state;
        const jsonData = {
            "email": affilateInfo.email
        }
        this.props.checkEmail(jsonData);

    }

    onChangeInput = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) > max || parseInt(event.target.value) < min) {
                return false;
            }

        }
        
        this.setState({
            affilateInfo: {
                ...this.state.affilateInfo,
                [event.target.name]: event.target.value
            }
        });
    }
    onChangeCode = (event) => { 
        this.setState({
            lastCode: event.target.value 
        });
    }

    onChangeTextArea = (event) => {

        this.setState({
            affilateInfo: {
                ...this.state.affilateInfo,
                [event.target.name]: event.target.value
            }
        });
    }


    resetForm = (event) => {
        // console.log('resetForm');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            ..._.cloneDeep(this.initialState)
        })
        // window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
        //     e.classList.remove('error');
        // });
    }

    onChangeCreditPack = (option) => {
        this.setState({
            creditPack: { ...this.state.creditPack, selected: option.value, bonus: option.bonus },
        })
    }

    render() {
        const { affilateInfo, currentUser, lastCode, creditPack } = this.state;

       
        const title = 'Kích hoạt partner: Khi kích hoạt partner, hệ thống sẽ tạo 1 tài khoản affilate, sau đó, tài khoản này sẽ được chuyển sang tài khoản partner';
        this.inputsRef = new Set();
        return (
            <div ref={this.ref} id="modal-user-active-partner" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-user-active-partner">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-user-active-partner"><i className="ladi-icon icon-e-remove" data-dismiss="modal-user-active-partner" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body" style={{overflow:"unset"}}>
                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email</label> 
                                            <input name="email" className="ladiui input" value={currentUser.email} disabled="disable"/> 
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Affiliate Code</label>
                                            {
                                                affilateInfo.code != "" 
                                                ?
                                                <input name="code" className="ladiui input" value={affilateInfo.code} disabled="disable"/> 
                                                :
                                                <div>
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="code"
                                                    validationName="Code"
                                                    placeholder="Code"
                                                    value={lastCode}
                                                    onChange={this.onChangeCode}
                                                    validations={{ isRequired: true }}
                                                />
                                                <i className="ladi-icon icon-zoom ladi-date-picker-icon" onClick={this.checkCode}></i>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Thông tin thanh toán</label>
                                    <textarea name="payment_info" className="ladiui textarea ladi-payment-info" value={affilateInfo.payment_info} onChange={this.onChangeTextArea} />

                                </div>

                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Hoa hồng C1</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="commission_1"
                                                validationName="Commission1"
                                                placeholder="Commission1"
                                                value={affilateInfo.commission_1 + ""}
                                                onChange={this.onChangeInput}
                                                type="number"
                                                onKeyPress={this.onKeyPress}
                                                validations={{ isRequired: true, min: 0, max: 100 }}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Hoa hồng C2</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="commission_2"
                                                validationName="Commission2"
                                                placeholder="Commission2"
                                                value={affilateInfo.commission_2 + ""}
                                                onChange={this.onChangeInput}
                                                type="number"
                                                onKeyPress={this.onKeyPress}
                                                validations={{ isRequired: true, min: 0, max: 100 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui-row">
                                    <div className="col-50">

                                        <div className="ladiui form-group">
                                            {/* <label className="ladiui-label">{`Credit (1 Credit <=> 1000VND)`}</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="credit"
                                                validationName="Credit"
                                                placeholder="Credit"
                                                value={affilateInfo.credit+""}
                                                onChange={this.onChangeInput}
                                                type="number" 
                                                onKeyPress={this.onKeyPress}
                                                validations={{ isRequired: true, min: 0, max: 50000 }}
                                            /> */}
                                            <label className="ladiui-label">{`Credit (1 Credit <=> 1000VND)`}</label>
                                            <div className="ladiui-select-options">
                                                
                                                    <span className="ladiui-select-init">{
                                                        creditPack.name
                                                        }</span>
                                                    <input type="hidden" name="select" defaultValue />
                                                    <ul>
                                                        {
                                                            _.map(appConst.CREDIT_PACKAGES, option =>
                                                                <li key={option.value} className="select-item" onClick={() => this.onChangeCreditPack(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                            )
                                                        }
                                                    </ul>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Hoa Hồng cho khách hàng</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="customer_commission"
                                                validationName="CustomerCommission"
                                                placeholder="CustomerCommission"
                                                value={affilateInfo.customer_commission+""}
                                                onChange={this.onChangeInput}
                                                type="number"
                                                onKeyPress={this.onKeyPress}
                                                validations={{ isRequired: true, min: 0, max: 100 }}
                                            />
                                        </div>
                                    
                                    </div>


                                </div>
                                 

                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-user-active-partner">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-user-active-partner" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                         </button>
                        {/* {(this.props.loading == types.UPDATE_PAYMENT_INFO) && <Spinner />} */}
                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create: (data) => dispatch(affilateActions.createAffilate(data)),
        update: (data) => dispatch(affilateActions.updateAffilate(data)),
        checkEmail: (data) => dispatch(affilateActions.checkEmail(data)),
        checkCode: (data) => dispatch(affilateActions.checkCode(data)),
        findAffilateInfo: (data) => dispatch(affilateActions.findAffilateInfo(data)),
        createPartner: (data) => dispatch(partnerActions.createPartner(data)),
    }
}

const mapStateToProps = (state) => ({ partner: { ...state.partner }, affilate: { ...state.affilate } });

export default connect(mapStateToProps, mapDispatchToProps)(ActivePartner);