import React from 'react';
import PropTypes from 'prop-types';
import invoiceActions from '../../../redux/futures/invoice/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';


import * as types from '../../../redux/futures/invoice/types';




class Detail extends React.Component {

    static propTypes = {
        currentInvoice: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            currentInvoice: {
                 
                amount: "",
                created_at: "",
                pay_date: "",
                status: "",
                updated_at: "",
                _id: ""
            },
            loading:true,
            commissions: []
        };

    }


    // componentDidMount() {
    //     window.LadiUI.init();
    //     this.invoiceDetail();
    // }

    componentWillReceiveProps(nextProps) {
        
        if (this.props.currentInvoice !== nextProps.currentInvoice) {
            this.setState({
                loading: true
            });
            this.setState({
                currentInvoice: _.pick(nextProps.currentInvoice, _.keys(this.state.currentInvoice))
            })
        }

        if (_.includes([types.INVOICE_DETAIL], nextProps.action)) {
            if (nextProps.status) {
                this.setState({
                    loading:false
                });
                this.setState({
                    commissions: nextProps.commissions,
                });
            } 
        }
        
    }

    invoiceDetail = () => {
        
        const ID = this.state.currentInvoice._id;
        const data = {
            id: ID
        }
        this.props.invoiceDetail(data);
    }

    render() {
        const { currentInvoice,commissions } = this.state;
         
        const title = 'Affiliate invoice detail';

        return (
            <div id="modal-invoicedetail" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-invoicedetail">
                <div className="ladiui-modal-content" style={{ maxWidth: '1200px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-invoicedetail"><i className="ladi-icon icon-e-remove" data-dismiss="modal-invoicedetail" /></button>
                        <h3>Affiliate invoice id: {currentInvoice._id}</h3>
                    </div>
                    <div className="ladiui-modal-body" >

                        <div className="order-box" style={{width:'970px',margin:'15px'}}>
                            {/* <ul> */}
                            <div className="ladiui-row" style={{ height: '50px' }}>
                                   
                                <div className="col-50">
                                    <strong>Amount: </strong>
                                    {
                                        currentInvoice.amount && currentInvoice.amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                    }
                                </div>
                                <div className="col-50">

                                    <strong>Created Date: </strong>
                                    {
                                        baseHelper.formatStrToDate(currentInvoice.created_at)
                                    }
                                </div>
                                
                            </div>
                            <div className="ladiui-row" style={{ height: '50px' }}>
                                <div className="col-50">

                                    <strong>Pay Date: </strong>
                                    {
                                        baseHelper.formatStrToDate(currentInvoice.pay_date)
                                    }
                                    </div>        

                                <div className="col-50">
                                    <strong>Invoice ID: </strong>
                                    {
                                        currentInvoice._id
                                    }
                                </div>
                            </div>

                            
                        </div>

                    
                        <table className="ladiui table">
                            <thead>
                                <tr>
                                    <th className="text-left">Order Amount</th>
                                    <th className="text-left">User Email</th>
                                    <th className="text-left">User Name</th>
                                    <th className="text-left">Commission Amount</th> 
                                    <th className="text-left">Created Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(commissions, commission => {
                                        

                                        return <tr key={commission._id}>
                                            <td>{commission.order_amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                            <td>{commission.userInfo.email}</td>
                                            <td>{commission.userInfo.fullname}</td>
                                            <td>{commission.commission_value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                            <td className="text-right">{baseHelper.formatStrToDate(commission.created_at)}</td>
                                        
                                        </tr>
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="ladiui-modal-footer">

                        {this.state.loading && <Spinner />}
                    </div>
 
                </div>

            </div >
        )


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        invoiceDetail: (data) => dispatch(invoiceActions.invoiceDetail(data))
    }
}

const mapStateToProps = (state) => ({ ...state.invoice });

export default connect(mapStateToProps, mapDispatchToProps)(Detail);