import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PropTypes from 'prop-types';
import CustomInputDate from './components/CustomInputDate';
import './CustomDatePicker.css';
import "react-datepicker/dist/react-datepicker.css";

class CustomDatePicker extends React.Component {

    constructor(props) {
        super(props);
    }
    onChange = (date) => {
        if (!date) {
            date = this.props.selected;
        }

        if (this.props.onChange) {
            this.props.onChange(date);
        }
    }
    render() {
        const { customClass } = this.props;

        return (
            <div>
                <DatePicker
                    customInput={(
                        <CustomInputDate
                            customClass={customClass}
                        />
                    )}
                    dateFormat='dd/MM/yyyy'
                    {
                    ...this.props
                    }
                    onChange={this.onChange}
                />
            </div>
        )
    }
}

export default CustomDatePicker;