import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.LIST_STORE,
    meta: {
        endpoint: endpoint.LIST_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const upgradeLadiboost = (data) => ({
    type: types.ACTIVE_LADIBOOST,
    meta: {
        endpoint: endpoint.ACTIVE_LADIBOOST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const upgrade = (data) => ({
    type: types.UPGRADE_STORE,
    meta: {
        endpoint: endpoint.UPGRADE_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const upgradeList = (data) => ({
    type: types.UPGRADE_STORE_LIST,
    meta: {
        endpoint: endpoint.UPGRADE_STORE_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const downgrade = (data) => ({
    type: types.DOWNGRADE_STORE,
    meta: {
        endpoint: endpoint.DOWNGRADE_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const listPackages = (data) => ({
    type: types.LIST_PACKAGE,
    meta: {
        endpoint: endpoint.LIST_PACKAGE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const getListCourse = (data) => ({
    type: types.LIST_PACKAGE_COURSE,
    meta: {
        endpoint: endpoint.LIST_PACKAGE_COURSE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const listDeals = (data) => ({
    type: types.LIST_DEALS,
    meta: {
        endpoint: endpoint.LIST_DEALS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const infoLadiboost = (data) => ({
    type: types.INFO_LADIBOOST,
    meta: {
        endpoint: endpoint.INFO_LADIBOOST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listPackagesLadiboost = () => ({
    type: types.LIST_PACKAGE_LADIBOOST,
    meta: {
        endpoint: endpoint.LIST_PACKAGE_LADIBOOST,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const clearToken = (data) => ({
    type: types.CLEAR_TOKEN,
    meta: {
        endpoint: endpoint.CLEAR_TOKEN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const getUserinfo = (data) => ({
    type: types.GET_USERINFO,
    meta: {
        endpoint: endpoint.GET_USERINFO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const activeStore = (data) => ({
    type: types.ACTIVE_STORE,
    meta: {
        endpoint: endpoint.ACTIVE_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const changePackageStore = (data) => ({
    type: types.CHANGE_PACKAGE_STORE,
    meta: {
        endpoint: endpoint.CHANGE_PACKAGE_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


export default {
    list,
    upgrade,
    upgradeLadiboost,
    infoLadiboost,
    upgradeList,
    downgrade,
    activeStore,
    getListCourse,
    listPackages,
    listDeals,
    clearToken,
    getUserinfo,
    changePackageStore,
    listPackagesLadiboost
}