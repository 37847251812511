import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.LIST_PAYMENT: {
            return {
                ...state,
                action: types.LIST_PAYMENT,
                payments: action.status ? action.payload.payments : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.MY_PAYMENT: {
            return {
                ...state,
                action: types.MY_PAYMENT,
                payments: action.status ? action.payload.payments : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }

        case types.DETAIL_PAYMENT: {
            return {
                ...state,
                action: types.DETAIL_PAYMENT,
                details: action.status ? action.payload.details : [],
                order: action.status ? action.payload.order : {},
                status: action.status,
                message: action.message,
            }
        } 

        case types.CONFIRM_BANK: {
            return {
                ...state,
                action: types.CONFIRM_BANK,
                status: action.status,
                message: action.message,
            }
        }

        default:
            return state;
    }
}

