import React from 'react';
import { connect } from 'react-redux';
import authActions from '../../../../redux/futures/auth/actions';
import { withRouter, NavLink } from 'react-router-dom';
import appConst from '../../../../config/const';
import baseHelper from '../../../../helpers/BaseHelper';

class Topbar extends React.Component {

    constructor(props) {
        super(props);
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

        this.state = {
            userInfo: userInfo
        };
        this.signOut = this.signOut.bind(this);
    }

    signOut() {
        console.log(' ------ signOut -----');
        this.props.signOut();
        this.props.history.push('/signin');
    }

    render() {
        // const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        const { userInfo } = this.state;

        return (
            <div className="ladiui-topbar fixed">
                <div className="topbar-left">
                    <div className="ladiui-nav-toggle">
                        <i className="ladi-icon icon-menu ladiui-menu-toggle" data-id="main-navigation"></i>
                    </div>
                    <div className="ladiui-logo">
                        <img src={appConst.LOGO}
                            alt="LadiPage" />
                    </div>

                </div>
                <div className="topbar-right topbar-profile">
                    {/* <div className="app-packages">
                        <span>Bạn đang sử dụng tài khoản miễn phí </span>
                        <a href="#" className="upgrade ladiui button warning sm">Nâng cấp ngay !</a>
                    </div> */}
                    <ul className="ladiui-navbar -horizontal pull-right">
                        <li className="ladiui-dropdown app-tools"><a href="#"><i className="ladi-icon icon-c-question"></i></a>
                            <ul>
                                <li><a target='_blank' href={appConst.SUPPORT_URL.SUPPORT}><i className="ladi-icon icon-b-chat"></i> <span>Hỗ trợ</span></a></li>
                                <li><a target='_blank' href={appConst.SUPPORT_URL.DOCUMENT}><i className="ladi-icon icon-blog"></i> <span>Blog</span></a></li>
                                <li><a target='_blank' href={appConst.SUPPORT_URL.NEWS}><i className="ladi-icon icon-c-warning"></i> <span>What's New</span></a></li>
                                <li><a target='_blank' href={appConst.SUPPORT_URL.SHORT_CUT}><i className="ladi-icon icon-hot-key"></i> <span>Shortcut</span></a></li>
                                <li><a target='_blank' href={appConst.SUPPORT_URL.FANPAGE_FACEBOOK}><i className="ladi-icon icon-logo-facebook"></i> <span>Community</span></a></li>
                            </ul>
                        </li>

                        {/* <li><a href="#"><i className="ladi-icon icon-settings"></i></a></li> */}
                        <li className="ladiui-dropdown">
                            <a ><span className="ladiui avatar">{(userInfo && userInfo.fullname) ? baseHelper.getFirstLetter(userInfo.fullname) : 'UID'}</span></a>
                            <ul>
                                <li>
                                    <div className="ladipage-account">
                                        <div className="ladipage-account-avatar">
                                            {
                                                userInfo.avatar?

                                            <img src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR} onError={this.onError} />
                                            :
                                            <span 
                                                className="ladiui-topbar-noavatar"
                                            >
                                            {baseHelper.getFirstLetter(userInfo.fullname)}
                                            </span>
                                            }
                                            {/* <img src={avatar ? avatar : 'https://lh3.googleusercontent.com/a-/AAuE7mBVeljufiwq3tbGnwyb4zD5knIDTBWdHDOoTX7R=s96'} /> */}
                                        </div>
                                        <div className="ladipage-account-info">
                                            <span className="ladipage-account-name">
                                                {(userInfo && userInfo.fullname) ? userInfo.fullname : 'UID'}
                                            </span>
                                            <span className="ladipage-account-email">
                                                {userInfo.email}
                                            </span>
                                            <span className="ladipage-account-button">
                                                <NavLink to="/profile" className="ladiui-link">Quản lý Tài khoản </NavLink>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li className="ladipage-account-signout"><a onClick={this.signOut}><i className="ladi-icon icon-logout"></i> Đăng xuất</a></li>
                            </ul>

                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(authActions.signOut()),
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Topbar));