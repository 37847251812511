import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.COMMISSION_LIST,
    meta: {
        endpoint: endpoint.COMMISSION_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listLadiFlow = (data) => ({
    type: types.COMMISSION_LADIFLOW_LIST,
    meta: {
        endpoint: endpoint.COMMISSION_LADIFLOW_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const detail = (data) => ({
    type: types.COMMISSION_DETAIL,
    meta: {
        endpoint: endpoint.COMMISSION_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const myCommission = () => ({
    type: types.MY_COMMISSION,
    meta: {
        endpoint: endpoint.MY_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const commissionDetail = (data) => ({
    type: types.COMMISSION_DETAIL,
    meta: {
        endpoint: endpoint.COMMISSION_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const exportExcel = (data) => ({
    type: types.EXPORT_COMMISSION,
    meta: {
        endpoint: endpoint.EXPORT_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const exportExcelLadiFlow = (data) => ({
    type: types.EXPORT_COMMISSION_LADIFLOW,
    meta: {
        endpoint: endpoint.EXPORT_COMMISSION_LADIFLOW,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    list,
    listLadiFlow,
    myCommission,
    detail,
    commissionDetail,
    exportExcel,
    exportExcelLadiFlow
}