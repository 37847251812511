import React from 'react';
import PropTypes from 'prop-types';

export default class ConfirmModal extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        onCancel: PropTypes.func,
        cancelText: PropTypes.string,
        onOk: PropTypes.func,
        okText: PropTypes.string,
        isLoading: PropTypes.bool,
    }

    static defaultProps = {
        id: "modal-confirm-action",
    }

    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    onOk = () => {
        if (this.props.onOk) {
            this.props.onOk();
        }
    }

    render() {
        const { id, title, content, cancelText, okText, isLoading } = this.props;

        return (
            <div id={id} className="ladiui-modal fade" tabIndex="-1" data-dismiss={id} style={{ zIndex: 22 }}>
                <div className="ladiui-modal-content confirm">
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal"
                        data-dismiss={id}><i className="ladi-icon icon-e-remove"
                            data-dismiss={id}></i></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {content}
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button danger close-modal" onClick={this.onCancel} data-dismiss={id}>{cancelText}</button>
                        <button className={`ladiui button primary ${isLoading ? 'loader' : ''}`} onClick={this.onOk}>{okText}</button>
                    </div>
                </div>
            </div>
        )
    }

}