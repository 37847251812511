import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';

import * as types from '../../redux/futures/tools/types';
import ConfirmModal from '../../components/ConfirmModal';
import appConst from '../../config/const';

class BlackListDomain extends React.Component {
 
    constructor(props) {
        super(props);

        this.state = {
            domain:'',
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: '',
            message: "",
            data: {},
            actionObj: { 

                domain: "",

                action: "", 
            },
            selectedAction: appConst.ACTION_DOMAIN_BLACKLIST.addBlackList,

        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.initialState = _.cloneDeep(this.state);
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            
            if (nextProps.action == types.CHECK_DOMAIN) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });  
                if (nextProps.status) {
                    this.setState({
                        message: nextProps.result
                    })
                } else {
                }
            } 

            if (nextProps.action == types.BLACK_LIST_DOMAIN) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });  
                if (nextProps.status) {
                    window.LadiUI.toastr('success', '', nextProps.message);
                    this.setState({
                        message: nextProps.result,
                        data: nextProps.data.data? nextProps.data.data:false
                    })
                    setTimeout(() => {
                        window.LadiUI.closeModalById('confirm-blacklist-domain');
                        const { actionObj } = this.state;
                        const jsonData = {
                            "domain": actionObj.domain,
                        }
                        this.props.checkDomain(jsonData);
                    }, 500);
                    // setTimeout(function () {
                    //     window.LadiUI.closeModal(_seft.ref.current);
                    // },1000);
                    
                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            } 
        }
    } 
    submit = (selectedID) => {
      
        this.selectedID = selectedID;
        
        window.LadiUI.showModalById('confirm-blacklist-domain');
         
    }
 
    onChangeInput = (event) => { 
         
        this.setState({
            actionObj: {
                ...this.state.actionObj,
                [event.target.name]: event.target.value
            }
        });
    } 

    onChangeAction = (option) => {
        // console.log(option);
        this.setState({
            selectedAction: option ,
        })
    }
 

    resetForm = (event) => {
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
        
    }
    blackListDomain = () => {
        const { actionObj, selectedAction } = this.state;
        const jsonData = {
            "domain": actionObj.domain,
            "is_blacklist": selectedAction.value
        } 
        this.props.blackListDomain(jsonData);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }
    

    render() {
        const { actionObj, message, data, selectedAction } = this.state;
         
        const title = 'Đưa tên miền vào black list';
        const isLoadingDelete = this.props.loading && (this.props.waitting == types.DELETE_DOMAIN);
        return (
            <div ref={this.ref} id="modal-blacklist-domain" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-blacklist-domain">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-blacklist-domain"><i className="ladi-icon icon-e-remove" data-dismiss="modal-blacklist-domain" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                    
                            <div className="ladiui-tab-content" style={{height:"160px"}}>
                            
                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Domain</label> 
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="domain"
                                                validationName="Domain"
                                                placeholder="Domain"
                                                value={actionObj.domain}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true}}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Hành động</label>
                                            <div className="ladiui-select-options">
                                                
                                                    <span className="ladiui-select-init">{
                                                        selectedAction.name
                                                        }</span>
                                                    <input type="hidden" name="select" defaultValue />
                                                    <ul>
                                                        {
                                                            _.map(appConst.ACTION_DOMAIN_BLACKLIST, option =>
                                                                <li key={option.value} className="select-item" onClick={() => this.onChangeAction(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                            )
                                                        }
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div> 
                                
                            </div>
                         
                    
                        {
                            message && 
                            <label className="ladiui-label">{message}</label> 
                        }
                        {
                            data && data.length >0 && 
                            
                            _.map(data, (item,index) =>
                                <label className="ladiui-label" key={index}>
                                {"URL = " +item.url +" - Trạng thái: " + item.status}
                                </label> 
                            )
                            
                        }

                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-blacklist-domain">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-blacklist-domain" />Đóng
                        </button>
                        <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.submit}
                        disabled={this.state.loading}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i>  
                                    Áp dụng
                         </button>
                         
                    </div>
                </div>
                <ConfirmModal
                    id="confirm-blacklist-domain"
                    title="Đưa tên miền vào blacklist"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.blackListDomain()}
                    isLoading={isLoadingDelete}
                />

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        blackListDomain: (data) => dispatch(toolActions.blackListDomain(data)) ,
        checkDomain: (data) => dispatch(toolActions.checkDomain(data)),

    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(BlackListDomain);