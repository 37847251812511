import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';


import Store from './pages/stores/Store';
import Order from './pages/orders/Order';
import Affilate from './pages/affilates/Affilate';
import Partner from './pages/affilates/Partner';
import Invoice from './pages/invoices/Invoice';
import Commission from './pages/commission/Commission';
import MyCommission from './pages/commission/MyCommission';
import TimeOnsite from './pages/timeonsite/TimeOnSite';
import Referral from './pages/referrals/Referral';
import Point from './pages/points/Point';
import Payment from './pages/payments/Payment';
import Tool from './pages/tools/Tools';

import SidebarItem from './components/SidebarItem';


import Dashboard from './pages/dashboard/Dashboard';
import _ from 'lodash';
import Profile from './pages/profile/Profile';
import Package from './pages/packages/Package';
import User from './pages/users/Users';
import UserDetail from './pages/users/UserDetails';
import Admin from './pages/admins/Admins';
import History from './pages/historylog/HistoryLog';
import Credit from './pages/credits/Credit';
import ActivityLog from './pages/stores/ActivityLog';
import CommissionFlow from './pages/commission/CommissionFlow';

const routeMap = {
    '/': {
        path: '/', exact: true, name: 'Profile', component: Profile
    },
    '/dashboard': {
        path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard
    },
    '/user': {
        path: '/user', exact: true, name: 'Users', component: User
    },
    '/user-detail': {
        path: '/user-detail', exact: true, name: 'UserDetails', component: UserDetail
    },
    '/timeonsite': {
        path: '/timeonsite', exact: true, name: 'TimeOnsite', component: TimeOnsite
    },
    '/admin': {
        path: '/admin', exact: true, name: 'Admins', component: Admin
    },
    '/history': {
        path: '/history', exact: true, name: 'History', component: History
    },
    '/store': {
        path: '/store', exact: true, name: 'Store', component: Store
    },
    '/activity-log': {
        path: '/activity-log', exact: true, name: 'Activity Log', component: ActivityLog
    },
    '/order': {
        path: '/order', exact: true, name: 'Orders', component: Order
    },
    '/payment': {
        path: '/payment', exact: true, name: 'Payments', component: Payment
    },
    '/referral': {
        path: '/referral', exact: true, name: 'Referrals', component: Referral
    },
    '/affilate': {
        path: '/affilate', exact: true, name: 'Affiliates', component: Affilate
    },
    '/partner': {
        path: '/partner', exact: true, name: 'Partner', component: Partner
    },
    '/invoice': {
        path: '/invoice', exact: true, name: 'Invoices', component: Invoice
    },
    '/commission': {
        path: '/commission', exact: true, name: 'Commission', component: Commission
    },
    '/commission-flow': {
        path: '/commission-flow', exact: true, name: 'Commission LadiFlow', component: CommissionFlow
    },
    '/profile': {
        path: '/profile', exact: true, name: 'Profile', component: Profile
    },
    '/package': {
        path: '/package', exact: true, name: 'Packages', component: Package
    },
    '/point': {
        path: '/point', exact: true, name: 'Points', component: Point
    },
    '/credit': {
        path: '/credit', exact: true, name: 'Credits', component: Credit
    },
    '/tool': {
        path: '/tool', exact: true, name: 'Tools', component: Tool
    },
}


const menuItems = {
    // '/stores': 
    //     <li key='stores' className="ladiui menu-item">
    //         <NavLink to="/stores" className="ladiui-accordion"> <i className="ladi-icon icon-users-mm-2" /> Stores </NavLink>
    //     </li>,
    '/profile':
        <SidebarItem
            keyStr={['profile']}
            key='profile'
            classLi="ladiui menu-item"
            to="/profile"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-users"
            title="Tài khoản"
        />,
    '/dashboard':
        <SidebarItem
            keyStr={['dashboard']}
            key='dashboard'
            classLi="ladiui menu-item"
            to="/dashboard"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-users"
            title="Tổng quan"
        />,
    '/user':
        <SidebarItem
            keyStr={["user", "store", "timeonsite", "user-detail", "activity-log"]}
            key='users'
            classLi="ladiui menu-item"
            // to="/user"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-users"
            title="Khách hàng"
            subMenu='user'
            subItem={['user', 'store', 'timeonsite', 'user-detail', 'activity-log']}
        />,
    '/tool':
        <SidebarItem
            keyStr={['tool']}
            key='toolRoot'
            classLi="ladiui menu-item"
            to="/tool"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-bill"
            title="Công cụ"

        />
    ,
    '/admin':
        <SidebarItem
            keyStr={['admin', 'history']}
            key='admins'
            classLi="ladiui menu-item"
            // to="/admin"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-users"
            title="Admins"
            subMenu='admin'
            subItem={['admin', 'history']}
        />,
    '/order':
        <SidebarItem
            keyStr={['order']}
            key='orders'
            classLi="ladiui menu-item"
            to="/order"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-home"
            title="Đơn hàng"
        />,

    '/payment':
        <SidebarItem
            keyStr={['payment']}
            key='payments'
            classLi="ladiui menu-item"
            to="/payment"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-bill"
            title="Thanh Toán"
        />,
    '/referral':
        <SidebarItem
            keyStr={['referral']}
            key='referrals'
            classLi="ladiui menu-item"
            to="/referral"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-referral"
            title="Giới thiệu"
        />,
    '/affilate':
        <SidebarItem
            keyStr={['affilate', 'commission', 'invoice', 'partner', 'commission_flow']}
            key='affilatesRoot'
            classLi="ladiui menu-item"
            // to="/"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-affilate"
            title="Affiliates"
            subMenu='affilate'
            subItem={['affilate', 'commission', 'invoice', 'partner', 'commission_flow']}
        />
    ,
    '/credit':
        <SidebarItem
            keyStr={['credit']}
            key='creditRoot'
            classLi="ladiui menu-item"
            to="/credit"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-bill"
            title="Credit history"

        />
    ,
    '/point':
        <SidebarItem
            keyStr={['point']}
            key='pointRoot'
            classLi="ladiui menu-item"
            to="/point"
            classNav="ladiui-accordion"
            classIcon="ladi-icon icon-bill"
            title="Điểm thưởng"

        />
    ,


};

class Routes {
    getRoutesByScopes(scopes = {}) {
        if (scopes.admin) {
            return routeMap;
        }

        const routes = [];
        routes.push(routeMap['/']);

        _.map(scopes, (actions, scope) => {
            // if (actions.length == 1 && actions[0] == '*') {
            if (actions) {
                routes.push(routeMap[`/${scope}`]);
            }
        });

        return routes;
    }

    getItemByScopes(scopes = {}) {
        if (scopes.admin) {
            return menuItems;
        }

        const items = [];


        _.map(scopes, (actions, scope) => {
            // if (actions.length == 1 && actions[0] == '*') {
            if (actions) {
                items.push(menuItems[`/${scope}`]);
            }
        });

        return items;
    }
}

export default new Routes();