import React from 'react';
import * as types from '../../../redux/futures/store/types';
import storeActions from '../../../redux/futures/store/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import appConst from '../../../config/const';



class ActiveForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: {
                email: "",
                fullname: "",
                _id: "",
                phone: "",

            },
            selectedPackage: {
                package_name: ''
            },
            appCode: 'LS',
            store_name: '',
            credit_app: '0',
            loading: false,
            loaderPackages: true,
            exp_date: '',
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        }
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();

        this.ref = React.createRef();

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.appCode !== nextProps.appCode) {
            this.setState({
                appCode: nextProps.appCode,
            });
        }
        if (this.props.currentUser !== nextProps.currentUser) {
            if (!nextProps.currentUser) {
                this.resetForm();
            } else {
                this.setState({
                    currentUser: nextProps.currentUser,
                    store_name: nextProps.currentUser.fullname || '',
                    appCode: nextProps.appCode,
                    loaderPackages: true,
                    packages: []
                })

            }
        }

        if (nextProps.action === types.LIST_PACKAGE) {

            this.setState({ loaderPackages: false });
            if (nextProps.status && nextProps.packages.length > 0) {
                this.setState({
                    packages: nextProps.packages,
                    selectedPackage: nextProps.packages[0],
                    loaderPackages: false
                });
                // console.log({packages: nextProps.packages,
                //     selectedPackage: nextProps.packages[0]});
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

            // this.list();
        }
        if (nextProps.action == types.ACTIVE_STORE) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                    const { appCode, currentUser } = _seft.state;
                    const data = {
                        store_name: '',
                        app_code: appCode,
                        limit: 20,
                        offset: 0,
                        email: currentUser.email

                    };
                    if (currentUser.email != '' && currentUser.email != undefined) {
                        _seft.props.listStore(data);
                    }
                }, 1000);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

    }

    pickPackages(name) {
        // console.log('pickPackages(',name);
        const { packages } = this.state;

        for (let i = 0; i < packages.length; i++) {
            if (packages[i].name === name) {

                this.setState({
                    selectedPackage: packages[i]
                });
                break;
            }
        }

    }

    resetForm = (event) => {
        // console.log('===resetForm===');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
    }

    onChangeInput = (event) => {
        this.setState({
            store_name: event.target.value
        });
    }
    onChangeCredit = (event) => {
        this.setState({
            credit_app: event.target.value
        });
    }


    submit = () => {
        // Validate  
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { currentUser, selectedPackage, store_name, appCode, credit_app } = this.state;


        const data = {
            pkg_name: selectedPackage.package_name,
            ladi_uid: currentUser._id,
            phone: currentUser.phone,
            email: currentUser.email,
            store_name: store_name == '' ? currentUser.fullname : store_name,
            app_code: appCode,
            credit_app
        };
        this.props.activeStore(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }


    onChangePackage = (event) => {

        this.pickPackages(event.target.value);
    }

    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value

        });
    }

    handleClickOutside = () => {
        this.props.onCancel();
    }

    render() {
        this.inputsRef = new Set();
        const { packages, selectedPackage, appCode, loaderPackages, store_name, credit_app } = this.state;
        // console.log('selectedPackage :', selectedPackage, '-= \n\n packages:', packages);
        const title = "Tạo store cho " + appConst.APP[appCode];
        return (
            <div ref={this.ref} id="modal-active-store" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-active-store" style={{ zIndex: 21 }}>
                <div className={`ladiui-modal-content ${loaderPackages ? 'loader' : ''}`} >
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-active-store"><i className="ladi-icon icon-e-remove" data-dismiss="modal-active-store" onClick={this.props.onCancel} /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className={"ladiui-modal-body"}>
                        <form>

                            <div>

                                <div className="ladiui form-group condition-form">

                                    <h3>Gói dịch vụ: {selectedPackage.package_name}</h3>
                                </div>
                                {/* <div className="ladiui-row">
                                    {
                                        _.map(packages, packageObj => {
                                            return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + packageObj.name}>

                                                <input type="radio"

                                                    id={"package-active-store" + packageObj.package_name}
                                                    name="selectedPackage"
                                                    value={packageObj.package_name}
                                                    onChange={this.onChangePackage}
                                                    // defaultChecked
                                                    checked={selectedPackage.package_name == packageObj.package_name}
                                                />

                                                <label htmlFor={"package-active-store" + packageObj.package_name}>{packageObj.name}</label>
                                            </div>
                                        })
                                    }
                                </div> */}
                                <div className="ladiui form-group">
                                    <h3>Tên Store</h3>
                                </div>

                                <div className="ladiui form-group">
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="store_name"
                                        validationName="Tên Store"
                                        placeholder="Tên Store"
                                        value={store_name}
                                        onChange={this.onChangeInput}
                                    />

                                </div>
                                {appCode == 'LS' &&
                                    <React.Fragment>
                                        <div className="ladiui form-group">
                                            <h3>Credit</h3>
                                        </div>



                                        <div className="ladiui form-group">
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="credit_app"
                                                validationName="Credit"
                                                placeholder="Credit"
                                                value={credit_app}
                                                onChange={this.onChangeCredit}
                                                type='number'
                                            />
                                        </div>
                                    </React.Fragment>
                                }

                            </div>
                        </form>

                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-active-store"><i className="ladi-icon icon-e-remove" data-dismiss="modal-active-store" onClick={this.props.onCancel} />Close</button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i> Áp dụng </button>

                        {/* {this.props.loading && <Spinner />} */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listStore: (data) => dispatch(storeActions.list(data)),
        activeStore: (store) => dispatch(storeActions.activeStore(store)),

    }
}

const mapStateToProps = (state) => ({ ...state.store });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ActiveForm);