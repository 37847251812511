import axios from 'axios';
import * as commonTypes from '../futures/common/types';
import {
    SIGNIN, SIGNUP, SIGNOUT, UPDATE_ADMIN_INFO, CHANGE_PASSWORD, RESET_PASSWORD, FORGOTTEN_PASSWORD, OAUTH2_FACEBOOK, OAUTH2_GOOGLE, GET_ADMIN_INFO
} from '../futures/auth/types';
import appConst, { status } from '../../config/const';
import _ from 'lodash';
import { push } from 'connected-react-router';

import baseHelper from '../../helpers/BaseHelper';
import FileDownload from 'js-file-download';
import { saveAs } from 'file-saver';
export const REQUEST_METHOD = {
    GET: 'get',
    POST: 'post',
}

const serviceMiddleware = store => next => action => {

    if (action.meta) {
        console.log('===== META waitting: ', action.meta);
        next({ type: commonTypes.ASYNC_START, waitting: action.type });
        const { endpoint, body, method, hasAuth, form } = action.meta;

        const req = {
            method: method,
            url: endpoint,
            headers: requestHeaders(hasAuth, form),
            data: body ? body : form
        };

        axios(req).then(function (response) {

            if (action.type != commonTypes.ASYNC_END && action.type != commonTypes.ASYNC_START) {
                // console.log('Action = ', action.type, ' - Response = ', response);
            }

            const { data } = response;
            if (_.includes([status.OK, status.CREATED], data.code)) {
                action.status = true;
                action.payload = data.data;
                action.message = data.message;
            } else if (data.code === status.UNAUTHORIZED) {
                baseHelper.removeCookie(appConst.ADMIN_INFO);
                // localStorage.removeItem(appConst.ADMIN_INFO);
                store.dispatch(push('/signin'));
                return;
            } else {
                action.status = false;
                action.message = data.message;
            }

            if (action.type.indexOf('EXPORT_') >= 0) {
                const fileBuffer = data.data.buffer;
                const blob = new Blob([new Uint8Array(fileBuffer.data)]);
                saveAs(blob, data.data.name);
            }

            next(action);
            next({ type: commonTypes.ASYNC_END, waitting: null });
        }).catch(function (error) {
            action.status = false;
            action.message = error.message;

            next(action);
            next({ type: commonTypes.ASYNC_END, waitting: null });
        });

        return;
    }

    next(action);
}

const localStorageMiddleware = store => next => action => {
    switch (action.type) {
        case SIGNIN:
        case SIGNUP:
        case GET_ADMIN_INFO:
        case UPDATE_ADMIN_INFO:
        case OAUTH2_FACEBOOK:
        case OAUTH2_GOOGLE:
            if (action.status) {
                baseHelper.setCookie(appConst.ADMIN_INFO, JSON.stringify(action.payload), appConst.COOKIE_EXPRIRE);
                // localStorage.setItem(appConst.ADMIN_INFO, JSON.stringify(action.payload));
            }
            // if (window.OneSignal){
            //     window.OneSignal.sendTags({
            //         userID: action.payload.ladi_uid
            //     }).then(function(tagsSent) {

            //     });
            // }
            break;

        case SIGNOUT:
            baseHelper.removeCookie(appConst.ADMIN_INFO);
            // localStorage.removeItem(appConst.ADMIN_INFO);
            break;

        case CHANGE_PASSWORD:
            if (action.status) {
                const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
                userInfo.token = action.payload.token;
                baseHelper.setCookie(appConst.ADMIN_INFO, JSON.stringify(userInfo), appConst.COOKIE_EXPRIRE);
            }
            break;

        case RESET_PASSWORD:
            if (action.status) {
                store.dispatch(push('/signin'));
            }
            break;

    }


    next(action);
};

function requestHeaders(hasAuth = true, form) {
    const headers = (form)
        ? {
            'Content-Type': 'multipart/form-data',
        } : {
            'Content-Type': 'application/json',
        }
    if (!hasAuth) {
        return headers;
    }

    // const userInfo = JSON.parse(localStorage.getItem(appConst.ADMIN_INFO));
    const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
    headers.authorization = userInfo.token;

    return headers;
}


export { serviceMiddleware, localStorageMiddleware }