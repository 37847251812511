import React from 'react';
import PropTypes from 'prop-types';
import affilateActions from '../../../redux/futures/affilate/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import Input from '../../../components/Input';


import * as types from '../../../redux/futures/affilate/types';




class PaymentInfo extends React.Component {

    static propTypes = {
        affilateInfo: PropTypes.object
    }

    constructor(props) {
        super(props);
        
        this.state = { 
            affilateInfo: {
                code:"", 
                payment_info: "", // thông tin thanh toán
            }
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.affilateInfo && this.state.affilateInfo !== nextProps.affilateInfo) {
            this.setState({
                affilateInfo: nextProps.affilateInfo
            });
        }
        if (nextProps.action == types.UPDATE_PAYMENT_INFO) {

            if (nextProps.status) {
                this.setState({
                    affilateInfo: nextProps.affilateInfo
                });
                // window.LadiUI.closeModal(this.ref.current);
                window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        _.map(this.inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { affilateInfo } = this.state;
        if (affilateInfo.payment_info == '') {
            window.LadiUI.toastr('error', '', 'Payment infomation cannot null');
            return;
        }
        const jsonData = {
            "code": affilateInfo.code,
            "payment_info": affilateInfo.payment_info 
        }

       
        
        this.props.update(jsonData);

    }

    onChangeInput = (event) => {

        this.setState({
            affilateInfo: {
                ...this.state.affilateInfo,
                [event.target.name]: event.target.value
            }
        });
    }
 

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        const affilateInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

        this.state = {
            affilateInfo: affilateInfo ? affilateInfo : {}
        }

        window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
            e.classList.remove('error');
        });
    }

    render() {
        const { affilateInfo } = this.state;

        const title = 'Edit Payment Information';

        return (
            <div ref={this.ref} id="modal-paymentaffilate" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-paymentaffilate"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content" style={{width:'500px'}}>
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-paymentaffilate"><i className="ladi-icon icon-e-remove" data-dismiss="modal-paymentaffilate" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Code</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="code"
                                                validationName="Code"
                                                placeholder="Code"
                                                value={affilateInfo.code}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                            /> 
                                        </div>

                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Payment Infomation</label>
                                            <textarea name="paymentinfo" className="ladiui textarea ladi-payment-info" />
                                        </div>
                                     
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-paymentaffilate">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-paymentaffilate" />Close
                        </button>
                        <button className="ladiui button primary icon-floppy-disk" onClick={this.submit}>
                            Save
                        </button>
                        {(this.props.loading == types.UPDATE_PAYMENT_INFO) && <Spinner />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (data) => dispatch(affilateActions.updatePaymentInfo(data))
    }
}

const mapStateToProps = (state) => ({ ...state.affilate });

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);