import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export default class BreadCrumb extends React.Component {
    static propTypes = {
        parent: PropTypes.string,
        parentUrl: PropTypes.string,
        firstChild: PropTypes.string,
    }

    render() {
        const { parent, parentUrl, firstChild } = this.props;
        return (
            <ul className="ladiui breadcrumbs">
                <li><NavLink to="/profile"><i className="ladi-icon icon-home" /></NavLink></li>
                <li><NavLink to={parentUrl}>{parent}</NavLink></li>
                <li className="active">{firstChild}</li>
            </ul>
        );
    }
}
