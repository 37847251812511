import React from 'react';
import * as types from '../../redux/futures/store/types';
import storeActions from '../../redux/futures/store/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';


class ActiveLadiChat extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            store: {
                store_id: '',
                name: '',
                ladi_uid: '',
                pkg_name: '',
                pkg_exp: '',
                pkg_buy_exp: '',
                pkg_buy_price: '0',
                created_at: '',
            },
            userinfo: {
                point: '',
                email: '',
                fullname: '',
                lastname: '',
                phone: ''
            },
            note: '',
            affilateCode: '',
            appCode: 'LP',
            packages: [],
            selectedPackage: '',
            month: "3",
            addition_month: "0",
            discount: "0",
            point: "0",
            totalPrice: 0,
            backMoney: 0,
            listStore: [],
            listPkg: [],
            listRegister: [],
            method: appConst.PAYMENT_STATUS_OPTIONS.PAID.value,
            returned: appConst.PAYMENT_RETURN_OPTIONS.RETURNED.value,
            loading: false,
            loaderPackages: true,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        }
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();

        this.ref = React.createRef();

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.appCode !== nextProps.appCode) {
            this.setState({ appCode: nextProps.appCode })
        }

        if (this.props.currentStore !== nextProps.currentStore) {
            if (!nextProps.currentStore) {
                this.resetForm();
            } else {

                this.setState({
                    store: _.pick(nextProps.currentStore, _.keys(this.state.store)),
                    appCode: nextProps.appCode,
                    loaderPackages: true,
                    packages: []
                })
            }
        }


        if (nextProps.action === types.LIST_PACKAGE) {

            this.setState({ loaderPackages: false });
            if (nextProps.status && nextProps.packages.length > 0) {
                const { store } = this.state;
                const expDate = new Date(store.pkg_exp);
                const isExp = baseHelper.diffDateDay(expDate, new Date()) > 0;

                let selectedPackage = null;
                let list = [];
                // window.LadiUI.toastr('success', '', nextProps.message);
                for (let i = 0; i < nextProps.packages.length; i++) {
                    // if (nextProps.packages[i].price == 0) {
                    list.push(nextProps.packages[i]);
                    // }
                }
                // if (price) {
                for (let i = 0; i < list.length; i++) {
                    if (store.pkg_name == list[i].name) {
                        selectedPackage = list[i];
                        break;
                    }
                }
                // } else {
                // list = nextProps.packages;
                // }

                list = list.sort(function (a, b) {
                    if (a.price > b.price) return 1;
                    if (a.price < b.price) return -1;
                    return 0;
                });
                if (!selectedPackage) {
                    selectedPackage = list[0];
                }
                console.log(list, selectedPackage);
                this.setState({
                    packages: list,
                    selectedPackage: selectedPackage,
                    loaderPackages: false
                }, () => {
                    this.calculateMoney();
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

            // this.list();
        }
        if (nextProps.action == types.UPGRADE_STORE_LIST) {

            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                const { listStore, listPkg, listRegister } = nextProps;
                // setTimeout(function () {
                // window.LadiUI.closeModal(_seft.ref.current);
                // }, 1000);
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal,
                    listStore, listPkg, listRegister
                });

            } else {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                window.LadiUI.toastr('error', '', nextProps.message);
            }

        }

    }

    pickPackages(name) {
        const { packages } = this.state;

        for (let i = 0; i < packages.length; i++) {
            if (packages[i].name === name) {

                this.setState({
                    selectedPackage: packages[i]
                }, () => {
                    this.calculateMoney();
                });
                break;
            }
        }

    }

    resetForm = (event) => {
        // console.log('===resetForm===');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
    }

    calculateMoney = () => {

        const { month, point, discount, selectedPackage, store, returned } = this.state;

        let backMoney = 0;

        if (store.pkg_buy_exp && store.pkg_buy_exp != '') {
            const diffDate = baseHelper.diffDateDay(new Date(), new Date(store.pkg_buy_exp));

            backMoney = parseInt(store.pkg_buy_price) * diffDate;
            if (backMoney < 0) backMoney = 0;
        }
        if (selectedPackage.name == store.pkg_name || (returned == 'NO_RETURN')) {
            backMoney = 0;
        }
        let totalPrice = selectedPackage.price * month - point * 1000 - discount - backMoney;
        if (totalPrice < 0) {
            totalPrice = 0
        }

        totalPrice = Math.round(totalPrice / 1000) * 1000;

        this.setState({
            totalPrice,
            backMoney
        });
    }

    onChangePoint = (event) => {
        const { min } = event.target;

        if (min) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            point: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeDiscount = (event) => {
        const { min } = event.target;

        if (min) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            discount: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeAdditionMonth = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            addition_month: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeInput = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) > max || parseInt(event.target.value) < min) {
                return false;
            }

        }

        this.setState({
            month: event.target.value

        }, () => {
            this.calculateMoney();
        });
    }

    onChangeAffilateCode = (event) => {

        this.setState({
            affilateCode: event.target.value
        });
    }
    submit = () => {
        // Validate  
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        let { store, month, selectedPackage, method, affilateCode, addition_month, discount, point, note, returned, appCode, listEmail } = this.state;

        listEmail = listEmail.split(';');
        console.log(listEmail)
        _.map(listEmail, email => {
            email = email.trim();
        });
        const data = {
            list_email: listEmail,
            month,
            pkg_name: selectedPackage.pkg_name,
        };

        // console.log(data); return;
        this.props.upgradeList(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangeMethod = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    onChangeReturned = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.calculateMoney();
        });
    }

    onChangePackage = (event) => {

        this.pickPackages(event.target.value);
        // this.setState({
        //     selectedPackage: {
        //         package_name: event.target.value
        //     }
        // }, () => {
        //     this.calculateMoney();
        // });
    }

    onChangeTextArea = (event) => {

        this.setState({
            listEmail: event.target.value
        });
    }

    handleClickOutside = () => {
        this.props.onCancel();
    }
    changeNote = () => {

    }

    render() {
        this.inputsRef = new Set();
        const { store, packages, selectedPackage, month, loaderPackages, userinfo, addition_month, discount, listEmail, appCode,
            listStore, listPkg, listRegister } = this.state;
        let title = "Nâng cấp hoặc gia hạn store: " + userinfo.email;
        if (userinfo.is_partner_aff) {
            title += " - Mã Partner : " + userinfo.affilate_code;
        }
        let appName = appConst.STORE_CONFIG.APP_CODE[appCode].name;
        return (
            <div ref={this.ref} id="modal-active-education" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-active-education" style={{ zIndex: 21 }} >
                <div className={`ladiui-modal-content ${loaderPackages ? 'loader' : ''}`} >
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-active-education"><i className="ladi-icon icon-e-remove" data-dismiss="modal-active-education" onClick={this.props.onCancel} /></button>
                        <h3>{title}<span className="app-name">{appName}</span></h3>
                    </div>
                    <div className={"ladiui-modal-body"}>

                        <div className="ladiui-row">
                            <h3>Danh sách email phân biệt bằng dấu chấm phẩy(;): </h3>
                        </div>
                        <div className="ladiui-form-group">
                            <textarea name="note" className="ladiui textarea ladi-payment-info"
                                value={listEmail} onChange={this.onChangeTextArea} />
                        </div>
                        <form>

                            <div>
                                <div className="ladiui form-group condition-form">

                                    <h3>Lựa chọn gói dịch vụ</h3>
                                </div>
                                <div className="ladiui-row">
                                    {
                                        _.map(packages, packageObj => {
                                            if (packageObj.name != 'EDUCATION') {
                                                return <div key={"package-" + packageObj.name}></div>
                                            }
                                            return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + packageObj.name}>

                                                <input type="radio"

                                                    id={"package-" + packageObj.package_name}
                                                    name="selectedPackage"
                                                    value={packageObj.package_name}
                                                    onChange={this.onChangePackage}
                                                    // defaultChecked
                                                    checked={selectedPackage.package_name == packageObj.package_name}
                                                />

                                                <label htmlFor={"package-" + packageObj.package_name}>{packageObj.name}</label>
                                            </div>
                                        })
                                    }
                                </div>

                                <div className="ladiui-row" >
                                    <div className="ladiui form-group">
                                        <h3>Số tháng</h3>
                                    </div>

                                    <div className="ladiui form-group">
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="month"
                                            validationName="Month"
                                            placeholder="Số tháng gia hạn"
                                            value={month}
                                            type='number'
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true, min: 1, max: 36 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        {
                            listStore &&
                            <div className="ladiui-form-group">
                                <h3>Danh sách email chưa tạo store </h3>
                                {
                                    _.map(listStore, user => {
                                        return <p key={user.email}> {user.email}</p>
                                    })
                                }
                                <hr></hr>
                            </div>
                        }
                        {
                            listPkg &&
                            <div className="ladiui-form-group">
                                <h3>Danh sách email sai package  </h3>

                                {
                                    _.map(listPkg, user => {
                                        return <p key={user.email}> {user.email + "\t" + user.pkg_name}</p>
                                    })
                                }
                                <hr></hr>
                            </div>
                        }
                        {
                            listRegister &&
                            <div className="ladiui-form-group">
                                <h3>Danh sách email chưa đăng kí tài khoản </h3>

                                <textarea name="note" className="ladiui textarea ladi-payment-info"
                                    onChange={this.changeNote}
                                    value={listRegister} />
                            </div>
                        }
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-active-education"><i className="ladi-icon icon-e-remove" data-dismiss="modal-active-education" onClick={this.props.onCancel} />Close</button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i> Áp dụng </button>

                        {/* {this.props.loading && <Spinner />} */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        upgradeList: (store) => dispatch(storeActions.upgradeList(store)),
        listPackages: (data) => dispatch(storeActions.listPackages(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.store });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ActiveLadiChat);