import React from 'react';
import * as types from '../../../redux/futures/store/types';
import storeActions from '../../../redux/futures/store/actions';
import _, { max } from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';


class ActiveLadiBoost extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stores: {
                ladi_page: false,
                ladi_flow: false,
                ladi_sale: false,
            },
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                status: "",

                point: "",

                credit: "",

                created_at: "",

                _id: "",

                web_id: "",

                code: "",

                is_verify_email: false,
                is_verify_domain: false

            },
            inputTag: '',
            listTags: [],
            type: props.typeUpgrade,
            appType: props.appType,
            note: '',
            affilateCode: '',
            appCode: 'LP',
            packages: [],
            selectedPackage: '',
            month: "3",
            addition_month: "0",
            discount: "0",
            max_domain: "0",
            max_link: "0",
            max_funnel: "0",
            max_funnelx: "0",
            max_step_action: "0",
            max_traffic: "0",
            point: "0",
            totalPrice: 0,
            backMoney: 0,
            method: appConst.PAYMENT_STATUS_OPTIONS.PAID.value,
            returned: appConst.PAYMENT_RETURN_OPTIONS.RETURNED.value,
            is_test: false,
            loading: false,
            loaderPackages: true,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        }
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();

        this.ref = React.createRef();

    }

    componentWillReceiveProps(nextProps) {

        if (this.props.currentUser !== nextProps.currentUser) {
            if (!nextProps.currentUser) {
                this.resetForm();
            } else {

                this.setState({
                    currentUser: _.pick(nextProps.currentUser, _.keys(this.state.currentUser)),
                    appCode: nextProps.appCode,
                    loaderPackages: true,
                    packages: []
                })
            }
        }

        if (nextProps.action === types.GET_USERINFO) {
            // this.setState({loaderPackages: false});
            // console.log(nextProps.userinfo);
            if (nextProps.status && nextProps.userinfo) {
                this.setState({
                    currentUser: nextProps.userinfo
                });
            } else {
                // window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

        if (nextProps.action == types.INFO_LADIBOOST) {
            if (nextProps.status) {
                this.setState({
                    stores: nextProps.stores
                });
            }
        }

        if (nextProps.action === types.LIST_PACKAGE_LADIBOOST) {

            this.setState({ loaderPackages: false });
            if (nextProps.status && nextProps.packages.length > 0) {
                // let { store } = this.state;
                // const expDate = new Date(store.pkg_exp);

                let selectedPackage = null;
                let list = [];
                // window.LadiUI.toastr('success', '', nextProps.message);
                for (let i = 0; i < nextProps.packages.length; i++) {
                    // if (nextProps.packages[i].price == 0) {
                    if (!nextProps.packages[i].pkg_name && nextProps.packages[i].package_name) {
                        nextProps.packages[i].pkg_name = nextProps.packages[i].package_name;
                    }
                    list.push(nextProps.packages[i]);
                    // }
                }
                // if (price) {
                // for (let i = 0; i < list.length; i++) {
                //     if (store.pkg_name == list[i].name) {
                //         selectedPackage = list[i];
                //         break;
                //     }
                // }
                // } else {
                // list = nextProps.packages;
                // }

                list = list.sort(function (a, b) {
                    if (a.price > b.price) return 1;
                    if (a.price < b.price) return -1;
                    return 0;
                });
                if (!selectedPackage) {
                    selectedPackage = list[0];
                }

                this.setState({
                    packages: list,
                    selectedPackage: selectedPackage,
                    loaderPackages: false
                }, () => {
                    this.calculateMoney();
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

            // this.list();
        }
        if (nextProps.action == types.ACTIVE_LADIBOOST) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal,
                listTags: []
            });
            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                }, 1000);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

    }

    pickPackages(name) {
        let { packages } = this.state;

        for (let i = 0; i < packages.length; i++) {
            if (packages[i].pkg_name === name) {

                this.setState({
                    selectedPackage: packages[i],

                }, () => {
                    this.calculateMoney();
                });
                break;
            }
        }

    }

    resetForm = (event) => {
        // console.log('===resetForm===');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
    }

    calculateMoney = () => {

        const { month, point, discount, selectedPackage } = this.state;

        let backMoney = 0;

        // if (store.pkg_buy_exp && store.pkg_buy_exp != '') {
        //     const diffDate = baseHelper.diffDateDay(new Date(), new Date(store.pkg_buy_exp));

        //     backMoney = store.pkg_buy_price ? parseInt(store.pkg_buy_price) * diffDate : 0;
        //     if (backMoney < 0) backMoney = 0;
        // }
        // if (selectedPackage.name == store.pkg_name || (returned == 'NO_RETURN')) {
        //     backMoney = 0;
        // }
        let totalPrice = selectedPackage.price * month - point * 1000 - discount - backMoney;
        if (totalPrice < 0) {
            totalPrice = 0
        }

        totalPrice = Math.round(totalPrice / 1000) * 1000;

        this.setState({
            totalPrice,
            backMoney
        });
    }

    onChangePoint = (event) => {
        const { min } = event.target;

        if (min) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            point: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeDiscount = (event) => {
        const { min } = event.target;

        if (min) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            discount: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeMaxResource = (event) => {
        const { min } = event.target;

        if (min) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onChangeAdditionMonth = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            addition_month: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeInput = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) > max || parseInt(event.target.value) < min) {
                return false;
            }

        }

        this.setState({
            month: event.target.value

        }, () => {
            this.calculateMoney();
        });
    }

    onChangeAffilateCode = (event) => {

        this.setState({
            affilateCode: event.target.value
        });
    }
    submit = () => {
        // Validate  
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { currentUser, stores, selectedPackage, affilateCode, month, addition_month, discount, note, type, is_test } = this.state;
        console.log('store ', selectedPackage);

        const data = {
            ladi_uid: currentUser.ladi_uid || currentUser._id,
            pkg_name: selectedPackage.pkg_name,
            stores,
            discount,
            month,
            addition_month,
            note,
            type,
            is_test,
        };
        if (affilateCode != '') {
            data.affilate_code = affilateCode;
        }
        // console.log(data); return;
        this.props.upgrade(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangeMethod = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    onChangeReturned = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.calculateMoney();
        });
    }

    onChangePackage = (event) => {

        this.pickPackages(event.target.value);
        // this.setState({
        //     selectedPackage: {
        //         package_name: event.target.value
        //     }
        // }, () => {
        //     this.calculateMoney();
        // });
    }

    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value

        });
    }

    handleClickOutside = () => {
        this.props.onCancel();
    }

    onChangeIsTest = (event) => {
        this.setState({
            is_test: event.target.value,
        });
    }
    onChangeTag = (event) => {
        this.setState({
            inputTag: event.target.value,
        });
    };

    render() {
        this.inputsRef = new Set();
        const { stores, packages, selectedPackage, month, loaderPackages, affilateCode, point, totalPrice, currentUser, addition_month, discount, backMoney, appCode, appType } = this.state;
        let title = "Nâng cấp LadiBoost " + currentUser.email;
        if (currentUser.is_partner_aff) {
            title += " - Mã Partner : " + currentUser.affilate_code;
        }
        const {
            ladi_page: ladipageStores,
            ladi_flow: ladiflowStores,
            ladi_sale: ladisaleStores,
        } = stores;
        console.log('stores = ', stores)
        return (
            <div ref={this.ref} id="modal-active-ladiboost" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-active-ladiboost" style={{ zIndex: 21 }} >
                <div className={`ladiui-modal-content ${loaderPackages ? 'loader' : ''}`} >
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-active-ladiboost"><i className="ladi-icon icon-e-remove" data-dismiss="modal-active-ladiboost" onClick={this.props.onCancel} /></button>
                        <h3>{title}<span className="app-name">LadiBoost</span>{appType && <span className="app-name">{appType}</span>}</h3>

                    </div>
                    <div className={"ladiui-modal-body"}>
                        <div className="ladiui form-group condition-form">
                            {ladipageStores && ladipageStores.length > 0 ?
                                <div> LadiPage: &nbsp;
                                    {`${ladipageStores[0].pkg_name} / Ngày hết hạn  ${ladipageStores[0].pkg_exp ? baseHelper.formatStrToDate(ladipageStores[0].pkg_exp) : '--'} `}
                                </div>
                                :
                                <div>LadiPage: Chưa tạo store</div>
                            }
                        </div>
                        <div className="ladiui form-group condition-form">
                            {ladiflowStores && ladiflowStores.length > 0 ?
                                <div> LadiFlow:&nbsp;
                                    {`${ladiflowStores[0].pkg_name} / Ngày hết hạn  ${ladiflowStores[0].pkg_exp ? baseHelper.formatStrToDate(ladiflowStores[0].pkg_exp) : '--'} `}
                                </div>
                                :
                                <div>LadiFlow: Chưa tạo store</div>
                            }
                        </div>
                        <div className="ladiui form-group condition-form">
                            {ladisaleStores && ladisaleStores.length > 0 ?
                                <div> LadiSale:&nbsp;
                                    {`${ladisaleStores[0].pkg_name} / Ngày hết hạn  ${ladisaleStores[0].pkg_exp ? baseHelper.formatStrToDate(ladisaleStores[0].pkg_exp) : '--'} `}
                                </div>
                                :
                                <div>LadiSale: Chưa tạo store</div>
                            }
                        </div>

                        <form>

                            <div>

                                <div className="ladiui form-group condition-form">

                                    <h3>Lựa chọn gói dịch vụ</h3>
                                </div>
                                <div className="ladiui-row">
                                    {
                                        _.map(packages, (packageObj, indx) => {
                                            return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + packageObj.name + "-" + indx}>

                                                <input type="radio"

                                                    id={"package-" + packageObj.pkg_name}
                                                    name="selectedPackage"
                                                    value={packageObj.pkg_name}
                                                    onChange={this.onChangePackage}
                                                    // defaultChecked
                                                    checked={selectedPackage.pkg_name == packageObj.pkg_name}
                                                />

                                                <label htmlFor={"package-" + packageObj.pkg_name}>{packageObj.pkg_name}</label>
                                            </div>
                                        })
                                    }
                                </div>

                                {/* <div className="ladiui-row" >

                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <h3>Số tháng</h3>
                                        </div>

                                        <div className="ladiui form-group">
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="month"
                                                validationName="Month"
                                                placeholder="Số tháng gia hạn"
                                                value={month}
                                                type='number'
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, min: 1, max: 60 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">

                                        <div className="ladiui form-group">
                                            <h3>Affiliate Code</h3>
                                        </div>

                                        <div className="ladiui form-group">
                                            {
                                                currentUser.affilate_code ?
                                                    <input name="affilateCode" className="ladiui input" disabled="disable" value={currentUser.affilate_code} /> :
                                                    <Input
                                                        ref={(ref) => this.inputsRef.add(ref)}
                                                        name="affilateCode"
                                                        validationName="Affiliate Code"
                                                        placeholder="Mã affiliate"
                                                        value={affilateCode}
                                                        onChange={this.onChangeAffilateCode}
                                                    />
                                            }
                                        </div>
                                    </div>

                                </div> */}
                                {/* <div className="ladiui-row" >

                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <h3>Số tháng cộng thêm</h3>
                                        </div>

                                        <div className="ladiui form-group">
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="addition_month"
                                                validationName="Addition Month"
                                                placeholder="Số tháng cộng thêm"
                                                value={addition_month}
                                                type='number'
                                                onChange={this.onChangeAdditionMonth}
                                                validations={{ isRequired: true, min: 1 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">

                                        <div className="ladiui form-group">
                                            <h3>Giảm giá: {baseHelper.numberWithCommas(discount)}</h3>
                                        </div>

                                        <div className="ladiui form-group">
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="discount"
                                                validationName="Discount value"
                                                placeholder="Giảm giá"
                                                type='number'
                                                value={discount}
                                                onChange={this.onChangeDiscount}
                                            />
                                        </div>
                                    </div>

                                </div> */}


                                <div className="ladiui-row">
                                    <h3>Nội dung chuyển khoản: </h3>
                                </div>
                                <div className="ladiui-form-group">
                                    <textarea name="note" className="ladiui textarea ladi-payment-info"
                                        value={this.state.note} onChange={this.onChangeTextArea} />
                                </div>

                            </div>
                        </form>

                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-active-ladiboost"><i className="ladi-icon icon-e-remove" data-dismiss="modal-active-ladiboost" onClick={this.props.onCancel} />Close</button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i> Áp dụng </button>

                        {/* {this.props.loading && <Spinner />} */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        upgrade: (store) => dispatch(storeActions.upgradeLadiboost(store)),
    }
}

const mapStateToProps = (state) => ({ ...state.store });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ActiveLadiBoost);