import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const getHistory = (data) => ({
    type: types.GET_HISTORY,
    meta: {
        endpoint: endpoint.GET_HISTORY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    getHistory
}