import React from 'react';
import orderActions from '../../../redux/futures/order/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import * as types from '../../../redux/futures/order/types';
import AddCommission from './AddCommission';

class Detail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            order: {
                ladi_uid: '',
                _id: '',
                reference_no: '',
                discount: '',
                total: '',
                note: '',
                status: ''
            },
            details: []
        }
    }

    // componentDidMount() {
    //     this.getDetail();
    // }

    componentDidUpdate() {
        window.LadiUI.init();
    }


    componentWillReceiveProps(nextProps) {


        if (this.props.currentPayment !== nextProps.currentPayment) {
            this.setState({
                order: _.pick(nextProps.currentPayment, _.keys(this.state.order))
            })
        }

        if (_.includes([types.DETAIL_ORDER], nextProps.action)) {
            if (nextProps.status) {

                this.setState({
                    details: nextProps.details,
                });
            } else {
            }
        }
    }

    render() {
        const { details, order } = this.state;
        const { affilate } = details;

        return (
            <div id="modal-detail" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-detail">
                <div className="ladiui-modal-content" style={{ maxWidth: '1200px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-detail"><i className="ladi-icon icon-e-remove" data-dismiss="modal-detail" /></button>
                        {
                            (!order.credit_used || order.credit_used == 0) &&
                            <button className="ladiui button primary open-modal" modal="modal-add-commission" >Tạo commission affiliate</button>
                        }
                        {
                            order.credit_used && order.credit_used > 0 &&
                            <h3>Đơn hàng được partner kích hoạt </h3>
                        }
                    </div>
                    <div className="ladiui-modal-body">

                        <div className="order-box" style={{ width: '970px', margin: '15px' }}>
                            {/* <ul> */}
                            <div className="ladiui-row" style={{ height: '50px' }}>

                                <div className="col-50">
                                    <strong>Tổng tiền: </strong>
                                    {
                                        this.state.order.total && this.state.order.total.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                    }
                                </div>


                                <div className="col-50">
                                    <strong>Giảm giá: </strong>
                                    {
                                        this.state.order.discount
                                    }
                                </div>
                            </div>
                            <div className="ladiui-row" style={{ height: '50px' }}>
                                <div className="col-50">
                                    <strong>Ghi chú: </strong>{this.state.order.note}
                                </div>
                                <div className="col-50">
                                    <strong>Trạng thái: </strong>{this.state.order.status}
                                </div>
                            </div>

                            {/* <li><strong>admin_id: ''</strong>{this.state.admin_id}</li> */}

                            {/* </ul> */}
                            {this.props.loading && <Spinner />}
                        </div>

                    </div>
                    <table className="ladiui table">
                        <thead>
                            <tr>
                                <th className="text-left">Store ID</th>
                                <th className="text-left">Store Name</th>
                                <th className="text-left">App</th>
                                <th className="text-left">Tên gói</th>
                                <th className="text-left">Tháng</th>

                                <th className="text-left">Tháng KM</th>

                                <th className="text-left">Tổng tiền</th>
                                <th className="text-right">Thời gian tạo</th>
                                {/* <th className="text-center">Discount Type</th>
                                <th className="text-center">Discount Value</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(details, detail => {
                                    let displayName = detail.pkg_name;
                                    if (detail.pkg_name == 'PREMIUM_5') {
                                        displayName = 'BUSSINESS_3';
                                    }
                                    if (detail.pkg_name == 'PREMIUM_10') {
                                        displayName = 'BUSSINESS_10';
                                    }
                                    if (detail.pkg_name == 'PREMIUM_20') {
                                        displayName = 'BUSSINESS_20';
                                    }
                                    displayName = displayName.replace('PREMIUM', 'BUSSINESS');
                                    if (detail.course_pkg_name != '') {
                                        displayName = 'Khóa học:' + detail.course_pkg_name;
                                    }
                                    return <tr key={detail._id}>
                                        <td>{detail.store_id}</td>
                                        <td>{detail.store_name}</td>
                                        <td>{appConst.APP[detail.pkg_type]}</td>
                                        <td>{displayName}</td>
                                        <td>{detail.pkg_month}</td>
                                        <td>{detail.addition_month}</td>
                                        <td>{detail.subtotal.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                        <td className="text-right">{baseHelper.formatStrToDate(detail.created_at)}</td>
                                        {/* <td>{detail.discount_type}</td>
                                        <td>{detail.discount_value}</td> */}
                                    </tr>
                                }
                                )
                            }
                        </tbody>
                    </table>

                    <div className="ladiui-modal-footer">

                        {/* {this.props.loading && <Spinner />} */}
                    </div>
                </div>
                <AddCommission
                    ref={this.formRef}
                    currentOrder={this.state.order}
                    affilate={affilate}
                />

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDetail: (order) => dispatch(orderActions.getDetail(order))
    }
}

const mapStateToProps = (state) => ({ ...state.order });

export default connect(mapStateToProps, mapDispatchToProps)(Detail);