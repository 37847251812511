import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const moveLadiPage = (data) => ({
    type: types.MOVE_LADIPAGE,
    meta: {
        endpoint: endpoint.MOVE_LADIPAGE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const blockUrl = (data) => ({
    type: types.BLOCK_URL,
    meta: {
        endpoint: endpoint.BLOCK_URL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const blockPublish = (data) => ({
    type: types.BLOCK_PUBLISH,
    meta: {
        endpoint: endpoint.BLOCK_PUBLISH,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const moveAllLadiPage = (data) => ({
    type: types.MOVE_ALL_LADIPAGE,
    meta: {
        endpoint: endpoint.MOVE_ALL_LADIPAGE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const restoreLadiPage = (data) => ({
    type: types.RESTORE_LADIPAGE,
    meta: {
        endpoint: endpoint.RESTORE_LADIPAGE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const changeEmail = (data) => ({
    type: types.CHANGE_EMAIL,
    meta: {
        endpoint: endpoint.CHANGE_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const checkDomain = (data) => ({
    type: types.CHECK_DOMAIN,
    meta: {
        endpoint: endpoint.CHECK_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const checkLadiLink = (body) => ({
    type: types.CHECK_LADI_LINK,
    meta: {
        endpoint: endpoint.CHECK_LADI_LINK,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: true
    }
});

const blockLadiLink = (body) => ({
    type: types.BLOCK_LADI_LINK,
    meta: {
        endpoint: endpoint.BLOCK_LADI_LINK,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: true
    }
});

const deleteMemberLadiPage = (data) => ({
    type: types.DELETE_MEMBER_LP,
    meta: {
        endpoint: endpoint.DELETE_MEMBER_LP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const resetMemberLadiPage = (data) => ({
    type: types.RESET_MEMBER_LP,
    meta: {
        endpoint: endpoint.RESET_MEMBER_LP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const deleteDomain = (data) => ({
    type: types.DELETE_DOMAIN,
    meta: {
        endpoint: endpoint.DELETE_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const blackListDomain = (data) => ({
    type: types.BLACK_LIST_DOMAIN,
    meta: {
        endpoint: endpoint.BLACK_LIST_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const whiteListDomain = (data) => ({
    type: types.ADD_DOMAIN_WHITELIST,
    meta: {
        endpoint: endpoint.ADD_DOMAIN_WHITELIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const blockDomain = (data) => ({
    type: types.BLOCK_DOMAIN,
    meta: {
        endpoint: endpoint.BLOCK_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const blockIpForm = (data) => ({
    type: types.BLOCK_IP_FORM,
    meta: {
        endpoint: endpoint.BLOCK_IP_FORM,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const actionSSL = (data) => ({
    type: types.ACTION_SSL,
    meta: {
        endpoint: endpoint.ACTION_SSL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const checkDomainSsl = (data) => ({
    type: types.CHECK_DOMAIN_SSL,
    meta: {
        endpoint: endpoint.CHECK_DOMAIN_SSL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const verifyEmail = (data) => ({
    type: types.VERIFY_EMAIL,
    meta: {
        endpoint: endpoint.VERIFY_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const verifyEmailLadiFlow = (data) => ({
    type: types.VERIFY_EMAIL_LADIFLOW,
    meta: {
        endpoint: endpoint.VERIFY_EMAIL_LADIFLOW,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const verifyDomain = (data) => ({
    type: types.VERIFY_DOMAIN,
    meta: {
        endpoint: endpoint.VERIFY_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const verifyDomainNew = (data) => ({
    type: types.VERIFY_DOMAIN_NEW,
    meta: {
        endpoint: endpoint.VERIFY_DOMAIN_NEW,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const stopUrl = (data) => ({
    type: types.STOP_URL,
    meta: {
        endpoint: endpoint.STOP_URL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const activeCourse = (data) => ({
    type: types.ACTIVE_COURSE,
    meta: {
        endpoint: endpoint.ACTIVE_COURSE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const reloadConfigSSL = (data) => ({
    type: types.RELOAD_CONFIG_SSL,
    meta: {
        endpoint: endpoint.RELOAD_CONFIG_SSL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const reconnectRedis = (data) => ({
    type: types.RECONNECT_REDIS,
    meta: {
        endpoint: endpoint.RECONNECT_REDIS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const addStorage = (data) => ({
    type: types.ADD_STORAGE,
    meta: {
        endpoint: endpoint.ADD_STORAGE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const removeKeyRedis = (data) => ({
    type: types.REMOVE_KEY_REDIS,
    meta: {
        endpoint: endpoint.REMOVE_KEY_REDIS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const reportApota = (data) => ({
    type: types.EXPORT_REPORT_APOTA,
    meta: {
        endpoint: endpoint.EXPORT_REPORT_APOTA,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const reportTraffic = (data) => ({
    type: types.REPORT_TRAFFIC,
    meta: {
        endpoint: endpoint.REPORT_TRAFFIC,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    moveLadiPage,
    moveAllLadiPage,
    restoreLadiPage,
    changeEmail,
    checkDomain,
    deleteDomain,
    blackListDomain,
    whiteListDomain,
    actionSSL,
    checkDomainSsl,
    verifyEmail,
    verifyEmailLadiFlow,
    verifyDomain,
    verifyDomainNew,
    reloadConfigSSL,
    reconnectRedis,
    deleteMemberLadiPage,
    resetMemberLadiPage,
    blockUrl,
    blockPublish,
    addStorage,
    removeKeyRedis,
    blockDomain,
    blockIpForm,
    checkLadiLink,
    blockLadiLink,
    reportApota,
    reportTraffic,
    stopUrl,
    activeCourse

}