import React from 'react';

import { connect } from 'react-redux';
import affilateActions from '../../redux/futures/affilate/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/affilate/types';

import Form from './components/Form';
import View from './components/View';
import LoadingTable from '../../components/LoadingTable';

import "react-datepicker/dist/react-datepicker.css";

class Affilate extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            searchLevel: {
                name: appConst.AFFILATE_LEVEL.ALL.name,
                value: appConst.AFFILATE_LEVEL.ALL.value,
                selected: appConst.AFFILATE_LEVEL.ALL,
            },
            fromDate: firstDay,
            toDate: new Date(),
            code: "",
            email: "",
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            affilateList: [],
            mode: appConst.FORM_MODE.CREATE,
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.selectedOrderIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.AFFILATE_LIST) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
            // if (nextProps.action === types.LIST_ORDER ){
            //     this.state.();
            // }
        }
        if (nextProps.action == types.ACTIVE_AFFILATE
            || nextProps.action == types.UPDATE_AFFILATE) {

            if (nextProps.status) {
                setTimeout(() => {
                    this.list();
                }, 500);

            }
        }
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {


        // document.getElementById("checkAllItem").checked = false;
        // window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        // e.checked = false;
        // });


        const { selectedLimit, code, email, searchLevel } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        
        
        let { fromDate, toDate } = this.state;
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);
        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "code": code,
            "email": email,
            "level": searchLevel.selected,
            // "from_date": fromDate,
            // "to_date": toDate
        };

        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    }

    /**
     * Tim kiem theo level
     */
    onChangeSearchLevel = (option) => {
        
        this.setState({
            searchLevel: { ...this.state.searchLevel, selected: option.value },
        }, () => {
            this.list();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }


    /****************************************************************/

    openCreateForm = () => {
        this.setState({
            currentAffilate: false,
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentAffilate) => {
        this.setState({
            currentAffilate,
            mode: appConst.FORM_MODE.EDIT
        })
    }
    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedOrderIds = [];
        if (checked) {
            this.selectedOrderIds = _.map(this.props.affilates, store => store.store_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, orderId) => {
        const { checked } = event.target;
        const { selectedOrderIds } = this;

        if (checked) {
            if (!_.includes(selectedOrderIds, orderId)) {
                selectedOrderIds.push(orderId);
            }

            if (selectedOrderIds.length == this.props.affilates.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedOrderIds, selectedOrderId => selectedOrderId == orderId);
        }


    }

    deleteAllItems = () => {
        if (this.selectedOrderIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }

        this.props.deleteOrders(this.selectedOrderIds);
    }

    deleteItem = (orderId) => {
        this.props.deleteOrder(orderId);
    }

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    // view = (currentAffilate) => {
    //     this.setState({
    //         currentAffilate
    //     })
    // }

    /****************************************************************/


    render() {

        const { totalRecord } = this.props;
        const { activePage, selectedLimit, code, email, searchLevel } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.affilates || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.affilates.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.AFFILATE_LIST);
        
        return (
            <div>
                <h1 className="page-title">Affiliate</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo mã code" name="code" value={code} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo email" name="email" value={email} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        
                        {/* <div className="action-item">
                            <span className="title-date">Created Date</span>
                        </div> */}
                        {/* <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                        <div className="action-item">
                           
                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div> */}
                        {/* <div className="action-item">
                            <span className="title-date">Level</span>
                        </div> */}
                        <div className="action-item">
                            <div className="ladiui data-status" style={{width:'160px'}}>
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init" >{searchLevel.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.AFFILATE_LEVEL, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchLevel(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ladiui actions">
                            <div className="action-item">
                                <button className="ladiui button primary icon-c-add open-modal" modal="modal-affilateform" onClick={this.openCreateForm}>Kích hoạt affiliate</button>
                            </div>
                        </div>
                    </div>

                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>
                                <div className="ladi-card">
                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                {/* <th className="ladiui checkall text-center">
                                                <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                            </th> */}
                                                {/* <th name="store_id" onClick={this.sort} className="text-left sorting">ID</th> */}

                                                <th className="text-left">Email</th>
                                                <th className="text-left">Affiliate Code</th>
                                                {/* <th name="name" onClick={this.sort} className="text-left sorting">Level</th> */}
                                                <th className="text-left">Tổng số khách</th>
                                                <th className="text-left">Số đã mua gói</th>
                                                <th className="text-left">Hoa hồng C1</th>
                                                <th className="text-left">Hoa hồng C2</th>
                                                <th className="text-left">Hoa hồng cho Khách hàng</th>
                                                {/* <th className="text-left">Commission Tier2</th> */}
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Thời gian tạo</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.affilates, affilate => {
                                                    affilate.email = (affilate.userInfo && affilate.userInfo.email) || '';
                                                    return <tr key={affilate._id}>
                                                        {/* <td className="text-center">
                                                        <input type="checkbox" onClick={(event) => this.checkItem(event, affilate._id)} className="ladiui checkbox" />
                                                    </td> */}
                                                        {/* <td>{store.store_id}</td> */}
                                                        <td>{affilate.email}</td>
                                                        <td>{affilate.code}</td>
                                                        {/* <td>{affilate.level}</td> */}
                                                        <td>{affilate.total_invited}</td>
                                                        <td>{affilate.total_purchased}</td>
                                                        <td>{affilate.commission_1}</td>
                                                        <td>{affilate.commission_2}</td>
                                                        <td>{affilate.customer_commission}</td>
                                                        {/* <td>{affilate.commission_tier_2}</td> */}
                                                        <td>{baseHelper.formatStrToDate(affilate.created_at)}</td>

                                                        <td>

                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>
                                                                        <li><a onClick={() => this.openEditForm(affilate)} className="ladiui open-modal" modal="modal-affilateform" >Chỉnh sửa</a></li>
                                                                        {/* <li><a onClick={() => this.view(affilate)} className="ladiui open-modal" modal="modal-affilateview" >View</a></li> */}


                                                                    </ul>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                        {
                                             totalRecord <= 0 &&                                                
                                            <tr className="text-center"> 
                                                <td colSpan="100%">Không có bản ghi nào</td> 
                                            </tr>                                             
                                        }
                                         </tbody>
                                    </table>
                                </div>
                            </div>
                    }

                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Show</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                </div>
                <View 
                     currentAffilate={this.state.currentAffilate} 
                 />

                <Form
                    currentAffilate={this.state.currentAffilate}
                    mode={this.state.mode}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(affilateActions.list(data))
    }
}

const mapStateToProps = (state) => ({ ...state.affilate });

export default connect(mapStateToProps, mapDispatchToProps)(Affilate);