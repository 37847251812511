import React from 'react';
import PropTypes from 'prop-types';
import affilateActions from '../../../redux/futures/affilate/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import PaymentInfo from './PaymentInfo';

import * as types from '../../../redux/futures/affilate/types';




class Detail extends React.Component {

    static propTypes = {
        affilateInfo: PropTypes.object
    }

    constructor(props) {
        super(props);
        
        this.state = { 
            affilateInfo: {
                ladi_uid:"",
    
                email: "",
    
                code: "",
    
                payment_info: "", // thông tin thanh toán
    
                level: "",
    
                commission_1: "",
    
                commission_2: "",
    
                customer_commission: "",
    
                commission_tier_2: ""
    
            }
        };
        
    }


    componentDidMount() {
        window.LadiUI.init();
        this.getAffilateInfo();
    }

    componentWillReceiveProps(nextProps) {

        // if (nextProps.affilateInfo && this.state.affilateInfo !== nextProps.affilateInfo) {
        //     this.setState({
        //         affilateInfo: nextProps.affilateInfo
        //     });
        // }
        // if (nextProps.action == types.MY_AFFILATE_INFO) {

        //     if (nextProps.status) {
        //         this.setState({
        //             affilateInfo: nextProps.affilateInfo
        //         });
        //         // window.LadiUI.closeModal(this.ref.current);
        //         window.LadiUI.toastr('success', '', nextProps.message);
        //     } else {
        //         window.LadiUI.toastr('error', '', nextProps.message);
        //     }
        // }
        // console.log(nextProps.action , types.MY_AFFILATE_INFO, nextProps.affilateInfo);
        if (nextProps.action == types.MY_AFFILATE_INFO) {
            if (nextProps.status) { 
                this.setState({
                    affilateInfo: nextProps.affilateInfo,
                     
                });
            }else {
                this.setState({
                    affilateInfo: false,
                     
                });
            }
            return;
        }
    }
    
    getAffilateInfo = () => {
        this.props.getAffilateInfo();
      }

    render() {
        const { affilateInfo } = this.state;

        const title = 'Affiliate detail';
         
        return  (affilateInfo) 
            ?
            (<div className="box-lastlogin ladiui card col">
                <div >
                    <div className="card-header">
                    <h3>Affiliate Info</h3>
                    <a href="#" className="order-customer-edit"><i modal="modal-affilateProfile"
                        className="ladiui ladi-icon icon-d-edit open-modal"></i></a>
                    </div>
                    <div className="profile-info" style={{padding:'15px'}}> 
                    <ul>
                        
                        <li>Code: <strong> {affilateInfo.code}</strong></li>
                        <li>Level: {affilateInfo.level}</li>
                        <li>Payment Info:  {affilateInfo.payment_info}</li>
                        <li>Commission 1:  {affilateInfo.commission_1}</li>
                        <li>Commission 2:  {affilateInfo.commission_2}</li>
                        <li>Customer Commission:  {affilateInfo.customer_commission}</li>
                        <li>Commission Tier 2: {affilateInfo.commission_tier_2}</li>
                    </ul>
                    </div>
                </div>
                <PaymentInfo 
                    affilateInfo={affilateInfo}
                />
            </div>)
                 
                 
                :
                <div></div>
                   
            
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (affilateInfo) => dispatch(affilateActions.updateUserInfo(affilateInfo)),
        getAffilateInfo: () => dispatch(affilateActions.myAffilateInfo())
    }
}

const mapStateToProps = (state) => ({ ...state.affilate });

export default connect(mapStateToProps, mapDispatchToProps)(Detail);