import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.LIST_REPORT_DAILY: {
            return {
                ...state,
                action: types.LIST_REPORT_DAILY,
                records: action.status ? action.payload.records : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.LIST_REPORT_MONTHLY: {
            return {
                ...state,
                action: types.LIST_REPORT_MONTHLY,
                records: action.status ? action.payload.records : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.LIST_REPORT_REVENUE: {
            return {
                ...state,
                action: types.LIST_REPORT_REVENUE,
                records: action.status ? action.payload.records : [],
                status: action.status,
                message: action.message,
            }
        }
            
        default:
            return state;
    }
}

