import React from 'react';

import { connect } from 'react-redux';
import packageActions from '../../redux/futures/package/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/package/types';
// import Form from './components/Form';



import Detail from '../packages/components/Detail';

class Packages extends React.Component {

    constructor(props) {
        super(props);

        
        this.state = {
            activePage: 1, 
            searchType: {
                name: appConst.PACKAGE_OPTIONS.ALL.name,
                value: appConst.PACKAGE_OPTIONS.ALL.value,
                selected: appConst.PACKAGE_OPTIONS.ALL,
            }, 
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            currentPackage: {},
            mode: appConst.FORM_MODE.CREATE,
            packages: [],
            sortby: appConst.SORT_BY.DESCENDING
        }
 
        this.selectedPackageIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.action, nextProps.packages );
        if (nextProps.action && nextProps.action == types.LIST_PACKAGE) {
            
            if (nextProps.status) {
                this.setState({ packages: nextProps.packages });
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
            
            // this.list();
        }
    }

     

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {
        

        document.getElementById("checkAllItem").checked = false;
        window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
            e.checked = false;
        });


        const { selectedLimit, searchType, name } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        // Reset selectedPackageIds
        this.selectedPackageIds = [];

        
        const typeSelected = searchType.selected;

        const data = {
            "limit":selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "app_code": typeSelected,
            "name": name,
            "status":true
        };

        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchType = (option) => { 
        this.setState({
            searchType: { ...this.state.searchType, selected: option.value },
        }, () => {
            this.list();
        })
    }
 
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedPackageIds = [];
        if (checked) {
            this.selectedPackageIds = _.map(this.state.packages, store => store.store_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, packageId) => {
        const { checked } = event.target;
        const { selectedPackageIds } = this;

        if (checked) {
            if (!_.includes(selectedPackageIds, packageId)) {
                selectedPackageIds.push(packageId);
            }

            if (selectedPackageIds.length == this.state.packages.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedPackageIds, selectedOrderId => selectedOrderId == packageId);
        }

        
    }
     

    sort = (event, sortKey) => {
        const { classList } = event.target;
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            this.setState({
                sortby: appConst.SORT_BY.DESCENDING
            });
            classList.remove('up'); 
        } else {
            this.setState({
                sortby: appConst.SORT_BY.ASCENDING
            });
            classList.add('up'); 
        }
        
        const data = this.state.packages;
        data.sort((a,b) => {
            
            if (this.state.sortby == 1) {
                return a[sortKey].toString().localeCompare(b[sortKey].toString());
            }
            return b[sortKey].toString().localeCompare(a[sortKey].toString());
        });
        this.setState({ packages: data });
    }

    print = () => {
        this.setState({
            currentPackage: {},
            mode: appConst.FORM_MODE.CREATE
        })
    }

    view = (currentPackage) => {
        this.setState({
            currentPackage,
            mode: appConst.FORM_MODE.VIEW
        })
    }

    cancel = (currentPackage) => {
        this.setState({
            currentPackage,
            mode: appConst.FORM_MODE.EDIT
        })
    }

    /****************************************************************/


    render() { 
        const { totalRecord } = this.props;
        const { activePage, selectedLimit, searchType } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.state.packages || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.state.packages.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;

        return (
            <div>
                 <h1 className="page-title">Package List</h1>

                <div>
                    <div className="ladiui-table-actions">
                        
                       <div className="action-item">
                            <span className="title-date">App</span>
                            </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchType.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.PACKAGE_OPTIONS, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchType(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                         
                    </div>
                    <div className="ladi-card">

                        <table className="ladiui table">
                            <thead>
                                <tr>
                                    <th className="ladiui checkall text-center">
                                        <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                    </th>
                                    <th name="app" onClick={this.sort} className="text-left sorting">App</th>

                                    <th name="name" onClick={e => this.sort(e, 'name')} className="text-left sorting">Name</th>
                                    <th name="description" onClick={e => this.sort(e, 'method')} className="text-left sorting">Description</th>
                                    <th name="price" onClick={e => this.sort(e, 'amount')} className="text-left sorting">Price</th>
                                     
                                    <th name="created_at" onClick={e => this.sort(e, 'created_at')}className="text-left sorting">Created At</th>
                                     
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                   
                                    _.map(this.state.packages, packageItem => {
 
                                        return <tr key={packageItem._id}>
                                            <td className="text-center">
                                                <input type="checkbox" onClick={(event) => this.checkItem(event, packageItem._id)} className="ladiui checkbox" />
                                            </td>
                                            <td>{appConst.APP[packageItem.app_code]}</td>
                                            <td>{packageItem.name}</td>
                                            <td>{packageItem.description}</td>
                                            <td>{baseHelper.formatNumber(packageItem.price)} VND</td> 
                                            <td>{baseHelper.formatStrToDate(packageItem.created_at)}</td> 
                                            <td>
                                              
                                            </td>
                                        </tr>
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Show</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                </div>

                <Detail
                    currentPackage={this.state.currentPackage}
                    mode={this.state.mode}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(packageActions.list(data)),
        // deleteOrders: (packageIds) => dispatch(packageActions.deleteOrders(packageIds)),
        // deleteOrder: (packageId) => dispatch(packageActions.deleteOrder(packageId)),
    }
}

const mapStateToProps = (state) => ({ ...state.package });

export default connect(mapStateToProps, mapDispatchToProps)(Packages);