export const SIGNIN = 'SIGNIN';
export const SIGNUP = 'SIGNUP';
export const SIGNOUT = 'SIGNOUT';
export const COMPLETE_REGISTER = 'COMPLETE_REGISTER';
export const GET_ADMIN_INFO = 'GET_ADMIN_INFO';
export const UPDATE_ADMIN_INFO = 'UPDATE_ADMIN_INFO';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_URL_FACEBOOK = 'GET_URL_FACEBOOK';
export const GET_URL_GOOGLE = 'GET_URL_GOOGLE';
export const OAUTH2_FACEBOOK = 'OAUTH2_FACEBOOK';
export const OAUTH2_GOOGLE = 'OAUTH2_GOOGLE';

