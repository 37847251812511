import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.ADMIN_LIST,
    meta: {
        endpoint: endpoint.ADMIN_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const createAdmin = (data) => ({
    type: types.CREATE_ADMIN,
    meta: {
        endpoint: endpoint.CREATE_ADMIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const updateAdmin = (data) => ({
    type: types.ADMIN_UPDATE_ADMIN,
    meta: {
        endpoint: endpoint.ADMIN_UPDATE_ADMIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const getRoleList = () => ({
    type: types.GET_ROLE_LIST,
    meta: {
        endpoint: endpoint.GET_ROLE_LIST,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});
 
export default {
    list, 
    createAdmin,
    updateAdmin,
    getRoleList
}