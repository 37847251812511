import React from 'react';
import PropTypes from 'prop-types';
import appConst from '../../../config/const';

export default class Paid extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        onCancel: PropTypes.func,
        cancelText: PropTypes.string,
        onOk: PropTypes.func,
        okText: PropTypes.string,
        isLoading: PropTypes.bool,
    }


    constructor(props) {
        super(props);

        
        this.state = {
            method:appConst.PAYMENT_STATUS_OPTIONS.PAID.value 
        };
 
    }
    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    onClick = (event) => {
    }
    onChangeMethod = (event) => {
         
        this.setState({
            [event.target.name]: event.target.value,
        })
    }
    onOk = () => {
        const { method } = this.state;
        if (this.props.onOk) {
            this.props.onOk(method);
        }
    }

    setGender = (event) => {
        // console.log(event.target.value);
      }

    render() {
        const { id, title, content, cancelText, okText, isLoading } = this.props;

        return (
            
            <div id={id} className="ladiui-modal fade" tabIndex="-1" data-dismiss={id}>
                <div className="ladiui-modal-content confirm">
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal"
                        data-dismiss={id}><i className="ladi-icon icon-e-remove"
                            data-dismiss={id}></i></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <div className="ladiui form-group">

                            <h3>{content}</h3>
                        </div>
                          

                        <div className="ladiui-row">
                            <div className="ladiui form-group">
                                <input type="radio"   
                                    id="payment-paid"
                                    name="method"
                                    value={appConst.PAYMENT_STATUS_OPTIONS.PAID.value}
                                    onChange={this.onChangeMethod}
                                  
                                    defaultChecked
                                    // checked={this.state.method === appConst.PAYMENT_STATUS_OPTIONS.PAID.value}
                                />
                                <label htmlFor="payment-paid">Đã nhận tiền</label>
                            </div>
                        </div>
                        <div className="ladiui-row">
                            <div className="ladiui form-group">
                                <input type="radio"  
                                    name="method"
                                     id="payment-paid-debit"
                                    value={appConst.PAYMENT_STATUS_OPTIONS.PAID_DEBIT.value}
                                    onChange={this.onChangeMethod}
                                   
                                    // checked={this.state.method === appConst.PAYMENT_STATUS_OPTIONS.PAID_DEBIT.value}
                                />
                                <label htmlFor="payment-paid-debit">Chuyển khoản sau</label>
                            </div>
                        </div>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button danger close-modal" onClick={this.onCancel} data-dismiss={id}>{cancelText}</button>
                        <button className={`ladiui button primary ${isLoading ? 'loader' : ''}`} onClick={this.onOk}>{okText}</button>
                    </div>
                </div>
            </div>
        )
    }

}

