import React from 'react';

import { connect } from 'react-redux';
import pointActions from '../../redux/futures/point/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';

import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import LoadingTable from '../../components/LoadingTable';
 

import * as types from '../../redux/futures/point/types';

class Point extends React.Component {

    constructor(props) {
        super(props);

        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,

            searchStatus: {
                name: appConst.PAYMENT_STATUS_OPTIONS.ALL.name,
                value: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_STATUS_OPTIONS.ALL,
            },
            
            fromDate: firstDay,
            toDate: new Date(),
            searchEmail: '',
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
              
            points: [],
            sortby: appConst.SORT_BY.DESCENDING
        }

         this.muteActions = [];
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.LIST_POINT_LOGS) {
            if (nextProps.status) {
                this.setState({ pointlogs: nextProps.pointlogs });
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
            
            // this.getList();
        }
    }

    changeFromdate = (date) => {

        this.setState({
            fromDate: date
        }, () => {
            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }


    /**
     * list store
     */
    getList = (resetActivePageFlag = true) => {
        

        const { searchEmail, selectedLimit } = this.state;

        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
 

        let { fromDate, toDate } = this.state;
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);
        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit, 
            "email": searchEmail,
            "from_date": fromDate,
            "to_date": toDate
        };

        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.getList();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.getList();
        })
    }

    onChangeSearchMethod = (option) => {
        this.setState({
            searchMethod: { ...this.state.searchMethod, selected: option.value },
        }, () => {
            this.getList();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.getList();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.getList(false);
        });
    }
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedOrderIds = [];
        if (checked) {
            this.selectedOrderIds = _.map(this.state.points, store => store.store_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, orderId) => {
        const { checked } = event.target;
        const { selectedOrderIds } = this;

        if (checked) {
            if (!_.includes(selectedOrderIds, orderId)) {
                selectedOrderIds.push(orderId);
            }

            if (selectedOrderIds.length === this.state.points.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedOrderIds, selectedOrderId => selectedOrderId == orderId);
        }


    }


    sort = (event, sortKey) => {
        const { classList } = event.target;
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            this.setState({
                sortby: appConst.SORT_BY.DESCENDING
            });
            classList.remove('up');
        } else {
            this.setState({
                sortby: appConst.SORT_BY.ASCENDING
            });
            classList.add('up');
        }

        const data = this.state.points;
        data.sort((a, b) => {

            if (this.state.sortby === 1) {
                return a[sortKey].toString().localeCompare(b[sortKey].toString());
            }
            return b[sortKey].toString().localeCompare(a[sortKey].toString());
        });
        this.setState({ points: data });
    }
 

    /****************************************************************/


    render() {
        const { totalRecord } = this.props;
        const { activePage, selectedLimit, searchEmail, fromDate, toDate } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.state.pointlogs || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.state.pointlogs.length - 1;
        }


        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.LIST_POINT_LOGS);
        return (
            <div>
                <h1 className="page-title">Lịch sử cộng trừ point</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo email" name="searchEmail" value={searchEmail} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.getList} />
                            </div>
                        </div>
                         
                        <div className="action-item">

                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                         
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div> 
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>

                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                {/* <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th> */}
                                               <th className="text-left">Email khách hàng</th>
                                                <th name="reference_no" onClick={e => this.sort(e, 'order_reference_no')} className="text-left sorting">Mã đơn hàng</th>
                                                
                                                <th name="amount" onClick={e => this.sort(e, 'amount')} className="text-left sorting">Amount</th>
                                                <th className="text-left">Ghi chú</th>
                                                <th name="created_at" onClick={e => this.sort(e, 'created_at')} className="text-left sorting">Thời gian tạo</th>
                                                 
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.state.pointlogs, point => {

                                                    return <tr key={point._id}>
                                                        {/* <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, payment._id)} className="ladiui checkbox" />
                                                        </td> */}
                                                        <td>{point.user_email}</td>
                                                        <td>{point.reference_no}</td>
                                                        
                                                        <td>{point.point}</td>
                                                        <td>{point.description}</td>
                                                        <td>{baseHelper.formatStrToDate(point.created_at)}</td>
                                                       
                                                        <td>

                                                           
                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                        {
                                            totalRecord <= 0 &&                                                
                                            <tr className="text-center"> 
                                                <td colSpan="100%">Không có bản ghi nào</td> 
                                            </tr>                                             
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    { totalRecord > 0 &&
                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Show</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                    }
                </div>
 
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(pointActions.listPointLog(data)) 
    }
}

const mapStateToProps = (state) => ({ ...state.point });

export default connect(mapStateToProps, mapDispatchToProps)(Point);