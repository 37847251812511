import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.REFERRAL_LIST,
    meta: {
        endpoint: endpoint.REFERRAL_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
 
export default {
    list, 
}