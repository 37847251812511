import React from 'react';
import PropTypes from 'prop-types';
import adminActions from '../../../redux/futures/admin/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import Input from '../../../components/Input';


import * as types from '../../../redux/futures/admin/types';
const FormData = require('form-data');



class Form extends React.Component {

    static propTypes = {
        currentAdmin: PropTypes.object
    }

    constructor(props) {
        super(props);


        this.state = {
            searchStatus: {
                name: appConst.USER_STATUS.ACTIVE.name,
                value: appConst.USER_STATUS.ACTIVE.value,
                selected: appConst.USER_STATUS.ACTIVE,
            },
            roleSelected:{},
            currentAdmin: {
                _id: "",
                fullname: "",
                email: "",
                phone: "",
                role: "",
                status: true,
                roleName:""
            },
            roles:[],
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: ''

        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.defaultState = _.cloneDeep(this.state.currentAdmin);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        

        if (this.state.mode != nextProps.mode || this.state.currentAdmin != nextProps.currentAdmin){  


            const that = this;
            let searchStatus = {
                name: appConst.USER_STATUS.ACTIVE.name,
                value: appConst.USER_STATUS.ACTIVE.value,
                selected: appConst.USER_STATUS.ACTIVE,
            };
            
            _.map(appConst.USER_STATUS, option => {
                if (option.value ==  nextProps.currentAdmin.status){
                    searchStatus = {
                        name: option.name,
                        value: option.value,
                        selected: option,
                    };
                }
            });

            if (nextProps.mode == appConst.FORM_MODE.CREATE) {
                this.setState({
                    currentAdmin: _.pick(this.defaultState, _.keys(this.state.currentAdmin)),
                    mode:nextProps.mode,
                    searchStatus
                });
            }
            if (nextProps.mode == appConst.FORM_MODE.EDIT) {
                this.setState({
                    currentAdmin: _.pick(nextProps.currentAdmin, _.keys(this.state.currentAdmin)),
                    mode:nextProps.mode,
                    searchStatus
                });
            }
        }
         
        if (nextProps.action == types.ADMIN_UPDATE_ADMIN) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });

            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                }, 1000);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
        if (nextProps.action == types.GET_ROLE_LIST) {
            
          
            if (nextProps.status && nextProps.roles.length > 0) {
                let roleSelected = {
                    _id: nextProps.roles[0]._id,
                    name: nextProps.roles[0].name
                };
                
                if (nextProps.currentAdmin.role) {
                    _.map(nextProps.roles, role => {
                        if (role._id ==  nextProps.currentAdmin.role){
                            roleSelected = {
                                _id: role._id,
                                name: role.name
                            };
                        }
                    });
                }
                
                this.setState({
                    roles: nextProps.roles,
                    roleSelected 
                });
            }
        }
        if (nextProps.action == types.CREATE_ADMIN) {
             
            this.state.loading = false;
            this.state.classApply = this.state.classNormal;
            if (nextProps.status) { 
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                },1000);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const statusSelected = this.state.searchStatus.selected;
        const { currentAdmin,role,roleSelected } = this.state;
       
        if (this.props.mode === appConst.FORM_MODE.CREATE) {
            const jsonData = {
                "fullname": currentAdmin.fullname,
                "email": currentAdmin.email,
                "role_id": roleSelected._id,
                "phone": currentAdmin.phone 
            }
            // console.log(jsonData);
            this.props.create(jsonData);
        }else {
            const jsonData = {
                "fullname": currentAdmin.fullname,
                "phone": currentAdmin.phone,
                "time_zone": currentAdmin.tiomezone,
                "language": currentAdmin.language,
                "status": statusSelected.value,
                "role_id": roleSelected._id,
                "id": currentAdmin._id
            }
            // console.log(jsonData);
            this.props.update(jsonData);
        }

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangeInput = (event) => {

        this.setState({
            currentAdmin: {
                ...this.state.currentAdmin,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeSearchStatus = (option) => {
        console.log(option);
        this.setState({
            searchStatus: {
                name: option.name,
                value: option.value,
                selected: option,
            },
        }, () => {

        });

    }

    onChangeRole = (role) => {
        
        this.setState({
            roleSelected: { ...this.state.roleSelected, _id: role._id },
        }, () => {

        });

    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            currentAdmin: baseHelper.resetState(this.state.currentAdmin),
            roleSelected: baseHelper.resetState(this.state.roleSelected)
        });
        window.LadiUI.closeModal(this.ref.current);
    }

    render() {
        const { searchStatus, currentAdmin, roles,mode ,roleSelected} = this.state;
       
        const title = 'Chỉnh sửa thông tin admin';
        
        return (
            <div ref={this.ref} id="modal-admininfoform" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-admininfoform"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content">
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-admininfoform"><i className="ladi-icon icon-e-remove" data-dismiss="modal-admininfoform" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui-tab-content">
                                
                                <div className="ladiui-row">


                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email</label>
                                            {/* {
                                                mode == appConst.FORM_MODE.EDIT
                                                ?
                                                <input name="email" className="ladiui input" defaultValue={currentAdmin.email} 
                                                disabled={"disable"} />
                                                : */}
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="email"
                                                    validationName="Email"
                                                    placeholder="Email"
                                                    value={currentAdmin.email || ''}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: true, email: true }}
                                                    disabled={
                                                        (this.props.mode == appConst.FORM_MODE.EDIT)
                                                        ?
                                                        'disabled':
                                                        'false'
                                                    }
                                                />
                                            {/* } */}
                                        </div>
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Họ tên</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="fullname"
                                                validationName="FullName"
                                                placeholder="Họ tên"
                                                value={currentAdmin.fullname || ''}
                                                onChange={this.onChangeInput}
                                                // validations={{ isRequired: true }}
                                            />
                                            {/* <input name="full_name" className="ladiui input" value={currentAdmin.fullname} /> */}
                                        </div>
                                    </div>

                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Trạng thái</label>
                                            <div className="ladiui-select-options">

                                                <span className="ladiui-select-init">{searchStatus.name}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.USER_STATUS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeSearchStatus(option)}><a data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Số điện thoại</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="phone"
                                                validationName="Phone"
                                                placeholder="Số điện thoại"
                                                value={currentAdmin.phone}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: false }}
                                            />
                                        </div>
                                    </div>
                                                    
                                </div>
                                <div className="ladiui-row">
                                <div className="ladiui form-group"> 
                                  <label className="ladiui-label">Quyền hạn</label>
                                        <div className="ladiui-select-options">

                                            <span className="ladiui-select-init">{roleSelected.name}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(roles, option =>
                                                        <li key={option._id} className="select-item" onClick={() => this.onChangeRole(option)}><a data-value={option._id}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-unserinfoform">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-unserinfoform" />Huỷ
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                         </button>

                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (currentAdmin) => dispatch(adminActions.updateAdmin(currentAdmin)),
        create: (data) => dispatch(adminActions.createAdmin(data))
    }
}

const mapStateToProps = (state) => ({ ...state.admin });

export default connect(mapStateToProps, mapDispatchToProps)(Form);