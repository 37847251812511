import React from 'react';

import { connect } from 'react-redux';
import userActions from '../../redux/futures/user/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as userTypes from '../../redux/futures/user/types';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import View from './components/View';
import LoadingTable from '../../components/LoadingTable';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

class UserDetails extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            affilate_code: "",
            fromDate: moment(firstDay),
            toDate: moment(new Date()),
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            userList: [],
            copyiedStoreID: "",
            mode: appConst.FORM_MODE.CREATE,
            searchType: {
                name: appConst.USER_REGISTER_TYPE.ALL.name,
                value: appConst.USER_REGISTER_TYPE.ALL.value,
                selected: appConst.USER_REGISTER_TYPE.ALL,
            },
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                status: "",

                point: "",

                credit: "",

                created_at: "",

                _id: "",

                code: ""
            },
            submitData: false,
            excelDatas: [],

        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.selectedComissionIds = [];
        this.muteActions = [];

    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.user.action && !_.includes(this.muteActions, nextProps.user.action)) {
            if (nextProps.user.action === userTypes.USER_LIST_BYDATE) {
                if (nextProps.user.status) {
                    // window.LadiUI.toastr('success', '', nextProps.message);
                    this.setState({
                        excelDatas: nextProps.user.excelDatas
                    });
                } else {
                    window.LadiUI.toastr('error', '', nextProps.user.message);
                } 
            }
            if (nextProps.user.action == userTypes.EXPORT_USER) {
                setTimeout(() => {
                    this.setState({
                        submitData:false
                    });
                }, 200);
                if (nextProps.status) {
                    
                    // this.setState({
                    //     downloadUrl: nextProps.url
                    // });
                }
            }
        }

    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {
        const { selectedLimit, affilate_code, searchType } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        
        let { fromDate, toDate } = this.state;
        fromDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            milisecond: 0
        });

        toDate.set({
            hour: 23,
            minute: 59,
            second: 59,
            milisecond: 999
        });

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "affilate_code": affilate_code.trim(),
            "to_date": toDate,
            "from_date": fromDate,
            "is_affilate": searchType.selected.value
        };
        // console.log(data);
        this.props.listBydate(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {

            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option },
        }, () => {
            this.list();
        })
    }

    onChangeSearchType = (option) => {
        this.setState({
            searchType: { ...this.state.searchType, selected: option },
        }, () => {
            this.list();
        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

    openCreateForm = () => {
        this.setState({
            currentUser: false,
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentUser) => {
        this.setState({
            currentUser,
            mode: appConst.FORM_MODE.EDIT
        })
    }
    openGetPassword = (currentUser) => {
        this.setState({
            currentUser 
        });
        
        if (currentUser._id) {
            // console.log({ladi_uid: currentUser._id});
            this.props.getToken({ ladi_uid: currentUser._id, email: currentUser.email });
        }
    }
    /*******************************CHECK AND ACTIONS***************************/

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (currentUser) => {
        this.setState({
            currentUser 
        })
    }

    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedUserIds = [];
        if (checked) {
            this.selectedUserIds = _.map(this.props.commissions, store => store._id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, commissionId) => {
        const { checked } = event.target;
        const { selectedUserIds } = this;

        if (checked) {
            if (!_.includes(selectedUserIds, commissionId)) {
                selectedUserIds.push(commissionId);
            }

            if (selectedUserIds.length == this.props.commissions.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedUserIds, selectedCommissionId => selectedCommissionId == commissionId);
        }
    }

    exportExcelAll = () => {
        const { excelDatas,submitData } = this.state;
        if (submitData) return;
        if (excelDatas.length == 0) {
            window.LadiUI.toastr('error', '', 'Data empty');
            return;
        } 
        const data = {
            ids: excelDatas
        }; 
        this.setState({
            submitData:true
        });
        this.props.exportExcel(data);
    }

    exportList = () => {
        const { submitData } = this.state;
        if (submitData) return;
        if (this.selectedUserIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }
        const data = {
            ids: this.selectedUserIds
        }; 
        this.setState({
            submitData:true
        });
        this.props.exportExcel(data);
    }

    copyToClipboard = (user) => {
        // console.log(store.store_id);
        this.setState({
            copyiedUserID: user._id
        },()=> {
            this.textAreaRef.select();
            document.execCommand('copy');
            window.LadiUI.toastr('success', '', 'Đã copy vào clipboard');
        })
        
      };
    /****************************************************************/


    render() {

        const { totalRecord } = this.props.user;
        const { activePage, selectedLimit, searchStatus, searchType, affilate_code, currentUser } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.user.users || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.user.users.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.user.loading && (this.props.user.waitting == userTypes.USER_LIST);
        const isLoadingPaid = this.props.user.loading && (this.props.user.waitting == userTypes.HARD_DELETE_USER);
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        
        const partnerInfo = {
            email: "",
 
            credit: 0,

            _id: ""
        };
        if (currentUser._id) {
            partnerInfo.email = currentUser.email;
            partnerInfo.credit = currentUser.credit;
            partnerInfo._id = currentUser._id;
        }

        return (
            <div>
                <h1 className="page-title">Khách hàng</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">
                            <ul className="ladiui-navbar -horizontal">
                                <li className="ladiui-dropdown">
                                <a href="#" className={
                                    "ladiui button outline-primary" +(this.state.submitData ? " loader" : "")
                                    
                                    }>Thao tác <i
                                    className="ladi-icon icon-down-arrow"></i></a>
                                    <ul>
                                        <li><a onClick={this.exportExcelAll}>Lưu tất cả</a></li>
                                        <li><a onClick={this.exportList}>Lưu file các dòng đã chọn</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo affiliate code" name="affilate_code" value={affilate_code} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">
                            <DateRangePicker
                                startDateId="fromDate"
                                endDateId="toDate"
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState({
                                        fromDate: startDate,
                                        toDate: endDate,
                                    }, () => {
                                        if (endDate) {
                                            this.list();
                                        }
                                    })
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={
                                    <i className="ladi-icon icon-calendar" />
                                }
                                displayFormat={()=>appConst.DEFAULT_FORMAT_DATE_NEW}
                                // startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                // endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => {
                                    return moment(new Date()).isSame(date, 'day');
                                }}
                            />
                        </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options" style={{minWidth:'150px'}}>
                                    <span className="ladiui-select-init">{searchType.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.USER_REGISTER_TYPE, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchType(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>
                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th>
                                                {/* <th className="text-left">UID</th> */}
                                                <th className="text-left">Tên</th>
                                                <th className="text-left">Email</th>
                                                <th className="text-left">Affiliate Code</th>
                                                <th className="text-left">Số điện thoại</th>
                                                
                                                 
                                                {/* <th className="text-left">Đặng nhập gần nhất</th> */}
                                                {/* <th className="text-left">Trạng thái</th> */}
                                                {/* <th className="text-left">Commission Tier2</th> */}
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Thời gian đăng kí</th>
                                                {/* <th className="text-left">Affilate Email</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.user.users, user => {

                                                    return <tr key={user._id}>
                                                        <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, user._id)} className="ladiui checkbox" />
                                                        </td>
                                                        <td>{user.fullname}
                                                            {
                                                                user.is_partner &&
                                                                <span title="Partner ship">
                                                                    <i className="ladi-icon icon-d-check" style={{cursor:"pointer",color:"#0C61F2",marginLeft:"10px",fontSize:"17px"}}/>
                                                                </span>
                                                            }
                                                        </td>
                                                        <td>{user.email}</td>
                                                        <td>{user.code}</td>
                                                        <td>{user.phone}</td>
                                                        <td>{baseHelper.formatStrToDate(user.created_at)}</td>
                                                        {/* <td>{user.affilate_id}</td> */}
                                                        <td>

                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>
                                                                        <li><a onClick={() => this.view(user)} className="ladiui open-modal" modal="modal-userview" >Chi tiết</a></li>

                                                                    </ul>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Hiển thị</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                </div>
                <View 
                     currentUser={this.state.currentUser} 
                 />
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listBydate: (data) => dispatch(userActions.listBydate(data)),
        exportExcel: (data) => dispatch(userActions.exportExcel(data)),
    }
}

const mapStateToProps = (state) => ({ user: { ...state.user }, partner: {...state.partner} });

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);