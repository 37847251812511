import React from 'react';

import { connect } from 'react-redux';
import paymentActions from '../../redux/futures/payment/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';

import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import LoadingTable from '../../components/LoadingTable';
import Update from '../payments/components/Update';

import * as types from '../../redux/futures/payment/types';

class Payment extends React.Component {

    constructor(props) {
        super(props);

        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,

            searchStatus: {
                name: appConst.PAYMENT_STATUS_OPTIONS.ALL.name,
                value: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_STATUS_OPTIONS.ALL,
            },
            searchMethod: {
                name: appConst.PAYMENT_METHOD_OPTIONS.ALL.name,
                value: appConst.PAYMENT_METHOD_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_METHOD_OPTIONS.ALL,
            },
            fromDate: firstDay,
            toDate: new Date(),
            searchOrderNo: '',
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            currentPayment: {},
            mode: appConst.FORM_MODE.CREATE,
            payments: [],
            sortby: appConst.SORT_BY.DESCENDING
        }

        this.selectedOrderIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.LIST_PAYMENT) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
            this.setState({ payments: nextProps.payments });
            // this.list();
        }
        if (nextProps.action == types.CONFIRM_BANK) {
            if (nextProps.status) {
                setTimeout(() => {
                    this.getList();
                }, 600);
            }

        }
    }

    changeFromdate = (date) => {

        this.setState({
            fromDate: date
        }, () => {
            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }


    /**
     * list store
     */
    getList = (resetActivePageFlag = true) => {
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

        document.getElementById("checkAllItem").checked = false;
        window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
            e.checked = false;
        });


        const { searchOrderNo, selectedLimit } = this.state;

        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        // Reset selectedOrderIds
        this.selectedOrderIds = [];

        const statusSelected = this.state.searchStatus.selected;
        const methodSelected = this.state.searchMethod.selected;

        let { fromDate, toDate } = this.state;
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);
        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "status": statusSelected,
            "method": methodSelected,
            "reference_no": searchOrderNo,
            "from_date": fromDate,
            "to_date": toDate
        };

        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.getList();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.getList();
        })
    }

    onChangeSearchMethod = (option) => {
        this.setState({
            searchMethod: { ...this.state.searchMethod, selected: option.value },
        }, () => {
            this.getList();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.getList();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.getList(false);
        });
    }
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedOrderIds = [];
        if (checked) {
            this.selectedOrderIds = _.map(this.state.payments, store => store.store_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, orderId) => {
        const { checked } = event.target;
        const { selectedOrderIds } = this;

        if (checked) {
            if (!_.includes(selectedOrderIds, orderId)) {
                selectedOrderIds.push(orderId);
            }

            if (selectedOrderIds.length == this.state.payments.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedOrderIds, selectedOrderId => selectedOrderId == orderId);
        }


    }


    sort = (event, sortKey) => {
        const { classList } = event.target;
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            this.setState({
                sortby: appConst.SORT_BY.DESCENDING
            });
            classList.remove('up');
        } else {
            this.setState({
                sortby: appConst.SORT_BY.ASCENDING
            });
            classList.add('up');
        }

        const data = this.state.payments;
        data.sort((a, b) => {

            if (this.state.sortby == 1) {
                return a[sortKey].toString().localeCompare(b[sortKey].toString());
            }
            return b[sortKey].toString().localeCompare(a[sortKey].toString());
        });
        this.setState({ payments: data });
    }

    print = (currentPayment) => {
        const data = {
            "id": currentPayment.order_id
        };
        this.setState({
            currentPayment
        });
        this.props.getDetail(data);
    }

    view = (currentPayment) => {
        const data = {
            "id": currentPayment.order_id
        };
        this.setState({
            currentPayment
        }, () => {
            this.props.getDetail(data);
        });

    }

    update = (currentPayment) => {
         
        const data = {
            "id": currentPayment.order_id
        };
        this.setState({
            currentPayment
        }, () => {
            this.props.getDetail(data);
        });

    }

    // getClass(status) {
    //     switch(status) {
    //         case appConst.PAYMENT_STATUS_OPTIONS.PAID_DEBIT.value:
    //             return 'ladiui-tr-highlight';
    //         case appConst.PAYMENT_STATUS_OPTIONS.INVALID.value:
    //             return 'ladiui-tr-warning';
    //         case appConst.PAYMENT_STATUS_OPTIONS.PAID.value:
    //             return 'ladiui-tr-done';
    //     } 
            
        
    // }

    /****************************************************************/


    render() {
        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchStatus, searchMethod, searchOrderNo, resetForm, fromDate, toDate } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.state.payments || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.state.payments.length - 1;
        }


        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.LIST_PAYMENT);
        return (
            <div>
                <h1 className="page-title">Thanh toán</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo mã đơn" name="searchOrderNo" value={searchOrderNo} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.getList} />
                            </div>
                        </div>
                        {/* <div className="action-item">
                       
                        </div> */}
                        <div className="action-item">

                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                        {/* <div className="action-item">
                        
                        </div> */}
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div>

                        {/* <div className="action-item">
                            <span className="title-date">Status</span>
                        </div> */}
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchStatus.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.PAYMENT_STATUS_OPTIONS, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchStatus(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="action-item">

                            <span className="title-date">Phương t</span>
                        </div> */}
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchMethod.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.PAYMENT_METHOD_OPTIONS, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchMethod(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>

                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th>
                                                {/* <th name="store_id" onClick={this.sort} className="text-left sorting">ID</th> */}
                                                <th name="reference_no" onClick={e => this.sort(e, 'order_reference_no')} className="text-left sorting">Mã đơn</th>
                                                <th name="method" onClick={e => this.sort(e, 'method')} className="text-left sorting">Phương thức</th>
                                                <th name="amount" onClick={e => this.sort(e, 'amount')} className="text-left sorting">Tổng tiền</th>
                                                <th className="text-left">Ghi chú</th>
                                                <th name="created_at" onClick={e => this.sort(e, 'created_at')} className="text-left sorting">Thời gian tạo</th>
                                                <th name="status" onClick={e => this.sort(e, 'status')} className="text-left sorting">Trạng thái</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.state.payments, payment => {

                                                    return <tr 
                                                        key={payment._id}
                                                        // className={this.getClass(payment.status)}
                                                        >
                                                        <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, payment._id)} className="ladiui checkbox" />
                                                        </td>
                                                        {/* <td>{store.store_id}</td> */}
                                                        <td>{payment.order_reference_no}</td>
                                                        <td>{payment.method}</td>
                                                        <td>{payment.amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                        <td>{payment.note}</td>
                                                        <td>{baseHelper.formatStrToDate(payment.created_at)}</td>
                                                        <td>{
                                                            appConst.PAYMENT_STATUS_OPTIONS[payment.status].name
                                                        }</td>
                                                        <td>
                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a>{
                                                                        (payment.status === appConst.PAYMENT_STATUS_OPTIONS.PAID_DEBIT.value) &&
                                                                    <i className="ladi-icon icon-dots"></i>
                                                                    }
                                                                    </a>
                                                                    <ul>
                                                                        {
                                                                            (payment.status === appConst.PAYMENT_STATUS_OPTIONS.PAID_DEBIT.value) &&
                                                                            // <button className="ladiui button primary open-modal" onClick={() => this.update(payment)} modal="modal-update-payment" >Xác nhận chuyển khoản</button>
                                                                            <li><a onClick={() => this.update(payment)}
                                                                                className="ladiui open-modal" modal="modal-update-payment">Xác nhận chuyển khoản</a></li>
                                                                        }
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </td>



                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                                totalRecord <= 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="100%">Không có bản ghi nào</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    {totalRecord > 0 &&
                        <div className="ladiui pagination-block">
                            <div className="ladiui search-actions">
                                <div className="ladiui form-filter">
                                    <div className="ladiui data-limit">
                                        <span>Show</span>
                                        <div className="ladiui per-page">
                                            <div className="ladiui-select-options">
                                                <span className="ladiui-select-init">{selectedLimit}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui showing">{msgShowItem}</div>
                                </div>
                            </div>
                            {
                                totalRecord > 0 && <div className="ladiui pagination">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={selectedLimit}
                                        totalItemsCount={totalRecord}
                                        pageRangeDisplayed={5}
                                        onChange={this.onPageChange}
                                        prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                        nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                        firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                        lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                    />
                                </div>
                            }

                        </div>
                    }
                </div>

                {/* <Detail
                    currentPayment={this.state.currentPayment}
                    mode={this.state.mode}
                />
                <Print
                    currentPayment={this.state.currentPayment}
                    mode={this.state.mode}
                /> */}
                <Update
                    currentPayment={this.state.currentPayment}

                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(paymentActions.list(data)),
        myPayments: () => dispatch(paymentActions.myPayments()),
        deleteOrders: (paymentIds) => dispatch(paymentActions.deleteOrders(paymentIds)),
        deleteOrder: (paymentId) => dispatch(paymentActions.deleteOrder(paymentId)),
        getDetail: (data) => dispatch(paymentActions.getDetail(data))
    }
}

const mapStateToProps = (state) => ({ ...state.payment });

export default connect(mapStateToProps, mapDispatchToProps)(Payment);