import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const listCreditLog = (data) => ({
    type: types.LIST_CREDIT_LOGS,
    meta: {
        endpoint: endpoint.LIST_CREDIT_LOGS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
 
const changeCredit = (data) => ({
    type: types.CHANGE_CREDIT,
    meta: {
        endpoint: endpoint.CHANGE_CREDIT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const createPartner = (data) => ({
    type: types.CREATE_PARTNER,
    meta: {
        endpoint: endpoint.CREATE_PARTNER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const reportCredit = (data) => ({
    type: types.REPORT_CREDIT_LOGS,
    meta: {
        endpoint: endpoint.REPORT_CREDIT_LOGS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const exportExcel = (data) => ({
    type: types.EXPORT_CREDIT_LOG,
    meta: {
        endpoint: endpoint.EXPORT_CREDIT_LOG,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
export default {
    listCreditLog,
    changeCredit,
    reportCredit,
    exportExcel,
    createPartner
}