import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import Input from '../../components/Input';

import * as types from '../../redux/futures/tools/types';




class ChangeEmail extends React.Component {
 
    constructor(props) {
        super(props);

        this.state = {
            user: { 

                emailOld: "",

                emailNew: "", 
            },
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: ''
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            
             
            if (nextProps.action == types.CHANGE_EMAIL) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    const _seft = this;
                    window.LadiUI.toastr('success', '', nextProps.message);
                    setTimeout(function () {
                        window.LadiUI.closeModal(_seft.ref.current);
                    },1000);
                    
                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            } 
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { user } = this.state;
        const jsonData = {
            "old_email": user.emailOld,
            "new_email": user.emailNew
        } 
        this.props.changeEmail(jsonData);
        
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }
 
 
    onChangeInput = (event) => { 
         
        this.setState({
            user: {
                ...this.state.user,
                [event.target.name]: event.target.value
            }
        });
    } 
 

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            user: baseHelper.resetState(this.state.user),
        });
        window.LadiUI.closeModal(this.ref.current);

        // window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
        //     e.classList.remove('error');
        // });
    }

    render() {
        const { user } = this.state;

         
        const title = 'Đổi email';

        return (
            <div ref={this.ref} id="modal-change-email" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-change-email"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-change-email"><i className="ladi-icon icon-e-remove" data-dismiss="modal-change-email" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email cũ</label> 
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="emailOld"
                                                validationName="Email"
                                                placeholder="Email"
                                                value={user.emailOld}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, email: true }}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email mới</label> 
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="emailNew"
                                                validationName="Email"
                                                placeholder="Email"
                                                value={user.emailNew}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, email: true }}
                                            /> 
                                        </div>
                                    </div>

                                </div> 
                                
                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-change-email">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-change-email" />Đóng
                        </button>
                        <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.submit}
                        disabled={this.state.loading}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i>  
                                    Áp dụng
                         </button>
                         
                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeEmail: (data) => dispatch(toolActions.changeEmail(data)) 
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);