import React from 'react';
import pointActions from '../../../redux/futures/point/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import * as types from '../../../redux/futures/point/types';
import Input from '../../../components/Input';

class ChangePoint extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                point: 0,

                status: "",

                created_at: "",

                _id: ""

            },
            note: '',
            point_change: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        }
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    // componentDidMount() {
    //     this.getDetail();
    // }

    componentDidUpdate() {
        window.LadiUI.init();
    }


    componentWillReceiveProps(nextProps) {


        if (this.props.currentUser !== nextProps.currentUser) {
            this.setState({
                currentUser: _.pick(nextProps.currentUser, _.keys(this.state.currentUser))
            })
        }
        
        if (nextProps.action == types.CHANGE_POINT) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                }, 1000);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        _.map(this.inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { currentUser, point_change, note } = this.state;
        const data = {
            ladi_uid: currentUser._id,
            amount: point_change,
            description: note
        };
        this.props.changePoint(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    onChangeInput = (event) => {

        this.setState({
            point_change: event.target.value
             
        });
    }

    onChangeTextArea = (event) => {

        this.setState({
            note:  event.target.value
            
        });
    }

    render() {
        const { currentUser } = this.state;
        const title = 'Add point: ' + currentUser.fullname;


        return (
            <div ref={this.ref} id="modal-user-addpoint" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-user-addpoint">
                <div className="ladiui-modal-content" style={{ width: '500px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-user-addpoint"><i className="ladi-icon icon-e-remove" data-dismiss="modal-user-addpoint" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {/* <div className="ladiui-tab-content"> */}
                        <div className="point-box" style={{ margin: '15px' }}>
                            {/* <ul> */}
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">
                                    <strong>Email: </strong>{this.state.currentUser.email}
                                </div>
                            </div>
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">
                                    <strong>Điểm tích luỹ: </strong>
                                    {
                                        this.state.currentUser.point && baseHelper.numberWithCommas(this.state.currentUser.point)
                                    }
                                </div>
                            </div>
                            <div className="ladiui-row" >

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Nhập số điểm</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="point_change"
                                        validationName="PointChange"
                                        placeholder="PointChange"
                                        value={this.state.point_change + ""}
                                        onChange={this.onChangeInput}
                                        type="number"
                                        onKeyPress={this.onKeyPress}
                                        validations={{ isRequired: true }}
                                        style={{ width: '440px' }}
                                    />
                                </div>

                            </div>
                            <div className="ladiui-row" >


                                <div className="ladiui form-group">
                                    <strong>Note: </strong>
                                    <textarea name="note" className="ladiui textarea ladi-payment-info"
                                        value={this.state.note} onChange={this.onChangeTextArea} />
                                </div>

                            </div>

                            


                        </div>
                        {/* </div> */}
                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-affilateform">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-affilateform" />Close
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Save
                         </button>

                    </div>
                </div>

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changePoint: (data) => dispatch(pointActions.changePoint(data))
    }
}

const mapStateToProps = (state) => ({ ...state.point });

export default connect(mapStateToProps, mapDispatchToProps)(ChangePoint);