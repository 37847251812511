import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const listReportDaily = (data) => ({
    type: types.LIST_REPORT_DAILY,
    meta: {
        endpoint: endpoint.LIST_REPORT_DAILY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
  
const listReportMonthly = (data) => ({
    type: types.LIST_REPORT_MONTHLY,
    meta: {
        endpoint: endpoint.LIST_REPORT_MONTHLY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listReportRevenue = (data) => ({
    type: types.LIST_REPORT_REVENUE,
    meta: {
        endpoint: endpoint.LIST_REPORT_REVENUE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
  
export default {
    listReportDaily,
    listReportMonthly,
    listReportRevenue
}