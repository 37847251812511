import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import Input from '../../components/Input';
import appConst from '../../config/const';

import * as types from '../../redux/futures/tools/types';




class OnOffSSL extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            actionObj: {
                url: "",
                minute: 30,
            },
            selectedAction: appConst.ACTION_BLOCK_STATUS.block,
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: ''
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {


            if (nextProps.action == types.BLOCK_URL) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    const _seft = this;
                    window.LadiUI.toastr('success', '', nextProps.message);
                    setTimeout(function () {
                        window.LadiUI.closeModal(_seft.ref.current);
                    }, 1000);

                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { actionObj, selectedAction } = this.state;
        const jsonData = {
            "url": actionObj.url,
            "minute": actionObj.minute,
            "is_block": selectedAction.value
        }
        this.props.blockUrl(jsonData);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }


    onChangeInput = (event) => {

        this.setState({
            actionObj: {
                ...this.state.actionObj,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeAction = (option) => {
        // console.log(option);
        this.setState({
            selectedAction: option,
        })
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            user: baseHelper.resetState(this.state.user),
        });
        window.LadiUI.closeModal(this.ref.current);

        // window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
        //     e.classList.remove('error');
        // });
    }

    render() {
        const { actionObj, selectedAction } = this.state;


        const title = 'Block url';

        return (
            <div ref={this.ref} id="modal-block-url" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-block-url"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-block-url"><i className="ladi-icon icon-e-remove" data-dismiss="modal-block-url" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body" >
                        {/* <form> */}
                        <div className="ladiui-tab-content" style={{ height: "160px" }}>

                            <div className="ladiui-row">
                                <div className="col-50">
                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">Url</label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="url"
                                            validationName="Url"
                                            placeholder="Url"
                                            value={actionObj.url}

                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                </div>
                                <div className="col-50">
                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">Hành động</label>
                                        <div className="ladiui-select-options">

                                            <span className="ladiui-select-init">{
                                                selectedAction.name
                                            }</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.ACTION_BLOCK_STATUS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeAction(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="ladiui-row">
                                {/* <div className="col-50"> */}
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Số phút block</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="minute"
                                        validationName="Minute"
                                        placeholder="Minute"
                                        type="number"
                                        value={actionObj.minute + ""}
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-block-url">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-block-url" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                        </button>

                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        blockUrl: (data) => dispatch(toolActions.blockUrl(data))
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(OnOffSSL);