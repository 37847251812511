import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }

        case types.AFFILATE_LIST: {
            return {
                ...state,
                action: types.AFFILATE_LIST,
                affilates: action.status ? action.payload.affilates : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_PAYMENT_INFO: {
            return {
                ...state,
                action: types.UPDATE_PAYMENT_INFO,
                affilateInfo: action.status ? action.payload.affilateInfo : { },
                status: action.status,
                message: action.message,
            }
        }
        case types.ACTIVE_AFFILATE: {
            return {
                ...state,
                action: types.ACTIVE_AFFILATE,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_AFFILATE: {
            return {
                ...state,
                action: types.UPDATE_AFFILATE,
                status: action.status,
                message: action.message,
            }
        }
        case types.MY_AFFILATE_INFO: {
            return {
                ...state,
                action: types.MY_AFFILATE_INFO,
                affilateInfo: action.status ? action.payload.affilateInfo : { },
                status: action.status,
                message: action.message,
            }
        }
        case types.CHECK_AFFILATE_CODE: {
            return {
                ...state,
                action: types.CHECK_AFFILATE_CODE,
                status: action.status,
                err_name: "code",
                message: action.message,
            }
        }
        case types.CHECK_AFFILATE_EMAIL: {
            return {
                ...state,
                action: types.CHECK_AFFILATE_EMAIL,
                err_name: "email",
                status: action.status,
                message: action.message,
            }
        }
        case types.GET_AFFILATE_INFO: {
            return {
                ...state,
                action: types.GET_AFFILATE_INFO, 
                affilateInfo: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

