import React from 'react';

import { connect } from 'react-redux';

import { withRouter, NavLink } from 'react-router-dom';
import authActions from '../../redux/futures/auth/actions';
import * as types from '../../redux/futures/auth/types';
import _ from 'lodash';
import Input from '../../components/Input';
// import i18n from '../../i18n';
import queryString from 'query-string';
import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';
// import LadiIframe from './LadiIframe';
// import PoweredBy from '../../components/PoweredBy';
// import LadiAppLogo from '../../components/LadiAppLogo';
// import LadiLanguage from '../../components/LadiLanguage';
class CompleteRegister extends React.Component {

    constructor(props) {
        super(props);
        const parsed = queryString.parse(this.props.location.search);
        this.state = {
            passwordObj: {
                oldpass: '',
                newpass: '',
                confirmpass: ''
            },
            typeInput: 'password',
            iconClass: 'icon-preview',
            classNormal: "ladiui button primary",
            classLoading: "ladiui button primary loader",
            classApply: "ladiui button primary",
            search: this.props.location.search
        }
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentDidMount() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action == types.COMPLETE_REGISTER) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                // let parsed = {};
                // if (this.state.search) {
                //     parsed = queryString.parse(this.props.location.search);
                // }
                // if (!parsed.callback_url) {
                // console.log(' redirect login ');
                // this.props.history.push('/signin');
                window.LadiUI.toastr('success', '', nextProps.message);
                const that = this;
                setTimeout(() => {
                    that.props.signOut();
                    that.props.history.push('/signin');
                }, 500);

                // }
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

    }

    onChangeInput = (event) => {

        this.setState({
            passwordObj: {
                ...this.state.passwordObj,
                [event.target.name]: event.target.value
            }
        });
    }


    signOut = () => {
        this.props.signOut();
    }
    completeReg = (event) => {
        event.preventDefault();
        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { passwordObj } = this.state;
        if (passwordObj.newpass != passwordObj.confirmpass) {
            window.LadiUI.toastr('error', '', 'Mật khẩu đã nhập không khớp với xác nhận mật khẩu');
            return;
        }
        const jsonData = {
            "old_password": passwordObj.oldpass,
            "new_password": passwordObj.newpass,
        }
        this.props.complete(jsonData);
    }

    showPassword = () => {
        const { typeInput } = this.state;
        if (typeInput == 'password') {
            this.setState({
                typeInput: 'text',
                iconClass: 'icon-b-preview'
            });
        }
        if (typeInput == 'text') {
            this.setState({
                typeInput: 'password',
                iconClass: 'icon-preview'
            });

        }
    }

    render() {
        const { passwordObj, typeInput, iconClass } = this.state;
        return (
            <div className="ladiui auth">
                <div className="ladiui-logo">
                    <img src="https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg" alt='Ladipage.vn' />
                </div>
                <div className="ladiui-login">
                    <h1 className="form-title">Đổi mật khẩu đăng nhập mặc định</h1>
                    <div className="ladiui-login-form">
                        <div className="ladiui-form">
                            <div className="ladiui form-group">
                                <label className="ladiui-label">Mật khẩu cũ</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="oldpass"
                                    validationName="OldPassword"
                                    placeholder="Mật khẩu cũ"
                                    value={passwordObj.oldpass}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true }}
                                    type={typeInput}
                                />
                                <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                            </div>
                            <div className="ladiui-form-group">

                                <label className="ladiui-label">Mật khẩu mới</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="newpass"
                                    validationName="NewPassword"
                                    placeholder="Mật khẩu mới"
                                    value={passwordObj.newpass}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true }}
                                    type={typeInput}
                                />
                                <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                                {/* <input name="email" type="text" className="ladiui input" placeholder="Your Email" value={email} onChange={this.handleChangeInput} onKeyDown={this._handleKeyDown} /> */}
                            </div>
                            <div className="ladiui-form-group">
                                <label className="ladiui-label">Xác nhận mật khẩu</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="confirmpass"
                                    validationName="ConfirmPassword"
                                    placeholder="Xác nhận mật khẩu"
                                    value={passwordObj.confirmpass}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: false }}
                                    type={typeInput}
                                />
                                <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                                {/* <input name="password" type="password" className="ladiui input" placeholder="Your password" value={password} onChange={this.handleChangeInput} onKeyDown={this._handleKeyDown} /> */}
                            </div>

                            <div className="ladiui-form-group">
                                <button
                                    ref={this.ref}
                                    className={this.state.classApply}
                                    onClick={this.completeReg}
                                    disabled={this.state.loading}
                                >
                                    <i className="" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                                    Đổi mật khẩu</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ladipage-terms">
                    <ul>
                        <li><a target='_blank' href={appConst.SUPPORT_URL.SUPPORT}>Trợ giúp</a></li>
                        <li><a target="_blank" href="https://docs.ladipage.vn/article/68-chinh-sach-bao-mat" >Chính sách bảo mật</a></li>

                    </ul>
                </div>
            </div>


        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        complete: (data) => dispatch(authActions.completeRegister(data)),
        signOut: () => dispatch(authActions.signOut())

    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompleteRegister));