import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import * as types from '../../../redux/futures/affilate/types';




class View extends React.Component {

    static propTypes = {
        affilateInfo: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            affilateInfo: {
                ladi_uid: "",

                email: "",

                code: "",

                payment_info: "", // thông tin thanh toán

                level: "",

                commission_1: "",

                commission_2: "",

                customer_commission: "",

                commission_tier_2: ""

            },
            commissions: [],
        };

    }


    componentDidMount() {
        window.LadiUI.init();
        // this.getAffilateInfo();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.affilateInfo && this.state.affilateInfo !== nextProps.affilateInfo) {
            this.setState({
                affilateInfo: nextProps.affilateInfo
            });
        }
        // console.log(nextProps.action, nextProps.affilateInfo);
        if (nextProps.action == types.MY_AFFILATE_INFO) {
            if (nextProps.status) {
                this.setState({
                    affilateInfo: nextProps.affilateInfo,

                });
            } else {
                this.setState({
                    affilateInfo: false,

                });
            }
            return;
        }
    } 

    getAffilateInfo = () => {
        this.props.getAffilateInfo();
    }

    render() {
        const { affilateInfo } = this.state;


        return (
            <div id="modal-affilateview" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-affilateview">
                <div className="ladiui-modal-content" style={{ maxWidth: '1200px' }}>
                <div className="ladiui-modal-body">
                        
                            <div className="order-box"  style={{width:'970px',margin:'15px'}}>
                                    <div className="ladiui-row" style={{height:'50px'}}>
                                        <div className="col-50">
                                            <strong>Email: </strong>
                                            {
                                                affilateInfo.email  
                                            }
                                        </div>
                                        <div className="col-50">
                                            <strong>Code: </strong>
                                            {
                                                affilateInfo.code
                                            }
                                        </div>
                                    </div>
                                    <div className="ladiui-row" style={{height:'50px'}}>
                                        <div className="col-50">
                                            <strong>PaymentInfo: </strong>{affilateInfo.payment_info}
                                        </div>
                                        <div className="col-50">
                                            <strong>Level: </strong>{affilateInfo.level}
                                        </div>
                                    </div> 
                                    <div className="ladiui-row" style={{height:'50px'}}>
                                        <div className="col-50">
                                            <strong>Commission 1: </strong>{affilateInfo.commission_1}
                                        </div>
                                        <div className="col-50">
                                            <strong>Commission 2: </strong>{affilateInfo.commission_2}
                                        </div>
                                        
                                    </div>
                               
                            </div>
                        
                    </div>
                    
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = () => {
    return {
        // update: (affilateInfo) => dispatch(affilateActions.updateUserInfo(affilateInfo)),
        // getAffilateInfo: () => dispatch(affilateActions.myAffilateInfo())
    }
}

const mapStateToProps = (state) => ({ ...state.affilate });

export default connect(mapStateToProps, mapDispatchToProps)(View);