import React from 'react';
import PropTypes from 'prop-types';
import userActions from '../../../redux/futures/user/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';




class View extends React.Component {

    static propTypes = {
        currentUser: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                status: "",

                point:"",

                created_at:"",

                _id: ""

            },

        };

    }


    componentDidMount() {
        window.LadiUI.init();

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.currentUser && this.state.currentUser !== nextProps.currentUser) {
            this.setState({
                currentUser: nextProps.currentUser
            });
        }
        // if (nextProps.action == types.MY_AFFILATE_INFO) {

        //     if (nextProps.status) {
        //         this.setState({
        //             affilateInfo: nextProps.affilateInfo
        //         });
        //         // window.LadiUI.closeModal(this.ref.current);
        //         window.LadiUI.toastr('success', '', nextProps.message);
        //     } else {
        //         window.LadiUI.toastr('error', '', nextProps.message);
        //     }
        // }
        // console.log(nextProps.action, types.MY_AFFILATE_INFO, nextProps.affilateInfo);
        // if (nextProps.action == types.MY_AFFILATE_INFO) {
        //     if (nextProps.status) {
        //         this.setState({
        //             affilateInfo: nextProps.affilateInfo,

        //         });
        //     } else {
        //         this.setState({
        //             affilateInfo: false,

        //         });
        //     }
        //     return;
        // }
    }



    render() {
        const { currentUser } = this.state;
        const packages = (currentUser && currentUser.packages) ? JSON.parse(currentUser.packages) : {};
     
        return (
            <div id="modal-userview" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-userview">
                <div className="ladiui-modal-content" style={{ maxWidth: '1000px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-userview"><i className="ladi-icon icon-e-remove" data-dismiss="modal-userview" /></button>
                        <h3>User Infomation</h3>
                    </div>
                    <div className="ladiui-modal-body">

                        <div className="order-box" style={{ width: '970px', margin: '15px' }}>
                            <div className="ladiui-row" style={{ height: '50px' }}>

                                <div className="col-50">
                                    <strong>Ladi UID: </strong>
                                    {
                                        currentUser._id
                                    }
                                </div>


                                <div className="col-50">
                                    <strong>Point: </strong>
                                    {
                                        currentUser.point
                                    }
                                </div>
                            </div>
                            <div className="ladiui-row" style={{ height: '50px' }}>

                                <div className="col-50">
                                    <strong>Name: </strong>
                                    {
                                        currentUser.fullname
                                    }
                                </div>


                                <div className="col-50">
                                    <strong>Email: </strong>
                                    {
                                        currentUser.email
                                    }
                                </div>
                            </div>
                            <div className="ladiui-row" style={{ height: '50px' }}>
                                <div className="col-50">
                                    <strong>Create Time: </strong>{baseHelper.formatStrToDate(currentUser.created_at)}
                                </div>
                                <div className="col-50">
                                    <strong>Last login: </strong>{baseHelper.formatStrToDate(currentUser.last_login)}
                                </div>
                            </div>
                            {this.props.loading && <Spinner />}
                        </div>

                    </div>
                    
                    <table className="ladiui table">
                        <thead>
                            <tr>
                                <th className="text-left">Package Type</th>
                                <th className="text-left">Package Name</th>
                                <th className="text-left">Active Date</th>

                                <th className="text-left">Exprire Date</th>
                                 
                              
                            </tr>
                        </thead>
                        <tbody>
                         {
                            _.map(packages, (value, key) => {
                                return <tr key={value.id}>
                                    <td>{appConst.APP[key]}</td>
                                    <td>{value.pkg_name}</td>
                                    <td>{baseHelper.formatStrToDate(value.active_date)}</td>
                                    <td>{baseHelper.formatStrToDate(value.exp)}</td>
                                    
                                </tr>
                            }
                            )
                        }
                        </tbody>
                    </table>

                </div>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (affilateInfo) => dispatch(userActions.updateUserInfo(affilateInfo)),
        getAffilateInfo: () => dispatch(userActions.myAffilateInfo())
    }
}

const mapStateToProps = (state) => ({ ...state.affilate });

export default connect(mapStateToProps, mapDispatchToProps)(View);