import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const signIn = (data) => ({
    type: types.SIGNIN,
    meta: {
        endpoint: endpoint.ADMIN_SIGNIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: false,
    }
});


const signOut = () => ({
    type: types.SIGNOUT,
    meta: {
        endpoint: endpoint.SIGNOUT,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const getAdminInfo = () => ({
    type: types.GET_ADMIN_INFO,
    meta: {
        endpoint: endpoint.GET_ADMIN_INFO,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const updateAdminInfo = (formdata) => ({
    type: types.UPDATE_ADMIN_INFO,
    meta: {
        endpoint: endpoint.UPDATE_ADMIN_INFO,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
        form: formdata
    }
});

const changePassword = (body) => ({
    type: types.CHANGE_PASSWORD,
    meta: {
        endpoint: endpoint.CHANGE_PASSWORD,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: true
    }
});

const forgottenPassword = (body) => ({
    type: types.FORGOTTEN_PASSWORD,
    meta: {
        endpoint: endpoint.FORGOTTEN_PASSWORD,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: false
    }
});

const resetPassword = (body) => ({
    type: types.RESET_PASSWORD,
    meta: {
        endpoint: endpoint.RESET_PASSWORD,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: false
    }
});

const getUrlFacebook = (refObject) => ({
    type: types.GET_URL_FACEBOOK,
    meta: {
        endpoint: endpoint.GET_URL_FACEBOOK,
        method: REQUEST_METHOD.POST,
        body: refObject,
        hasAuth: false
    }
});

const oauth2Facebook = (callbackObj) => ({
    type: types.OAUTH2_FACEBOOK,
    meta: {
        endpoint: endpoint.OAUTH2_FACEBOOK,
        method: REQUEST_METHOD.POST,
        body: callbackObj,
        hasAuth: false
    }
});

const getUrlGoole = (refObject) => ({
    type: types.GET_URL_GOOGLE,
    meta: {
        endpoint: endpoint.GET_URL_GOOGLE,
        method: REQUEST_METHOD.POST,
        body: refObject,
        hasAuth: false
    }
});

const oauth2Goole = (callbackObj) => ({
    type: types.OAUTH2_GOOGLE,
    meta: {
        endpoint: endpoint.OAUTH2_GOOGLE,
        method: REQUEST_METHOD.POST,
        body: callbackObj,
        hasAuth: false
    }
});


const completeRegister = (body) => ({
    type: types.COMPLETE_REGISTER,
    meta: {
        endpoint: endpoint.COMPLETE_REGISTER,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: true
    }
});

export default {
    signIn,
    signOut,
    getAdminInfo,
    updateAdminInfo,
    changePassword,
    forgottenPassword,
    resetPassword,
    getUrlFacebook,
    getUrlGoole,
    oauth2Facebook,
    oauth2Goole,
    completeRegister
}