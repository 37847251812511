import React from 'react';
import '../../../assets/css/style.css';
import '../../../assets/css/custom.css';

import SideBar from './Sidebar';
import Main from './mains/Main';

export default class Layout extends React.Component {
    render() {
        return (
            <div className="ladiui fixed-sidebar fixed-topbar">
                <section className="ladiui-wrapper">
                    <SideBar />
                    <Main />
                </section>
            </div>
        )
    }
}