import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';

import * as types from '../../redux/futures/tools/types';




class CheckDomainSSL extends React.Component {
 
    constructor(props) {
        super(props);

        this.state = {
            domain:'',
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: '',
            domainssl: {}
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.initialState = _.cloneDeep(this.state);
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            
             
            if (nextProps.action == types.CHECK_DOMAIN_SSL) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });  
                if (nextProps.status) {
                    window.LadiUI.toastr('success', '', nextProps.message);
                    if (nextProps.result && nextProps.result.domainSsl) {
                        this.setState({
                            domainssl: nextProps.result.domainSsl
                        })
                    }
                    // setTimeout(function () {
                    //     window.LadiUI.closeModal(_seft.ref.current);
                    // },1000);
                    
                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            } 
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { domain } = this.state;
        const jsonData = {
            domain
        } 
        this.props.checkDomainSsl(jsonData);
        
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }
 
 
    onChangeInput = (event) => { 
         
        this.setState({
            domain: event.target.value
        });
    } 
 

    resetForm = (event) => {
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
        
    }

    render() {
        const { domain, domainssl } = this.state;

         
        const title = 'Kiểm tra tên miền';

        return (
            <div ref={this.ref} id="modal-check-domain-ssl" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-check-domain-ssl">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-check-domain-ssl"><i className="ladi-icon icon-e-remove" data-dismiss="modal-check-domain-ssl" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {/* <form> */}
                            <div className="ladiui-tab-content">
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Tên miền</label> 
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="domain"
                                        validationName="Domain"
                                        placeholder="Tên miền"
                                        value={domain}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}
                                    /> 
                                       
                                </div> 
                                
                            </div>
                        {/* </form> */}
                        {
                            domainssl.domain && 
                            <div >
                                <b>Domain:</b> {domainssl.domain}
                                <br/>
                                <b>Status:</b> {domainssl.status}
                                <br/>
                                <b>Exprire Date:</b> {domainssl.exprire_at} 
                                <br/>
                                <b>Output:</b> {domainssl.last_output}
                                <br/>
                                <b>Created_at:</b> {domainssl.created_at}
                                <br/>
                                <b>Updated_at:</b> {domainssl.updated_at}
                            </div> 
                        }

                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-check-domain-ssl">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-check-domain-ssl" />Đóng
                        </button>
                        <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.submit}
                        disabled={this.state.loading}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i>  
                                    Check thông tin SSL
                         </button>
                         
                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkDomainSsl: (data) => dispatch(toolActions.checkDomainSsl(data)) 
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(CheckDomainSSL);