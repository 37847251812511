import React from 'react';
import paymentActions from '../../../redux/futures/payment/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';

class Detail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            payment: {
                ladi_uid: '',
                order_id: '',
                order_reference_no: '',
                method: '',
                gate_way: '',
                amount: '',
                note: '',
                status: '',
                transaction_id: '',
                admin_id: ''
            },
        }

        this.ref = React.createRef();

        this.inputsRef = [];
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.currentPayment !== nextProps.currentPayment) {
            this.setState({
                payment: _.pick(nextProps.currentPayment, _.keys(this.state.payment))
            })
        }

        
    }

    getDetail = () => {
        const data = {
            "id": this.state.payment.order_id
        };
        this.props.list(data);
    }

    render() {
        const title = 'Payment Detail';

        return (
            <div id="modal-detail" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-detail">
                <div className="ladiui-modal-content">
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-store"><i className="ladi-icon icon-e-remove" data-dismiss="modal-store" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <div className="ladiui-tab-content">
                            <div className="payment-box" >
                                <ul>
                                    {/* <li><strong>ladi_uid: </strong>{this.state.ladi_uid}</li> */}
                                    {/* <li><strong>order_id: </strong>{this.state.order_id}</li> */}
                                    <li><strong>Order No: </strong>{this.state.payment.order_reference_no}</li>
                                    <li><strong>Method: </strong>{this.state.payment.method}</li>
                                    <li><strong>Gate Way: </strong>{this.state.payment.gate_way}</li>
                                    <li><strong>Amount: </strong>{this.state.payment.amount}</li>
                                    <li><strong>Note: </strong>{this.state.payment.note}</li>
                                    <li><strong>Status: </strong>{this.state.payment.status}</li>
                                    <li><strong>Transaction Id: </strong>{this.state.payment.transaction_id}</li>
                                    {/* <li><strong>admin_id: ''</strong>{this.state.admin_id}</li> */}

                                </ul>
                                {this.props.loading && <Spinner />}
                            </div>
                            </div>
                        </div>
                        <table className="ladiui table">
                            <thead>
                                <tr>
                                    
                                    <th className="text-left">Product Name</th>
                                    <th className="text-right">Price</th>
                                    <th className="text-center">Quantity</th>
                                    <th className="text-right">Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>MacBook Pro 15” (Mid 2018)</td>
                                    <td className="text-right">$2500</td>
                                    <td className="text-center">1</td>
                                    <td className="text-right">$2500</td>
                                    <td>
                                        <a href="">
                                            <i className="ladi-icon icon-bin"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                        </table>
                         
                    <div className="ladiui-modal-footer">

                        {this.props.loading && <Spinner />}
                    </div>
                </div>
            
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDetail: (payment) => dispatch(paymentActions.getDetail(payment))
    }
}

const mapStateToProps = (state) => ({ ...state.payment });

export default connect(mapStateToProps, mapDispatchToProps)(Detail);