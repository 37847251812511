import React from 'react';
import orderActions from '../../../redux/futures/order/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import * as types from '../../../redux/futures/order/types';

class Cancel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            order: {
                ladi_uid: '',
                _id: '',
                reference_no: '',
                discount: '',
                total: '', 
                note: '',
                status: '' 
            },
            note:'',
            details: [],
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        }
        this.ref = React.createRef();
    }

    // componentDidMount() {
    //     this.getDetail();
    // }

    componentDidUpdate() {
        window.LadiUI.init();
    }


    componentWillReceiveProps(nextProps) {
        

        if (this.props.currentPayment !== nextProps.currentPayment) {
            this.setState({
                order: _.pick(nextProps.currentPayment, _.keys(this.state.order))
            })
        }


        if (_.includes([types.DETAIL_ORDER], nextProps.action)) {
            if (nextProps.status) {

                this.setState({
                    details: nextProps.details,
                });
            } else {
            }
        }

        if (nextProps.action == types.CANCEL_ORDER) { 
            this.setState({
                loading : false,
                classApply : this.state.classNormal
            });    
            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                },1000);
                
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        _.map(this.inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { order } = this.state;
        
        
        const jsonData = {
            "id": order._id,
            "note": order.note,
            "status": appConst.PAYMENT_STATUS_OPTIONS.CANCEL.value
        } 
       
        this.props.cancel(jsonData);
        
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    onChangeTextArea = (event) => {
        
        this.setState({
            order: {
               ...this.state.order,
               [event.target.name]: event.target.value
           }
       });
    } 

    render() {
        const { details } = this.state;
        const title = 'Huỷ đơn hàng :' + this.state.order._id;

        return (
            <div id="modal-cancel" ref={this.ref} className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-cancel">
                <div className="ladiui-modal-content" style={{ maxWidth: '1000px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-cancel"><i className="ladi-icon icon-e-remove" data-dismiss="modal-cancel" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {/* <div className="ladiui-tab-content"> */}
                            <div className="order-box"  style={{width:'970px',margin:'15px'}}>
                                {/* <ul> */}
                                    <div className="ladiui-row" style={{height:'50px'}}>
                                        <div className="col-50">
                                            <div className="ladiui form-group">
                                                <strong>Lí do: </strong>
                                                <textarea name="note" className="ladiui textarea ladi-payment-info"
                                                value={this.state.order.note} onChange={this.onChangeTextArea}  />
                                            </div>
                                        </div>
                                        <div className="col-50">
                                            <div className="ladiui form-group">
                                                <strong>Mã đơn: </strong>{this.state.order.reference_no}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="ladiui-row" style={{height:'50px'}}>
                                        <div className="col-50">
                                            <div className="ladiui form-group">
                                                <strong>Trạng thái: </strong>{this.state.order.status}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui-row" style={{height:'50px'}}>
                                        
                                        <div className="col-50">
                                            <div className="ladiui form-group">
                                                <strong>Tổng tiền: </strong>
                                                {
                                                    this.state.order.total && this.state.order.total.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui-row" style={{height:'50px'}}>
                                        <div className="col-50">
                                            <div className="ladiui form-group">
                                                <strong>Giảm giá: </strong>
                                                {
                                                    this.state.order.discount
                                                }
                                            </div>
                                            
                                        </div>
                                    </div>
                                      
                                     
                                    {/* <li><strong>admin_id: ''</strong>{this.state.admin_id}</li> */}

                                {/* </ul> */}
                                {/* {this.props.loading && <Spinner />} */}
                            </div>
                        {/* </div> */}
                    </div>
                    <table className="ladiui table">
                        <thead>
                            <tr>
                                <th className="text-left">App</th>
                                <th className="text-left">Tên gói</th>
                                <th className="text-right">Tháng</th>
                                <th className="text-center">Loại KM</th>
                                <th className="text-center">Giá trị KM</th>
                                <th className="text-right">Tổng tiền</th>
                                <th className="text-right">Ngày tạo</th>
                                <th className="text-center">Loại giảm giá</th>
                                <th className="text-center">Giá trị giảm giá</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(details, detail => {
                                    return <tr key={detail._id}>
                                        <td>{appConst.APP[detail.pkg_type]}</td>
                                        <td>{detail.pkg_name}</td>
                                        <td>{detail.pkg_month}</td>
                                        <td>{detail.pkg_commission_type}</td>
                                        <td>{detail.pkg_commission_value}</td>
                                        <td>{baseHelper.formatNumber(detail.subtotal)} VND</td>
                                        <td>{baseHelper.formatStrToDate(detail.created_at)}</td>
                                        <td>{detail.discount_type}</td>
                                        <td>{detail.discount_value}</td>
                                    </tr>
                                }
                                )
                            }
                        </tbody>
                    </table>
 
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-affilateform">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-cancel" />Đóng
                        </button>
                        <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.submit}
                        disabled={this.state.loading}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i>  
                            Áp dụng
                         </button>
                        
                    </div>
                </div>

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cancel: (order) => dispatch(orderActions.cancel(order))
    }
}

const mapStateToProps = (state) => ({ ...state.order });

export default connect(mapStateToProps, mapDispatchToProps)(Cancel);