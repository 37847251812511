import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import baseHelper from '../../helpers/BaseHelper';
import moment from 'moment';

import { connect } from 'react-redux';
import reportActions from '../../redux/futures/report/actions';
import _ from 'lodash';

import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import chartConfig from '../../config/chartconfig';


import * as types from '../../redux/futures/report/types';


class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            fromDateRev: firstDay,
            toDateRev: new Date(),
            fromDate: firstDay,
            toDate: new Date(),
            fromMonth: firstDay,
            toMonth: new Date(),

            revenueMonth: new Date(),

            records: [],
            optionsList: [],
            monthList: [],
            revenueList:[],
            revenueObject: {
                A: 0,
                B1: 0,
                B2: 0,
                userPaid: 0,
                oldPaid: 0,
            }

        }

        this.muteActions = [];
    }


    componentDidMount() {
        this.getList();
        this.getReportMonth();
        this.getReportRevenue();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && nextProps.action === types.LIST_REPORT_DAILY) {
            if (nextProps.status) {
                this.processData(nextProps.records);
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

        }
        if (nextProps.action && nextProps.action === types.LIST_REPORT_MONTHLY) {
            if (nextProps.status) {
                this.processDataMonthly(nextProps.records);
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

        }
        if (nextProps.action && nextProps.action === types.LIST_REPORT_REVENUE) {
            if (nextProps.status) {
                this.processDataRevenue(nextProps.records);
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

        }
    }

    getList = () => {


        let { fromDate, toDate } = this.state;
        const data = {
            "from_date": fromDate,
            "to_date": toDate
        };

        this.props.list(data);
    }

    getReportMonth = () => {
        let { fromMonth, toMonth } = this.state;
        const data = {
            "from_month": fromMonth,
            "to_month": toMonth
        };
        this.props.getMonthly(data);
    }

    getReportRevenue = () => {
        let { fromDateRev, toDateRev } = this.state;
        const data = {
            from_date: moment(fromDateRev).format('YYYY-MM-DD'),
            to_date: moment(toDateRev).format('YYYY-MM-DD')

        };
        this.props.getRevenue(data);
    }

    processDataRevenue = (records) => {
        // console.log(' Rev = ', records);
        const optionsList = [];
        let { fromDateRev, toDateRev, revenueObject } = this.state;
        revenueObject.B1 = 0;
        revenueObject.B2 = 0;
        revenueObject.A = 0;
        const listDate = baseHelper.getDates(fromDateRev, toDateRev);
        const allSeries = {
            totalRevenue: {
                name: 'Doanh thu (A+B)',
                color: '#f4c242',
                data: []
            },
            A: {
                name: 'Không có aff (A)',
                color: '#caf441',
                data: []
            },
            B: {
                name: 'Có aff (B = B1+B2)',
                color: '#c63566',
                data: []
            },
            B1: {
                name: 'Partner (B1)',
                color: '#41f4b2',
                data: []
            },
            B2: {
                name: 'Ko co partner (B2)',
                color: '#41d3f4',
                data: []
            },
        };
        const xAxis = [];
       
        let items = records.listItem;
        _.map(items, (objVal, date) => {
            xAxis.push(date);
            allSeries.B1.data.push(objVal.B1);
            allSeries.B2.data.push(objVal.B2);
            allSeries.B.data.push(objVal.B1 + objVal.B2);
            allSeries.A.data.push(objVal.A);
            allSeries.totalRevenue.data.push(objVal.A + objVal.B1 + objVal.B2);

            revenueObject.B1 += objVal.B1;
            revenueObject.B2 += objVal.B2;
            revenueObject.A += objVal.A;
        });

        const userSerie = [];
        userSerie.push(allSeries.totalRevenue);
        userSerie.push(allSeries.A);
        userSerie.push(allSeries.B);
        userSerie.push(allSeries.B1);
        userSerie.push(allSeries.B2);
      
        const optUser = _.cloneDeep(chartConfig.options_line);
        optUser.title = 'Total Revenue Report';
        optUser.series = userSerie;
        optUser.xAxis.categories = xAxis;

        optionsList.push(optUser);
        revenueObject.userPaid = records.userPaid;
        revenueObject.oldPaid = records.oldPaid;

        this.setState({
            revenueList: optionsList,
            revenueObject
        });
    }

    processDataMonthly = (records) => {
        const optionsList = [];

        const allSeries = {
            newUser: {
                name: 'New User',
                color: '#f4c242',
                data: []
            },
            newUserPurchased: {
                name: 'New User Purchased',
                color: '#caf441',
                data: []
            },
            affilateCount: {
                name: 'Affiliate Count',
                color: '#c63566',
                data: []
            },
            referralCount: {
                name: 'Referral Count',
                color: '#41f4b2',
                data: []
            },
            orderCreated: {
                name: 'Order Create',
                color: '#41d3f4',
                data: []
            },
            orderCancel: {
                name: 'Order Cancel',
                color: '#4179f4',
                data: []
            },

            orderAmount: {
                name: 'Order Value',
                color: '#c63566',
                data: []
            },
            affilateTotalAmount: {
                name: 'Commission Value',
                color: '#2A31E8',
                data: []
            }

        };
        const xAxis = [];
        for (let i = 0; i < records.length; i++) {
            const record = records[i];
            xAxis.push(record.month);

            _.map(record, (value, key) => {

                if (allSeries[key]) {
                    allSeries[key].data.push(value);
                }

            });
        }

        const userSerie = [];
        userSerie.push(allSeries.newUser);
        userSerie.push(allSeries.newUserPurchased);
        // userSerie.push(allSeries.affilateCount);
        // userSerie.push(allSeries.referralCount);
        // userSerie.push(allSeries.orderCreated);
        // userSerie.push(allSeries.orderCancel);

        const optUser = _.cloneDeep(chartConfig.option_column);
        optUser.title = 'Common Report';
        optUser.series = userSerie;
        optUser.xAxis.categories = xAxis;
        optionsList.push(optUser);

        const amountSerie = [];
        amountSerie.push(allSeries.orderAmount);
        amountSerie.push(allSeries.affilateTotalAmount);

        const optAmount = _.cloneDeep(chartConfig.option_column);
        optAmount.title = 'Amount Report';
        optAmount.series = amountSerie;
        optAmount.xAxis.categories = xAxis;

        optionsList.push(optAmount);

        // console.log(optionsList);
        // console.log(chartConfig.option_column)

        this.setState({
            monthList: optionsList
        });
    }

    processData = (records) => {
        // console.log("processData ", records.length)
        const optionsList = [];

        const allSeries = {
            newUser: {
                name: 'New User',
                color: '#f4c242',
                data: []
            },
            newUserPurchased: {
                name: 'New User Purchased',
                color: '#caf441',
                data: []
            },
            affilateCount: {
                name: 'Affiliate Count',
                color: '#c63566',
                data: []
            },
            referralCount: {
                name: 'Referral Count',
                color: '#41f4b2',
                data: []
            },
            orderCreated: {
                name: 'Order Create',
                color: '#41d3f4',
                data: []
            },
            orderCancel: {
                name: 'Order Cancel',
                color: '#4179f4',
                data: []
            },

            orderAmount: {
                name: 'Order Value',
                color: '#c63566',
                data: []
            },
            affilateTotalAmount: {
                name: 'Commission Value',
                color: '#2A31E8',
                data: []
            }
        };
        const xAxis = [];
        for (let i = 0; i < records.length; i++) {
            const record = records[i];
            xAxis.push(records.month);

            _.map(record, (value, key) => {

                if (allSeries[key]) {
                    allSeries[key].data.push(value);
                }

            });
        }

        const userSerie = [];
        userSerie.push(allSeries.newUser);
        userSerie.push(allSeries.newUserPurchased);
        userSerie.push(allSeries.affilateCount);
        userSerie.push(allSeries.referralCount);
        userSerie.push(allSeries.orderCreated);
        userSerie.push(allSeries.orderCancel);

        const optUser = _.cloneDeep(chartConfig.options_line);
        optUser.title = 'Common Report';
        optUser.series = userSerie;
        optUser.xAxis.categories = xAxis;
        optionsList.push(optUser);

        const amountSerie = [];
        amountSerie.push(allSeries.orderAmount);
        amountSerie.push(allSeries.affilateTotalAmount);

        const optAmount = _.cloneDeep(chartConfig.options_line);
        optAmount.title = 'Amount Report';
        optAmount.series = amountSerie;
        optAmount.xAxis.categories = xAxis;

        optionsList.push(optAmount);



        this.setState({
            optionsList

        });
    }
    changeFromdateRev = (date) => {

        this.setState({
            fromDateRev: date
        }, () => {
            this.getReportRevenue();
        });

    }

    changeTodateRev = (date) => {
        this.setState({
            toDateRev: date
        }, () => {
            this.getReportRevenue();
        });

    }
    changeFromdate = (date) => {

        this.setState({
            fromDate: date
        }, () => {
            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }
    changeFromMonth = (month) => {

        this.setState({
            fromMonth: month
        }, () => {
            this.getReportMonth();
        });

    }

    changeToMonth = (month) => {
        this.setState({
            toMonth: month
        }, () => {
            this.getReportMonth();
        });

    }

    changeRevenueMonth = (month) => {

        this.setState({
            revenueMonth: month
        }, () => {
            this.getReportRevenue();
        });

    }

    render() {

        const { fromDateRev, toDateRev, fromDate, toDate, fromMonth, toMonth, revenueMonth ,revenueList, revenueObject } = this.state;


        return (
            <div>
                <h1 className="page-title">Revenue By Month</h1>
                <div className="ladiui-table-actions">
                    <div className="action-item">

                        <CustomDatePicker
                            customClass="ladiui input"
                            selected={fromDateRev}
                            onSelect={this.handleSelectRev} //when day is clicked
                            onChange={this.changeFromdateRev} //only when value has changed
                        />
                    </div>

                    <div className="action-item">
                        <CustomDatePicker
                            customClass="ladiui input"
                            selected={toDateRev}
                            onSelect={this.handleSelectRev} //when day is clicked
                            onChange={this.changeTodateRev} //only when value has changed
                        />
                    </div>
                </div>
               
                 <div className="ladiui-row flex">
                    <div className="ladiui-report-revenue">
                        <p>Doanh thu = A + B = {baseHelper.numberWithCommas(revenueObject.A + revenueObject.B1 + revenueObject.B2)}
                        </p>
                        <p>Không có aff : {baseHelper.numberWithCommas(revenueObject.A)} (A)</p>
                        <p>Có aff : {baseHelper.numberWithCommas(revenueObject.B1 + revenueObject.B2)} (B =  B1 + B2)</p>
                        <p>Partner = {baseHelper.numberWithCommas(revenueObject.B1)} (B1)</p>
                        <p>Ko co partner = {baseHelper.numberWithCommas(revenueObject.B2)} (B2)</p>
                        <p>------------------------------------</p>
                        <p>Số người trả tiền trong tháng ( bảng order status =PAID) : {baseHelper.numberWithCommas(revenueObject.userPaid)}</p>
                        <p>Số người gia hạn  :  {baseHelper.numberWithCommas(revenueObject.oldPaid)}</p>
                        <p>Số người kich mới = {baseHelper.numberWithCommas(revenueObject.userPaid - revenueObject.oldPaid)}</p>
                    </div>
                </div>
                <div className="ladiui-row flex">
                    {
                        _.map(revenueList, option => {

                            return <div className="ladiui card col" key={"LINE" + option.title}>
                                <div className="card-header">
                                    <h3><i className="ladi-icon icon-orders"></i> {option.title}</h3>
                                </div>
                                <div className="card-body">
                                    <div className="ls-dashboard-chart">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={option}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        )
                    }
                </div>
                <h1 className="page-title">Report Daily</h1>
                <div className="ladiui-table-actions">
                    <div className="action-item">

                        <CustomDatePicker
                            customClass="ladiui input"
                            selected={fromDate}
                            onSelect={this.handleSelect} //when day is clicked
                            onChange={this.changeFromdate} //only when value has changed
                        />
                    </div>

                    <div className="action-item">
                        <CustomDatePicker
                            customClass="ladiui input"
                            selected={toDate}
                            onSelect={this.handleSelect} //when day is clicked
                            onChange={this.changeTodate} //only when value has changed
                        />
                    </div>
                </div>


               
                <div className="ladiui-row flex">
                    {
                        _.map(this.state.optionsList, option => {

                            return <div className="ladiui card col" key={"LINE" + option.title}>
                                <div className="card-header">
                                    <h3><i className="ladi-icon icon-orders"></i> {option.title}</h3>
                                </div>
                                <div className="card-body">
                                    <div className="ls-dashboard-chart">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={option}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        )
                    }
                </div>

                <h1 className="page-title">Report Monthly</h1>
                <div className="ladiui-table-actions">
                    <div className="action-item">

                        <CustomDatePicker
                            // dateFormat="MM/yyyy"
                            showMonthYearPicker
                            customClass="ladiui input"
                            selected={fromMonth}
                            onSelect={this.handleSelect} //when day is clicked
                            onChange={this.changeFromMonth} //only when value has changed
                        />
                    </div>

                    <div className="action-item">
                        <CustomDatePicker
                            // dateFormat="MM/yyyy"
                            showMonthYearPicker

                            customClass="ladiui input"
                            selected={toMonth}
                            onSelect={this.handleSelect} //when day is clicked
                            onChange={this.changeToMonth} //only when value has changed
                        />
                    </div>
                </div>
                <div className="ladiui-row flex">
                    {
                        _.map(this.state.monthList, option => {

                            return <div className="ladiui card col" key={"COLUMN" + option.title}>
                                <div className="card-header">
                                    <h3><i className="ladi-icon icon-orders"></i>Revenue by month</h3>
                                </div>
                                <div className="card-body">
                                    <div className="ls-dashboard-chart">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={option}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        )
                    }
                    {/* <div className="ladiui card col">
                        <div className="card-header">
                            <h3><i className="ladi-icon icon-orders"></i>Revenue by month</h3>
                        </div>
                        <div className="card-body">
                            <div className="ls-dashboard-chart">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={chartConfig.option_column}
                                />
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(reportActions.listReportDaily(data)),
        getMonthly: (data) => dispatch(reportActions.listReportMonthly(data)),
        getRevenue: (data) => dispatch(reportActions.listReportRevenue(data))
    }
}

const mapStateToProps = (state) => ({ ...state.report });

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);