import React from 'react';


import { connect } from 'react-redux';
import adminActions from '../../redux/futures/admin/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/admin/types';

import Form from './components/Form';
import View from './components/View';
import ChangePoint from './components/ChangePoint';
import LoadingTable from '../../components/LoadingTable';


class Admins extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            // searchStatus: {
            //     name: appConst.admin_STATUS.ACTIVE.name,
            //     value: appConst.admin_STATUS.ACTIVE.value,
            //     selected: appConst.admin_STATUS.ACTIVE,
            // },
            fullname: "",
            email: "",
            fromDate: firstDay,
            toDate: new Date(),
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            adminLists: [],
            mode: appConst.FORM_MODE.CREATE,
            currentAdmin: {}
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.selectedOrderIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.ADMIN_LIST) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
            // if (nextProps.action === types.LIST_ORDER ){
            //     this.state.();
            // }
        }
        if (nextProps.action == types.ADMIN_UPDATE_ADMIN || nextProps.action == types.CREATE_ADMIN) {
            if (nextProps.status) {
                setTimeout(() => {
                    this.list();
                }, 600);
            }

        }

    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {


        // document.getElementById("checkAllItem").checked = false;
        // window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        // e.checked = false;
        // });


        const { selectedLimit, email, fullname } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        let { fromDate, toDate } = this.state;
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "from_date": fromDate,
            "to_date": toDate,
            // "email": email,
            // "fullname": fullname,
        };

        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {

            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.list();
        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

    openCreateForm = () => {
        this.props.getRoleList();
        this.setState({
            currentAdmin: {},
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentAdmin) => {

        this.props.getRoleList();
        this.setState({
            currentAdmin,
            mode: appConst.FORM_MODE.EDIT
        })
    }
    /*******************************CHECK AND ACTIONS***************************/

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (currentAdmin) => {
        this.setState({
            currentAdmin
        })
    }

    addPoint = (currentAdmin) => {
        this.setState({
            currentAdmin
        })
    }

    /****************************************************************/


    render() {

        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchLevel, fullname, email } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.admins || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.admins.length - 1;
        }
        const myAdminInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.ADMIN_LIST);
        return (
            <div>
                <h1 className="page-title">Admin</h1>

                <div>
                    <div className="ladiui-table-actions">
                        {
                            myAdminInfo.roleName == appConst.ROLE.ADMIN &&
                            <div className="action-item">
                                <button className="ladiui button primary icon-c-add open-modal" modal="modal-admininfoform" onClick={this.openCreateForm}>Kích hoạt Admin</button>
                            </div>
                        }
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>
                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>

                                                {/* <th className="text-left">ID</th> */}
                                                <th className="text-left">Tên</th>
                                                <th className="text-left">Email</th>
                                                <th className="text-left">Role</th>
                                                <th className="text-left">Số điện thoại</th>
                                                <th className="text-left">Đăng nhập gần nhất</th>
                                                <th className="text-left">Trạng thái</th>
                                                {/* <th className="text-left">Commission Tier2</th> */}
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Thời gian tạo</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.admins, admin => {

                                                    return <tr key={admin._id}>
                                                        {/* <td>{admin._id}</td> */}
                                                        <td>{admin.fullname}</td>
                                                        <td>{admin.email}</td>
                                                        <td>{admin.roleName}</td>
                                                        <td>{admin.phone}</td>
                                                        <td>{baseHelper.formatStrToDate(admin.last_login)}</td>
                                                        <td>{(admin.status) ? 'Active' : 'Dead Active'}</td>
                                                        <td>{baseHelper.formatStrToDate(admin.created_at)}</td>

                                                        <td>

                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>
                                                                        <li><a onClick={() => this.openEditForm(admin)} className="ladiui open-modal" modal="modal-admininfoform" >Chỉnh sửa</a></li>

                                                                    </ul>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Hiển thị</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                </div>
                <View
                    currentAdmin={this.state.currentAdmin}
                />
                <ChangePoint
                    currentAdmin={this.state.currentAdmin}
                />
                <Form
                    currentAdmin={this.state.currentAdmin}
                    mode={this.state.mode}
                />

            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(adminActions.list(data)),
        getRoleList: () => dispatch(adminActions.getRoleList()),
    }
}

const mapStateToProps = (state) => ({ ...state.admin });

export default connect(mapStateToProps, mapDispatchToProps)(Admins);