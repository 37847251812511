import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.MOVE_LADIPAGE: {
            return {
                ...state,
                action: types.MOVE_LADIPAGE,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.BLOCK_URL: {
            return {
                ...state,
                action: types.BLOCK_URL,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.BLOCK_PUBLISH: {
            return {
                ...state,
                action: types.BLOCK_PUBLISH,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.BLOCK_DOMAIN: {
            return {
                ...state,
                action: types.BLOCK_DOMAIN,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.BLOCK_IP_FORM: {
            return {
                ...state,
                action: types.BLOCK_IP_FORM,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }

        case types.ADD_DOMAIN_WHITELIST: {
            return {
                ...state,
                action: types.ADD_DOMAIN_WHITELIST,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.ADD_STORAGE: {
            return {
                ...state,
                action: types.ADD_STORAGE,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.REMOVE_KEY_REDIS: {
            return {
                ...state,
                action: types.REMOVE_KEY_REDIS,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.MOVE_ALL_LADIPAGE: {
            return {
                ...state,
                action: types.MOVE_ALL_LADIPAGE,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.CHECK_DOMAIN: {
            return {
                ...state,
                action: types.CHECK_DOMAIN,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }

        case types.CHECK_LADI_LINK: {
            return {
                ...state,
                action: types.CHECK_LADI_LINK,
                result: action.status ? action.payload : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.BLOCK_LADI_LINK: {
            return {
                ...state,
                action: types.BLOCK_LADI_LINK,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_MEMBER_LP: {
            return {
                ...state,
                action: types.DELETE_MEMBER_LP,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }

        case types.RESET_MEMBER_LP: {
            return {
                ...state,
                action: types.RESET_MEMBER_LP,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }

        case types.DELETE_DOMAIN: {
            return {
                ...state,
                action: types.DELETE_DOMAIN,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }

        case types.BLACK_LIST_DOMAIN: {
            return {
                ...state,
                action: types.BLACK_LIST_DOMAIN,
                result: action.status ? action.payload.message : '',
                data: action.status ? action.payload.data : {},
                status: action.status,
                message: action.message,
            }
        }

        case types.CHANGE_EMAIL: {
            return {
                ...state,
                action: types.CHANGE_EMAIL,
                status: action.status,
                message: action.message,
            }
        }


        case types.ACTION_SSL: {
            return {
                ...state,
                action: types.ACTION_SSL,
                status: action.status,
                message: action.message,
            }
        }

        case types.CHECK_DOMAIN_SSL: {
            return {
                ...state,
                action: types.CHECK_DOMAIN_SSL,
                result: action.status ? action.payload.result : '',
                status: action.status,
                message: action.message,
            }
        }

        case types.VERIFY_EMAIL: {
            return {
                ...state,
                action: types.VERIFY_EMAIL,
                status: action.status,
                message: action.message,
            }
        }

        case types.VERIFY_EMAIL_LADIFLOW: {
            return {
                ...state,
                action: types.VERIFY_EMAIL_LADIFLOW,
                status: action.status,
                message: action.message,
            }
        }

        case types.VERIFY_DOMAIN: {
            return {
                ...state,
                action: types.VERIFY_DOMAIN,
                status: action.status,
                message: action.message,
            }
        }

        case types.VERIFY_DOMAIN_NEW: {
            return {
                ...state,
                action: types.VERIFY_DOMAIN_NEW,
                status: action.status,
                message: action.message,
            }
        }

        case types.STOP_URL: {
            return {
                ...state,
                action: types.STOP_URL,
                status: action.status,
                message: action.message,
            }
        }

        case types.ACTIVE_COURSE: {
            return {
                ...state,
                action: types.ACTIVE_COURSE,
                status: action.status,
                message: action.message,
            }
        }


        case types.RELOAD_CONFIG_SSL: {
            return {
                ...state,
                action: types.RELOAD_CONFIG_SSL,
                status: action.status,
                message: action.message,
            }
        }

        case types.RECONNECT_REDIS: {
            return {
                ...state,
                action: types.RECONNECT_REDIS,
                status: action.status,
                message: action.message,
            }
        }

        case types.EXPORT_REPORT_APOTA: {
            return {
                ...state,
                action: types.EXPORT_REPORT_APOTA,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }

        case types.REPORT_TRAFFIC: {
            return {
                ...state,
                action: types.REPORT_TRAFFIC,
                result: action.status ? action.payload.message : '',
                status: action.status,
                message: action.message,
            }
        }

        default:
            return state;
    }
}

