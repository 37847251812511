export const LIST_ORDER = 'LIST_ORDER';
export const DELETE_ORDERS = 'DELETE_ORDERS';
export const DELETE_ORDER = 'DELETE_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DETAIL_ORDER = 'DETAIL_ORDER';
export const MY_ORDER = 'MY_ORDER';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const PAID_ORDER = 'PAID_ORDER';
export const LIST_ORDER_DETAIL = 'LIST_ORDER_DETAIL';
export const RE_CREATE_COMMISSION = 'RE_CREATE_COMMISSION';