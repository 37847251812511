import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';

import { connect } from 'react-redux';
import commissionActions from '../../redux/futures/commission/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/commission/types';

import Detail from './components/Detail';
import LoadingScene from '../../components/LoadingScene'; 

import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingTable from '../../components/LoadingTable';


class Commission extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            searchStatus: {
                name: appConst.COMMISSION_TYPE.ALL.name,
                value: appConst.COMMISSION_TYPE.ALL.value,
                selected: appConst.COMMISSION_TYPE.ALL,
            },
            code: "",
            fromDate: firstDay,
            toDate: new Date(),
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            commissionList: [],
            currentCommission: {},
            excelDatas: [],
            // downloadUrl: '',
            submitData: false

        }


        this.selectedComissionIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
        
        
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.COMMISSION_LIST) {
            
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
                this.setState({
                    excelDatas: nextProps.excelDatas
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
        if (nextProps.action == types.CREATE_INVOICE) {

            if (nextProps.status) {
                setTimeout(() => {
                    this.list();
                }, 500);

            }
        }
         
        if (nextProps.action == types.EXPORT_COMMISSION) {
            setTimeout(() => {
                this.setState({
                    submitData:false
                });
            }, 200);
            if (nextProps.status) {
                
                // this.setState({
                //     downloadUrl: nextProps.url
                // });
            }
        }
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {

        document.getElementById("checkAllItem").checked = false;
        window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
            e.checked = false;
        });


        const { selectedLimit, code } = this.state;
        let { fromDate, toDate } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);
        const statusSelected = this.state.searchStatus.selected;

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "is_invoiced": statusSelected,
            "from_date": fromDate,
            "to_date": toDate,
            "code": code
        };


        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.list();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

    openCreateForm = () => {
        this.setState({
            currentCommission: false,
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentCommission) => {
        this.setState({
            currentCommission,
            mode: appConst.FORM_MODE.EDIT
        })
    }
    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedComissionIds = [];
        if (checked) {
            this.selectedComissionIds = _.map(this.props.commissions, store => store._id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, commissionId) => {
        const { checked } = event.target;
        const { selectedComissionIds } = this;

        if (checked) {
            if (!_.includes(selectedComissionIds, commissionId)) {
                selectedComissionIds.push(commissionId);
            }

            if (selectedComissionIds.length == this.props.commissions.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedComissionIds, selectedCommissionId => selectedCommissionId == commissionId);
        }


    }

    deleteAllItems = () => {
        if (this.selectedComissionIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }

        this.props.deleteOrders(this.selectedComissionIds);
    }

    deleteItem = (commissionId) => {
        this.props.deleteOrder(commissionId);
    }

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (currentCommission) => {
        this.setState({
            currentCommission,

        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }

    exportExcelAll = () => {
        const { excelDatas,submitData } = this.state;
        if (submitData) return;
        if (excelDatas.length == 0) {
            window.LadiUI.toastr('error', '', 'Data empty');
            return;
        } 
        const data = {
            ids: excelDatas
        }; 
        this.setState({
            submitData:true
        });
        this.props.exportExcel(data);


    }

    exportList = () => {
        const { submitData } = this.state;
        if (submitData) return;
        if (this.selectedComissionIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }
        const data = {
            ids: this.selectedComissionIds
        }; 
        this.setState({
            submitData:true
        });
        this.props.exportExcel(data);
    }

    /****************************************************************/


    render() {

        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchStatus, currentCommission, code } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.commissions || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.commissions.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.COMMISSION_LIST);
        return (
            <div>
                <h1 className="page-title">Hoa hồng</h1>

                <div>
                    <div className="ladiui-table-actions">

                        <div className="action-item">
                            <ul className="ladiui-navbar -horizontal">
                                <li className="ladiui-dropdown">
                                <a href="#" className={
                                    "ladiui button outline-primary" +(this.state.submitData ? " loader" : "")
                                    
                                    }>Thao tác <i
                                    className="ladi-icon icon-down-arrow"></i></a>
                                    <ul>
                                        <li><a onClick={this.exportExcelAll}>Lưu tất cả</a></li>
                                        <li><a onClick={this.exportList}>Lưu file các dòng đã chọn</a></li>
                                    </ul>
                                </li>
                            </ul>
                            
                        </div>
                        {/* <div className="action-item">
                            {
                                (this.state.downloadUrl != '') &&
                            <a href={this.state.downloadUrl}><button className="ladiui button primary">{this.state.downloadUrl != '' ?'Download':''}</button>
                            </a>
                            }
                        </div> */}
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Search code" name="code" value={code} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                        <div className="action-item">

                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div>
                       
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{searchStatus.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.COMMISSION_TYPE, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchStatus(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>

                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th>
                                                {/* <th name="store_id" onClick={this.sort} className="text-left sorting">ID</th> */}

                                                <th className="text-left">Email user</th>
                                                <th className="text-left">Giá trị đơn hàng</th>
                                                <th className="text-left">Hoa hồng</th>
                                                <th className="text-left">Email affiliate</th>
                                                
                                                <th className="text-left">Ngày lập hoá đơn</th>
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Ngày mua</th>
                                                <th className="text-left">Chương trình</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.commissions, commission => {
                                                    commission.email = commission.userInfo.email;
                                                    return <tr key={commission._id}>
                                                        <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, commission._id)} className="ladiui checkbox" />
                                                        </td>
                                                        {/* <td>{store.store_id}</td> */}
                                                        <td>{commission.userInfo.email}</td>
                                                        <td>{commission.order_amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                        <td>{commission.commission_value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                        <td>{commission.affilateInfo.email}</td>
                                                        
                                                        <td>{baseHelper.formatStrToDate(commission.date_invoice)}</td>
                                                        <td>{baseHelper.formatStrToDate(commission.created_at)}</td>
                                                        <td>{
                                                            commission.type?
                                                            commission.type:
                                                            'Affiliate'
                                                            }
                                                        </td>

                                                        {/* <td>
                                                            
                                                            <button className="ladiui button primary open-modal" onClick={() => this.openEditForm(commission)} modal="modal-commissiondetail" >View</button>
                                                             

                                                        </td> */}
                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                                totalRecord <= 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="100%">Không có bản ghi nào</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    {
                        totalRecord > 0 &&
                        <div className="ladiui pagination-block">
                            <div className="ladiui search-actions">
                                <div className="ladiui form-filter">
                                    <div className="ladiui data-limit">
                                        <span>Show</span>
                                        <div className="ladiui per-page">
                                            <div className="ladiui-select-options">
                                                <span className="ladiui-select-init">{selectedLimit}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui showing">{msgShowItem}</div>
                                </div>
                            </div>
                            {
                                totalRecord > 0 && <div className="ladiui pagination">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={selectedLimit}
                                        totalItemsCount={totalRecord}
                                        pageRangeDisplayed={5}
                                        onChange={this.onPageChange}
                                        prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                        nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                        firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                        lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                    />
                                </div>
                            }

                        </div>
                    }
                </div>
                <Detail
                    currentCommission={currentCommission}
                />
                {
                    this.state.loadingScene &&
                    <LoadingScene />
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(commissionActions.list(data)),
        detail: (data) => dispatch(commissionActions.detail(data)),
        exportExcel: (data) => dispatch(commissionActions.exportExcel(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.commission });

export default connect(mapStateToProps, mapDispatchToProps)(Commission);