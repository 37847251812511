import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.USER_LIST: {
            return {
                ...state,
                action: types.USER_LIST,
                users: action.status ? action.payload.users : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.USER_LIST_BYDATE: {
            return {
                ...state,
                action: types.USER_LIST_BYDATE,
                users: action.status ? action.payload.users : [],
                excelDatas: action.status ? action.payload.excelDatas : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.EXPORT_USER: {
            return {
                ...state,
                action: types.EXPORT_USER,
                url: action.status ? action.payload.url : '',
                status: action.status,
                message: action.message,
            }
        } 
        case types.ADMIN_UPDATE_USER: {
            return {
                ...state,
                action: types.ADMIN_UPDATE_USER, 
                status: action.status,
                message: action.message,
            }
        } 

        case types.GET_TEMP_PASSWORD: {
            return {
                ...state,
                action: types.GET_TEMP_PASSWORD,
                token: action.status ? action.payload.token : '',
                status: action.status,
                message: action.message,
            }
        }

        case types.HARD_DELETE_USER: {
            return {
                ...state,
                action: types.HARD_DELETE_USER,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

