import React from 'react';

import { connect } from 'react-redux';

import _ from 'lodash';
import appConst from '../../config/const';
import ConfirmModal from '../../components/ConfirmModal';
import * as types from '../../redux/futures/tools/types';
import toolActions from '../../redux/futures/tools/actions';
import storeActions from '../../redux/futures/store/actions';

import MoveLadiPage from './MoveLadiPage';
import MoveAllLadiPage from './MoveAllLadiPage';
import RestoreLadiPage from './RestoreLadiPage';
import ChangeEmail from './ChangeEmail';
import CheckDomain from './CheckDomain';
import CheckLink from './CheckLink';
import DeleteMember from './DeleteMember';
import DeleteDomain from './DeleteDomain';
import BlackListDomain from './BlackListDomain';
import BlockDomain from './BlockDomain';
import BlockIpForm from './BlockIpForm';
import BlockLadiLink from './BlockLadiLink';
import BlockUrl from './BlockUrl';
import BlockPublish from './BlockPublish';
import OnOffSSL from './OnOffSSL';
import CheckDomainSSL from './CheckDomainSSL';
import VerifyEmail from './VerifyEmail';
import VerifyDomain from './VerifyDomain';
import VerifyDomainNew from './VerifyDomainNew';
import ActiveManyEmail from './ActiveManyEmail';
import baseHelper from '../../helpers/BaseHelper';
import { is } from 'date-fns/locale';
import RemoveKeyRedis from './RemoveKeyRedis';
import WhiteListDomain from './WhiteListDomain';
import ReportApota from './ReportApota';
import ReportTraffic from './ReportTraffic';
import StopUrl from './StopUrl';
import ActiveCourse from './ActiveCourse';
import VerifyEmailLadiFlow from './VerifyEmailLadiFlow';

class Tools extends React.Component {

    constructor(props) {
        super(props);

    }
    /****************************************************************/

    openMoveLadipageForm = () => {
        // this.setState({
        //     currentReferral: false,
        //     mode: appConst.FORM_MODE.CREATE
        // })
    }

    openActiveEducation = () => {
        const data = {
            app_code: 'LP'
        };
        this.props.listPackages(data);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action == types.RELOAD_CONFIG_SSL) {
            if (nextProps.status) {
                window.LadiUI.toastr('success', '', nextProps.message);
                const that = this;
                setTimeout(() => {
                    window.LadiUI.closeModalById('confirm-reload-config-ssl');

                }, 200);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

        if (nextProps.action == types.RECONNECT_REDIS) {
            if (nextProps.status) {
                window.LadiUI.toastr('success', '', nextProps.message);
                const that = this;
                setTimeout(() => {
                    window.LadiUI.closeModalById('confirm-reconnect-redis');

                }, 200);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    openReloadConfig = () => {
        window.LadiUI.showModalById('confirm-reload-config-ssl');
    }

    openReconnectRedis = () => {
        window.LadiUI.showModalById('confirm-reconnect-redis');
    }

    openEditForm = () => {
        this.setState({
            mode: appConst.FORM_MODE.EDIT
        })
    }


    view = (currentReferral) => {
        this.setState({
            currentReferral,
            mode: appConst.FORM_MODE.EDIT
        })
    }

    /****************************************************************/


    render() {
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        const { roleName } = userInfo;
        const isAdmin = appConst.ALLOW_ADMIN.indexOf(userInfo.admin_id) >= 0;
        const isLoadingReload = this.props.loading && (this.props.waitting == types.RELOAD_CONFIG_SSL);
        return (
            <div>
                <h1 className="page-title">Công cụ</h1>
                {
                    roleName == 'SUPPORT_ONLY_TOOL' &&
                    <div className="ladiui-table-actions" id="tool-buttons">
                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-check-ladi-link" onClick={this.openRestoreLadipageForm}>Check Link LadiLink</button>
                        </div>
                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-check-domain-ssl" onClick={this.openRestoreLadipageForm}>Check SSL</button>
                        </div>
                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-action-ssl" onClick={this.openRestoreLadipageForm}>Bật/Tắt SSL Domain</button>
                        </div>
                    </div>
                }
                {
                    roleName != 'SUPPORT_ONLY_TOOL' &&

                    <div className="ladiui-table-actions" id="tool-buttons">
                        <React.Fragment>
                            {roleName != 'SUPPORT_MANAGER' && <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-change-email" onClick={this.openMoveLadipageForm}>Đổi email</button>
                            </div>}
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-block-url" onClick={this.openBlockUrl}>Chặn đường dẫn</button>
                            </div>
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-check-domain" onClick={this.openRestoreLadipageForm}>Check tên miền</button>
                            </div>
                            {roleName != 'SUPPORT_MANAGER' && <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-move-all-ladipage" onClick={this.openMoveAllLadipageForm}>Di chuyển Nhiều Ladipage</button>
                            </div>}
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-add-domain-whitelist" onClick={this.openRestoreLadipageForm}>Add domain White List</button>
                            </div>

                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-check-ladi-link" onClick={this.openRestoreLadipageForm}>Check Link LadiLink</button>
                            </div>

                        </React.Fragment>
                        <React.Fragment>
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-verify-email" onClick={this.openRestoreLadipageForm}>Verify email</button>
                            </div>
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-check-domain-ssl" onClick={this.openRestoreLadipageForm}>Check SSL</button>
                            </div>
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-block-publish" onClick={this.openBlockPublish}>Chặn xuất bản</button>
                            </div>
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-remove-key-redis" onClick={this.openRemoveRedis}>Remove key redis</button>
                            </div>
                            {
                                roleName != 'SUPPORT_MANAGER' &&
                                <div className="action-item">
                                    <button className="ladiui button primary open-modal" modal="modal-delete-member-lp" onClick={this.openDeleteMemberLP}>Xóa thành viên LadiPage</button>
                                </div>
                            }
                            {roleName != 'SUPPORT_MANAGER' && <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-move-ladipage" onClick={this.openMoveLadipageForm}>Di chuyển Ladipage</button>
                            </div>}

                        </React.Fragment>
                        <React.Fragment>
                            {/* <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-verify-domain" onClick={this.openRestoreLadipageForm}>Verify domain</button>
                            </div> */}
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-action-ssl" onClick={this.openRestoreLadipageForm}>Bật/Tắt SSL Domain</button>
                            </div>



                            {roleName != 'SUPPORT_MANAGER' &&
                                <div className="action-item">
                                    <button className="ladiui button primary open-modal" modal="modal-delete-domain" onClick={this.openRestoreLadipageForm}>Xoá domain</button>
                                </div>}

                            {roleName != 'SUPPORT_MANAGER' && <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-blacklist-domain" onClick={this.openRestoreLadipageForm}>Blacklist link xuất bản</button>
                            </div>}
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-block-domain" onClick={this.openRestoreLadipageForm}>Block domain</button>
                            </div>
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-block-ladilink" onClick={this.openRestoreLadipageForm}>Xóa link LadiLink</button>
                            </div>
                        </React.Fragment>
                        {
                            roleName != 'SUPPORT_MANAGER' &&
                            <div className="action-item">
                                <button className="ladiui button primary open-modal" modal="modal-active-education" onClick={this.openActiveEducation}>Kích hoạt gói Education</button>
                            </div>
                        }
                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-report-apota" onClick={this.openReportApota}>Báo cáo Apota</button>
                        </div>
                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-report-traffic" onClick={this.openReportApota}>Báo cáo Traffic</button>
                        </div>
                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-verify-domain-new">Xác thực tên miền (mới)</button>
                        </div>
                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-stop-url">Stop URL</button>
                        </div>

                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-active-course">Khởi tạo khóa học</button>
                        </div>

                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-verify-email-flow">Xác thực email LadiFlow</button>
                        </div>
                        <div className="action-item">
                            <button className="ladiui button primary open-modal" modal="modal-block-ip-form" >Block IP form</button>
                        </div>

                    </div>
                }
                <MoveAllLadiPage />
                <MoveLadiPage />
                <ChangeEmail />
                <RestoreLadiPage />
                <CheckDomain />
                <CheckLink />
                <DeleteDomain />
                <DeleteMember />
                <BlackListDomain />
                <WhiteListDomain />
                <BlockDomain />
                <BlockIpForm />
                <BlockLadiLink />
                <BlockUrl />
                <BlockPublish />
                <OnOffSSL />
                <CheckDomainSSL />
                <VerifyEmail />
                <VerifyEmailLadiFlow />
                <VerifyDomain />
                <VerifyDomainNew />
                <StopUrl />
                <ActiveCourse />
                <ActiveManyEmail />
                <RemoveKeyRedis />
                <ReportApota />
                <ReportTraffic />
                <ConfirmModal
                    id="confirm-reload-config-ssl"
                    title="Reload lại config SSL"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.props.reloadConfigSSL()}
                    isLoading={isLoadingReload}
                />
                <ConfirmModal
                    id="confirm-reconnect-redis"
                    title="Reconnect redis"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.props.reconnectRedis()}
                    isLoading={isLoadingReload}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reloadConfigSSL: (data) => dispatch(toolActions.reloadConfigSSL()),
        reconnectRedis: (data) => dispatch(toolActions.reconnectRedis()),
        listPackages: (data) => dispatch(storeActions.listPackages(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(Tools);