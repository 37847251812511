import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import Input from '../../components/Input';

import * as types from '../../redux/futures/tools/types';




class MoveLadiPage extends React.Component {
 
    constructor(props) {
        super(props);

        this.state = {
            ladiPage: { 

                emailSend: "",

                emailRecieve: "",

                ladipageID: "", // thông tin thanh toán

                ladipageDomain: ""
            },
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: ''
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        // this.defaultState = _.cloneDeep(this.state.ladiPage);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            
             
            if (nextProps.action == types.MOVE_LADIPAGE) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });  
                if (nextProps.status) {
                    const _seft = this;
                    window.LadiUI.toastr('success', '', nextProps.message);
                    setTimeout(function () {
                        window.LadiUI.closeModal(_seft.ref.current);
                    },1000);
                    
                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            } 
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { ladiPage } = this.state;
        const jsonData = {
            "from_email": ladiPage.emailSend,
            "to_email":  ladiPage.emailRecieve,
            "ladipage_id":ladiPage.ladipageID,
            "ladipage_domain": ladiPage.ladipageDomain
        } 
        this.props.moveLP(jsonData);
        
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }
 
 
    onChangeInput = (event) => { 
         
        this.setState({
            ladiPage: {
                ...this.state.ladiPage,
                [event.target.name]: event.target.value
            }
        });
    } 
 

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            ladiPage: baseHelper.resetState(this.state.ladiPage),
        });
        window.LadiUI.closeModal(this.ref.current);

        // window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
        //     e.classList.remove('error');
        // });
    }

    render() {
        const { ladiPage } = this.state;

         
        const title = 'Di chuyển Ladipage ';

        return (
            <div ref={this.ref} id="modal-move-ladipage" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-move-ladipage"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-move-ladipage"><i className="ladi-icon icon-e-remove" data-dismiss="modal-move-ladipage" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email chủ sở hữu LandingPage</label> 
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="emailSend"
                                                validationName="Email"
                                                placeholder="Chủ sở hữu LandingPage"
                                                value={ladiPage.emailSend}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, email: true }}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email nhận Ladipage</label> 
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="emailRecieve"
                                                validationName="Email"
                                                placeholder="Email nhận Ladipage"
                                                value={ladiPage.emailRecieve}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, email: true }}
                                            /> 
                                        </div>
                                    </div>

                                </div> 
                                <div className="ladiui-row">
                                <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Domain</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="ladipageDomain"
                                                validationName="Domain"
                                                placeholder="Domain"
                                                value={ladiPage.ladipageDomain}
                                                onChange={this.onChangeInput}
                                               
                                                onKeyPress={this.onKeyPress} 
                                            />
                                        </div>
                                       
                                        </div>
                                        <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">ID Ladipage</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="ladipageID"
                                                validationName="Ladingpage ID"
                                                placeholder="Ladingpage ID"
                                                value={ladiPage.ladipageID}
                                                onChange={this.onChangeInput}
                                                
                                                onKeyPress={this.onKeyPress}
                                                 
                                            />
                                        </div>
                                    </div>
                                    

                                </div>


                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-move-ladipage">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-move-ladipage" />Đóng
                        </button>
                        <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.submit}
                        disabled={this.state.loading}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i>  
                                    Áp dụng
                         </button>
                         
                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        moveLP: (data) => dispatch(toolActions.moveLadiPage(data)) 
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(MoveLadiPage);