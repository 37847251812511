import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';


import * as types from '../../../redux/futures/user/types';




class GetPassword extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            token: "",
            currentUser: {
                ladi_uid: '',
                email:''
            }
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
         const { currentUser, token } = this.state;
        if (nextProps.currentUser && currentUser !== nextProps.currentUser) {
            this.setState({
                currentUser: nextProps.currentUser,
                token
            });
        }
        if (nextProps.action == types.GET_TEMP_PASSWORD) {

            if (nextProps.status) {
            
               this.setState({
                    currentUser,
                   token: nextProps.token
               },() => {
                
                    this.textAreaRef.select();
                    document.execCommand('copy');
                    window.LadiUI.toastr('success', '', 'Đã copy vào clipboard');
                 
               })
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    submit = () => {
        const { currentUser } = this.state;
        const jsonData = {
            "ladi_uid": currentUser.ladi_uid,
        }
        this.props.getToken(jsonData);

    }
 
    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.state = {
            token: ""
        }

        window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
            e.classList.remove('error');
        });
    }

    copyToClipboardRef = () => {
        this.textAreaRef.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        // e.target.focus();
        window.LadiUI.toastr('success', '', 'Đã sao chép vào khay nhớ tạm');
    };

    render() {
        const { currentUser, token } = this.state;

        const title = 'Lấy mật khẩu đăng nhập';
        
        return (
            <div ref={this.ref} id="modal-temppassword" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-temppassword"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content">
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-temppassword"><i className="ladi-icon icon-e-remove" data-dismiss="modal-temppassword" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>

                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div>
                                        <div className="ladiui form-group">
                                        <label className="ladiui-label">Email</label>
                                        <input 
                                            readOnly
                                            type="text" 
                                            className="ladiui input" 
                                            defaultValue={currentUser.email}
                                        />
                                         
                                        </div>

                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Token đăng nhập</label>
                                                <input 
                                                readOnly
                                                type="text" 
                                                className="ladiui input" 
                                                value={token} 
                                                ref={(textarea) => this.textAreaRef = textarea}
                                            />
                                            {/* {
                                                document.queryCommandSupported('copy') &&
                                                <div className="ladiui-input-group-prepend">
                                                <button className="ladiui-input-group-text" onClick={this.copyToClipboardRef}>Copy</button>
                                                </div>
                                            } */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = () => {
    return {
        
    }
}

const mapStateToProps = (state) => ({ 
    ...state.user 
});

export default connect(mapStateToProps, mapDispatchToProps)(GetPassword);


