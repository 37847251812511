import * as types from './types';
import * as commonTypes from '../common/types';
import * as orderTypes from '../order/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.LIST_STORE: {
            return {
                ...state,
                action: types.LIST_STORE,
                stores: action.status ? action.payload.stores : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,

            }
        }

        case types.LIST_PACKAGE: {
            return {
                ...state,
                action: types.LIST_PACKAGE,
                packages: action.status ? action.payload.packages : [],
                app_code: action.app_code ? action.payload.app_code : false,
                status: action.status,
                message: action.message,

            }
        }

        case types.LIST_PACKAGE_COURSE: {
            return {
                ...state,
                action: types.LIST_PACKAGE_COURSE,
                packages: action.status ? action.payload.packages : [],
                app_code: action.app_code ? action.payload.app_code : false,
                status: action.status,
                message: action.message,

            }
        }

        case types.LIST_DEALS: {
            return {
                ...state,
                action: types.LIST_DEALS,
                deals: action.status ? action.payload : [],
                status: action.status,
                message: action.message,

            }
        }


        case types.LIST_PACKAGE_LADIBOOST: {
            return {
                ...state,
                action: types.LIST_PACKAGE_LADIBOOST,
                packages: action.status ? action.payload.packages : [],
                status: action.status,
                message: action.message,

            }
        }

        case types.UPGRADE_STORE: {
            return {
                ...state,
                action: types.UPGRADE_STORE,
                status: action.status,
                message: action.message,

            }
        }

        case types.INFO_LADIBOOST: {
            return {
                ...state,
                action: types.INFO_LADIBOOST,
                stores: action.status ? action.payload : {
                    ladi_page: false,
                    ladi_flow: false,
                    ladi_sale: false,
                },
                status: action.status,
                message: action.message,

            }
        }

        case types.ACTIVE_LADIBOOST: {
            return {
                ...state,
                action: types.ACTIVE_LADIBOOST,
                status: action.status,
                message: action.message,

            }
        }

        case types.UPGRADE_STORE_LIST: {
            return {
                ...state,
                action: types.UPGRADE_STORE_LIST,
                listStore: action.status ? action.payload.listStore : [],
                listPkg: action.status ? action.payload.listPkg : [],
                listRegister: action.status ? action.payload.listRegister : [],
                status: action.status,
                message: action.message,

            }
        }

        case types.DOWNGRADE_STORE: {
            return {
                ...state,
                action: types.DOWNGRADE_STORE,
                status: action.status,
                message: action.message,

            }
        }

        case types.ACTIVE_STORE: {
            return {
                ...state,
                action: types.ACTIVE_STORE,
                status: action.status,
                message: action.message,

            }
        }

        case types.CHANGE_PACKAGE_STORE: {
            return {
                ...state,
                action: types.CHANGE_PACKAGE_STORE,
                status: action.status,
                message: action.message,

            }
        }


        case types.CLEAR_TOKEN: {
            return {
                ...state,
                action: types.CLEAR_TOKEN,
                status: action.status,
                message: action.message,
            }
        }

        case types.GET_USERINFO: {
            return {
                ...state,
                action: types.GET_USERINFO,
                userinfo: action.status ? action.payload.user : false,
                status: action.status,
                message: action.message,
            }
        }

        case orderTypes.LIST_ORDER: {
            return {
                ...state,
                action: orderTypes.LIST_ORDER,
                orders: action.status ? action.payload.orders : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case orderTypes.LIST_ORDER_DETAIL: {
            return {
                ...state,
                action: orderTypes.LIST_ORDER_DETAIL,
                details: action.status ? action.payload.details : [],
                status: action.status,
                message: action.message,
            }
        }

        default:
            return state;
    }
}

