import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../components/Spinner';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import Input from '../../components/Input';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';

import * as types from '../../redux/futures/auth/types';




class ResetPassword extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props);
        const parsed = queryString.parse(this.props.location.search);
        // console.log(this.props.location.search);

        this.state = {
            passwordObj: {
                newpass: '',
                confirmpass: ''
            },
            token: parsed.token ? parsed.token : '',
            loading: false,
            typeInput: 'password',
            iconClass: 'icon-preview',
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        };

        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillMount() {
        // this.redirectAuth(this.props);
    }

    redirectAuth(props) {

        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        if (userInfo) {
            props.history.push('/');
        }

    }

    componentWillReceiveProps(nextProps) {


        if (nextProps.action == types.RESET_PASSWORD) {
            this.setState({
                loading : false,
                classApply : this.state.classNormal
            });
            if (nextProps.status) {
                window.LadiUI.toastr('success', '', nextProps.message);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { passwordObj, token } = this.state;
        if (passwordObj.newpass != passwordObj.confirmpass) {
            window.LadiUI.toastr('error', '', 'New password and confirm password must match');
            return;
        }
        const jsonData = {
            "token": token,
            "password": passwordObj.newpass,
            "store_id": "",
            "app_code": "luid"
        }
        this.props.resetpass(jsonData);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
         
    }

    onChangeInput = (event) => {

        this.setState({
            passwordObj: {
                ...this.state.passwordObj,
                [event.target.name]: event.target.value
            }
        });
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.state = {
            passwordObj: {
                newpass: '',
                confirmpass: ''
            }
        }

        window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
            e.classList.remove('error');
        });
    }

    showPassword = () => {
        const { typeInput } = this.state;
        if (typeInput == 'password') {
             this.setState({
                typeInput: 'text',
                iconClass: 'icon-b-preview'
             });
        }
        if (typeInput == 'text') {
            this.setState({
                typeInput: 'password',
                iconClass: 'icon-preview'
             });
        
        }
    }

    render() {
        const { passwordObj ,typeInput, iconClass } = this.state;

        const title = 'Reset password';
        const search = this.props.location.search;
        return (
            <div className="ladiui auth">
                <div className="ladiui-logo">
                    <img src="https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg" alt='Ladipage.vn' />
                </div>
                <div className="ladiui-login">
                <h4>Để đặt lại mật khẩu, vui long nhập lại mật khẩu mới.</h4>
                    <div className="ladiui-login-form">
                        <div className="ladiui-form">
                            <div className="ladiui form-group">
                                {/* <label className="ladiui-label">New Password</label> */}
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="newpass"
                                    validationName="NewPassword"
                                    placeholder="Mật khẩu mới"
                                    value={passwordObj.newpass}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true, min: 6, max: 20 }}
                                    type={typeInput}
                                />
                                <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                            </div>

                            <div className="ladiui form-group">
                                {/* <label className="ladiui-label">Confirm Password</label> */}
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="confirmpass"
                                    validationName="ConfirmPassword"
                                    placeholder="Xác nhận mật khẩu mới"
                                    value={passwordObj.confirmpass}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true, min: 6, max: 20 }}
                                    type={typeInput}
                                />
                            </div>
                            <input type="hidden" name="token" value={this.state.token} />
                            <div className="ladiui-form-group">
                            <button 
                            ref={this.ref}
                            className={this.state.classApply} 
                            onClick={this.submit}
                            disabled={this.state.loading}
                            >
                            <i style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i> Đặt lại mật khẩu</button>
                            </div>
                        </div> 
                        <div className="question-form">
                            <NavLink to={{
                                pathname:"/signin",
                                search: search
                            }}> Quay lại trang đăng nhập </NavLink> 
                        </div>
                    </div>
                </div>
                <div className="ladipage-terms">
                <ul>
                        <li><a target='_blank' href={appConst.SUPPORT_URL.SUPPORT}>Trợ giúp</a></li>
                        <li><a target="_blank" href="https://docs.ladipage.vn/article/68-chinh-sach-bao-mat" >Chính sách bảo mật</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetpass: (passwordObj) => dispatch(authActions.resetPassword(passwordObj))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);