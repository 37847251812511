import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';

import { connect } from 'react-redux';
import commissionActions from '../../redux/futures/commission/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/commission/types';


import adminActions from '../../redux/futures/admin/actions';
import * as admintTypes from '../../redux/futures/admin/types';

import Detail from './components/Detail';
import LoadingScene from '../../components/LoadingScene';

import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingTable from '../../components/LoadingTable';


class CommissionFlow extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            adminInfo: {
                _id: false,
                email: 'All Admin'
            },
            adminList: [
                {
                    _id: false,
                    email: 'All Admin'
                }
            ],
            code: "",
            fromDate: firstDay,
            toDate: new Date(),
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            commissionList: [],
            currentCommission: {},
            excelDatas: [],
            // downloadUrl: '',
            submitData: false

        }


        this.selectedComissionIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
        this.listAllAdmin();

    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.commission.action && !_.includes(this.muteActions, nextProps.commission.action) && nextProps.commission.action === types.COMMISSION_LADIFLOW_LIST) {

            if (nextProps.commission.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
                this.setState({
                    excelDatas: nextProps.commission.excelDatas
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.commission.message);
            }
        }

        if (nextProps.commission.action == types.EXPORT_COMMISSION_LADIFLOW) {
            setTimeout(() => {
                this.setState({
                    submitData: false
                });
            }, 200);
            if (nextProps.commission.status) {

                // this.setState({
                //     downloadUrl: nextProps.url
                // });
            }
        }
        if (nextProps.admin.action && !_.includes(this.muteActions, nextProps.admin.action) && nextProps.admin.action === admintTypes.ADMIN_LIST) {
            if (nextProps.admin.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
                let listAdmin = [{ _id: false, email: 'All Admin' }];
                listAdmin = listAdmin.concat(nextProps.admin.admins);
                this.setState({ adminList: listAdmin })
            } else {
                window.LadiUI.toastr('error', '', nextProps.admin.message);
            }
            // if (nextProps.action === types.LIST_ORDER ){
            //     this.state.();
            // }
        }
    }

    listAllAdmin = (resetActivePageFlag = true) => {

        const data = {
            limit: 100
        };

        this.props.listAdmin(data);
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {

        document.getElementById("checkAllItem").checked = false;
        window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
            e.checked = false;
        });


        const { selectedLimit, code, adminInfo } = this.state;
        let { fromDate, toDate } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "from_date": fromDate,
            "to_date": toDate,
            "ladiflow_ref": code,

        };
        if (adminInfo._id) {
            data.admin_id = adminInfo._id;
        }


        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeAdmin = (option) => {
        this.setState({
            adminInfo: option,
            adminID: option._id,
        }, () => {
            this.list();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedComissionIds = [];
        if (checked) {
            this.selectedComissionIds = _.map(this.props.commission.commissions, store => store._id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, commissionId) => {
        const { checked } = event.target;
        const { selectedComissionIds } = this;

        if (checked) {
            if (!_.includes(selectedComissionIds, commissionId)) {
                selectedComissionIds.push(commissionId);
            }

            if (selectedComissionIds.length == this.props.commissions.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedComissionIds, selectedCommissionId => selectedCommissionId == commissionId);
        }


    }
    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (currentCommission) => {
        this.setState({
            currentCommission,

        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }

    exportExcelAll = () => {
        const { excelDatas, submitData } = this.state;
        if (submitData) return;
        if (excelDatas.length == 0) {
            window.LadiUI.toastr('error', '', 'Data empty');
            return;
        }
        const data = {
            ids: excelDatas
        };
        this.setState({
            submitData: true
        });
        this.props.exportExcel(data);


    }

    exportList = () => {
        const { submitData } = this.state;
        if (submitData) return;
        if (this.selectedComissionIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }
        const data = {
            ids: this.selectedComissionIds
        };
        this.setState({
            submitData: true
        });
        this.props.exportExcel(data);
    }

    /****************************************************************/


    render() {

        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchStatus, currentCommission, code, adminList, adminInfo } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.commissions || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.commissions.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.COMMISSION_LADIFLOW_LIST);
        return (
            <div>
                <h1 className="page-title">Hoa hồng LadiFlow</h1>

                <div>
                    <div className="ladiui-table-actions">

                        <div className="action-item">
                            <ul className="ladiui-navbar -horizontal">
                                <li className="ladiui-dropdown">
                                    <a href="#" className={
                                        "ladiui button outline-primary" + (this.state.submitData ? " loader" : "")

                                    }>Thao tác <i
                                        className="ladi-icon icon-down-arrow"></i></a>
                                    <ul>
                                        <li><a onClick={this.exportExcelAll}>Lưu tất cả</a></li>
                                        <li><a onClick={this.exportList}>Lưu file các dòng đã chọn</a></li>
                                    </ul>
                                </li>
                            </ul>

                        </div>
                        {/* <div className="action-item">
                            {
                                (this.state.downloadUrl != '') &&
                            <a href={this.state.downloadUrl}><button className="ladiui button primary">{this.state.downloadUrl != '' ?'Download':''}</button>
                            </a>
                            }
                        </div> */}
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Search code" name="code" value={code} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                        <div className="action-item">

                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div>

                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options" style={{ minWidth: '300px' }}>
                                    <span className="ladiui-select-init">{adminInfo.email}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(adminList, option =>
                                                <li key={option._id} className="select-item" onClick={() => this.onChangeAdmin(option)}><a data-value={option._id}>{option.email}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>

                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th>
                                                {/* <th name="store_id" onClick={this.sort} className="text-left sorting">ID</th> */}
                                                <th className="text-left">Email user</th>
                                                <th className="text-left">Credit</th>
                                                <th className="text-left">Tiền thực nhận</th>
                                                <th className="text-left">Hoa hồng</th>
                                                <th className="text-left">Ladi Flow Ref</th>
                                                <th className="text-left">Affiliate Info</th>
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Ngày mua</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.commission.commissions, commission => {
                                                    commission.email = commission.userInfo.email;
                                                    return <tr key={commission._id}>
                                                        <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, commission._id)} className="ladiui checkbox" />
                                                        </td>
                                                        <td>{commission.userInfo.email}</td>
                                                        <td>{baseHelper.numberWithCommas(commission.credit)}</td>
                                                        <td>{commission.order_amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                        <td>{commission.commission_value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                        <td>{commission.ladiflow_ref}</td>
                                                        <td>{commission.affilateInfo ? commission.affilateInfo.email : ''}</td>

                                                        <td>{baseHelper.formatStrToDate(commission.created_at)}</td>
                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                                totalRecord <= 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="100%">Không có bản ghi nào</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    {
                        totalRecord > 0 &&
                        <div className="ladiui pagination-block">
                            <div className="ladiui search-actions">
                                <div className="ladiui form-filter">
                                    <div className="ladiui data-limit">
                                        <span>Show</span>
                                        <div className="ladiui per-page">
                                            <div className="ladiui-select-options">
                                                <span className="ladiui-select-init">{selectedLimit}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui showing">{msgShowItem}</div>
                                </div>
                            </div>
                            {
                                totalRecord > 0 && <div className="ladiui pagination">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={selectedLimit}
                                        totalItemsCount={totalRecord}
                                        pageRangeDisplayed={5}
                                        onChange={this.onPageChange}
                                        prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                        nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                        firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                        lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                    />
                                </div>
                            }

                        </div>
                    }
                </div>
                <Detail
                    currentCommission={currentCommission}
                />
                {
                    this.state.loadingScene &&
                    <LoadingScene />
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(commissionActions.listLadiFlow(data)),
        exportExcel: (data) => dispatch(commissionActions.exportExcelLadiFlow(data)),
        listAdmin: (data) => dispatch(adminActions.list(data)),
    }
}

const mapStateToProps = (state) => ({ commission: { ...state.commission }, admin: { ...state.admin } });

export default connect(mapStateToProps, mapDispatchToProps)(CommissionFlow);