import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';

import { connect } from 'react-redux';
import historyActions from '../../redux/futures/historylog/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/historylog/types';
import LoadingTable from '../../components/LoadingTable';
import moment from 'moment';
// import Form from './components/Form';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import "react-datepicker/dist/react-datepicker.css";

class HistoryLog extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            searchType: {
                name: appConst.ACTION_TYPE.ALL.name,
                value: appConst.ACTION_TYPE.ALL.value,
                selected: appConst.ACTION_TYPE.ALL,
            },
            activePage: 1,
            fromDate: moment(firstDay),
            toDate: moment(new Date()),
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            focusedInput: null,
            params:{}
        }

        this.sortBy = {
            'created_at': 'ASC',
            'status': 'ASC',
        }
      
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.GET_HISTORY) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            } 
        }

       
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {

        const { selectedLimit, email, searchType } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        
        let {fromDate,toDate} =  this.state;
        fromDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            milisecond: 0
        });

        toDate.set({
            hour: 23,
            minute: 59,
            second: 59,
            milisecond: 999
        });

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "from_date": fromDate,
            "to_date": toDate,
            "email": email,
            "action_type": searchType.selected.value
        };
       
        this.props.getHistory(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {

            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangesearchType = (option) => {
        
        this.setState({
            searchType: { ...this.state.searchType, selected: option },
        }, () => {
            this.list();
        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/
 
     
    /*******************************CHECK AND ACTIONS***************************/

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (currentUser) => {
        this.setState({
            currentUser 
        })
    } 

    copyToClipboard = (log) => {
        // console.log(store.store_id);
        this.setState({
            params: log.params
        },()=> {
            this.textAreaRef.select();
            document.execCommand('copy');
            window.LadiUI.toastr('success', '', 'Đã copy vào clipboard');
        })
        
      };
    /****************************************************************/


    render() {

        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchType, email } = this.state;
        let fromItem = 0;
        let toItem = 0;
        console.log(this.props.historylogs);
        if ((this.props.historylogs || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.historylogs.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.GET_HISTORY);
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

        return (
            <div>
                <h1 className="page-title">Lịch sử hoạt động</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder="Tìm theo email" name="email" value={email} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">
                            <DateRangePicker
                                startDateId="fromDate"
                                endDateId="toDate"
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState({
                                        fromDate: startDate,
                                        toDate: endDate,
                                    }, () => {
                                       if (endDate) {
                                            this.list();
                                        }
                                        
                                    })
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={
                                    <i className="ladi-icon icon-calendar" />
                                }
                                displayFormat={()=>appConst.DEFAULT_FORMAT_DATE_NEW}
                                // startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                // endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => {
                                    return moment(new Date()).isSame(date, 'day');
                                }}
                            />
                        </div>
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options"  style={{minWidth:'260px'}}>
                                    <span className="ladiui-select-init">{searchType.name}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.ACTION_TYPE, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangesearchType(option)}><a data-value={option.value}>{option.name}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>
                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>

                                                {/* <th className="text-left">UID</th> */}
                                                <th className="text-left">Thời gian</th>
                                                <th className="text-left">Tên</th>
                                                
                                                <th className="text-left">Thao tác</th>
                                                <th className="text-left">Params</th>
                                                <th className="text-left">Note</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(this.props.historylogs, log => {

                                                    return <tr key={log._id}> 
                                                        <td>{baseHelper.parseTime(new Date(log.created_at))}</td>
                                                        <td>{log.fullname}</td>
                                                        
                                                        <td>{log.description}</td>
                                                        <td>{log.params.replace(",","\n,")}</td>
                                                        <td>{log.note}</td>
                                                        
                                                        <td>

                                                            <ul className="ladiui-navbar -horizontal row-actions">
                                                                <li className="ladiui-dropdown">
                                                                    <a><i className="ladi-icon icon-dots"></i></a>
                                                                    <ul>
                                                                        {/* <li><a onClick={() => this.print(invoice)} className="ladiui open-modal" modal="modal-print" >Print</a></li> */}
                                                                        <li><a onClick={() => this.copyToClipboard(log)} className="ladiui open-modal" modal="modal-invoicedetail" >Get Params</a></li>  
                                                                    </ul>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    <div className="ladiui pagination-block">
                        <div className="ladiui search-actions">
                            <div className="ladiui form-filter">
                                <div className="ladiui data-limit">
                                    <span>Hiển thị</span>
                                    <div className="ladiui per-page">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedLimit}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui showing">{msgShowItem}</div>
                            </div>
                        </div>
                        {
                            totalRecord > 0 && <div className="ladiui pagination">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={selectedLimit}
                                    totalItemsCount={totalRecord}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPageChange}
                                    prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                    nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                    firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                    lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                />
                            </div>
                        }

                    </div>
                </div>
                <input 
                readOnly
                type="text" 
                className="ladiui input" 
                value={this.state.params} 
                ref={(textarea) => this.textAreaRef = textarea}
                style={{position:"absolute",
                    left:"-9999px"}}
                />
                
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHistory: (data) => dispatch(historyActions.getHistory(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.historylog });

export default connect(mapStateToProps, mapDispatchToProps)(HistoryLog);