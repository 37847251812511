import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import storeActions from '../../redux/futures/store/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';
import * as storeTypes from '../../redux/futures/store/types';
import ConfirmModal from '../../components/ConfirmModal';
import * as types from '../../redux/futures/tools/types';




class DeleteMember extends React.Component {
 
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            store_info: {},
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: '',
           
            message: ""
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.textAreaRef = React.createRef();
        this.initialState = _.cloneDeep(this.state);
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            if (nextProps.tool.action == types.DELETE_MEMBER_LP) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });  
                if (nextProps.tool.status) {
                    window.LadiUI.toastr('success', '', nextProps.tool.message);
                    this.setState({
                        message: nextProps.tool.result
                    })
                } else {
                    window.LadiUI.toastr('error', '', nextProps.tool.message);
                }
            } 
            if (nextProps.tool.action == types.RESET_MEMBER_LP) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });  
                if (nextProps.tool.status) {
                    window.LadiUI.toastr('success', '', nextProps.tool.message);
                    this.setState({
                        message: nextProps.tool.result
                    })
                } else {
                    window.LadiUI.toastr('error', '', nextProps.tool.message);
                }
            } 
            if (nextProps.store.action == storeTypes.LIST_STORE) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });  
                if (nextProps.store.status) {
                    if (nextProps.store.stores.length > 0){
                        this.setState({store_info: nextProps.store.stores[0] });
                    }
                } else {
                    window.LadiUI.toastr('error', '', nextProps.store.message);
                }
            }
        }
    }

    listStores = (resetActivePageFlag = true) => {
       
        const { email } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
  
        const data = {
            app_code: 'LP',
            email
        };

        this.props.listStore(data);
    }

    resetMember = () => {
        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const jsonData = {
            email: this.state.email
        } 
        this.props.resetMemberLadiPage(jsonData);
        
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const jsonData = {
            email: this.state.email
        } 
        this.props.deleteMemberLadiPage(jsonData);
        
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

 
 
    onChangeInput = (event) => { 
        this.setState({
            email: event.target.value
        });
    } 
 

    resetForm = (event) => {
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            ..._.cloneDeep(this.initialState)
        })
        
    }

    render() {
        const { email,message, store_info } = this.state;
         
        const title = 'Xóa thành viên trong store';
        const isLoadingStoreInfo = this.props.store.loading && (this.props.store.waitting == storeTypes.LIST_STORE);
        const isLoadingDomain = this.props.tool.loading && (this.props.tool.waitting == types.DELETE_MEMBER_LP);
        const isLoadingReset = this.props.tool.loading && (this.props.tool.waitting == types.RESET_MEMBER_LP);
        
        return (
            <div ref={this.ref} id="modal-delete-member-lp" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-delete-member-lp">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-delete-member-lp"><i className="ladi-icon icon-e-remove" data-dismiss="modal-delete-member-lp" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {/* <form> */}
                            <div className="ladiui-tab-content">
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Email chủ store</label> 
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="email"
                                        validationName="Email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}
                                    /> 
                                       
                                </div> 
                                {store_info&& store_info._id &&
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Thông tin store</label>
                                       <p>Tên store: {store_info.name}</p>
                                       <p>Tên tài khoản: {store_info.fullname}</p>
                                       <p>Tên gói: {store_info.pkg_name}</p>
                                       <p>Ngày hết hạn : {store_info.pkg_exp}</p>
                                </div>
                                }
                            </div>
                        {/* </form> */}

                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-delete-member-lp">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-delete-member-lp" />Đóng
                        </button>
                        <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.listStores}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{paddingRight:'5px'}}></i>  Kiểm tra thông tin store
                         </button>
                         <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.submit}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(isLoadingDomain)?'none':'', paddingRight:'5px'}}></i>  
                                    Xóa thành viên trong store
                         </button>
                         <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.resetMember}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(isLoadingReset)?'none':'', paddingRight:'5px'}}></i>  
                        Reset quyền thành viên
                         </button>
                    </div>
                </div>
                <ConfirmModal
                    id="confirm-delete-member"
                    title="Xóa thành viên trong store"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.submit()}
                    isLoading={isLoadingDomain}
                />
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteMemberLadiPage: (data) => dispatch(toolActions.deleteMemberLadiPage(data)),
        resetMemberLadiPage: (data) => dispatch(toolActions.resetMemberLadiPage(data)),
        listStore: (data) => dispatch(storeActions.list(data)),
    }
}

const mapStateToProps = (state) => ({ tool: {...state.tool}, store:{...state.store} });

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMember);