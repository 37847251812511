import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import Input from '../../components/Input';
import { connect } from 'react-redux';
import partnerActions from '../../redux/futures/partner/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/partner/types';

import Detail from './components/Detail';
import LoadingScene from '../../components/LoadingScene'; 

import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingTable from '../../components/LoadingTable';


class Partner extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,
            code: "",
            fromDate: firstDay,
            toDate: new Date(),
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            commissionList: [],
            currentLog: {},
            excelDatas: [],
            creditLogs :[ ],
            // downloadUrl: '',
            submitData: false
        }
        this.inputsRef = new Set();
        this.selectedLogIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.REPORT_CREDIT_LOGS) {
            
            if (nextProps.status) {
                this.setState({
                    creditLogs: nextProps.creditLogs,
                    excelDatas: nextProps.excelDatas
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
        
         
        if (nextProps.action == types.EXPORT_CREDIT_LOG) {
            setTimeout(() => {
                this.setState({
                    submitData:false
                });
            }, 200);
            if (nextProps.status) {
                
                // this.setState({
                //     downloadUrl: nextProps.url
                // });
            }
        }
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }


        document.getElementById("checkAllItem").checked = false;
        window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
            e.checked = false;
        });


        const { selectedLimit, code } = this.state;
        let { fromDate, toDate } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "from_date": fromDate,
            "to_date": toDate,
            "code": code
        };


        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    } 
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

   
    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedLogIds = [];
        if (checked) {
            this.selectedLogIds = _.map(this.props.creditLogs, store => store._id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, logId) => {
        const { checked } = event.target;
        const { selectedLogIds } = this;

        if (checked) {
            if (!_.includes(selectedLogIds, logId)) {
                selectedLogIds.push(logId);
            }

            if (selectedLogIds.length == this.props.creditLogs.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedLogIds, selectedCommissionId => selectedCommissionId == logId);
        }


    }

    deleteAllItems = () => {
        if (this.selectedLogIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }

        this.props.deleteOrders(this.selectedLogIds);
    }

    deleteItem = (logId) => {
        this.props.deleteOrder(logId);
    }

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (currentLog) => {
        this.setState({
            currentLog,

        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }

    exportExcelAll = () => {
        const { excelDatas,submitData } = this.state;
        if (submitData) return;
        if (excelDatas.length == 0) {
            window.LadiUI.toastr('error', '', 'Data empty');
            return;
        } 
        const data = {
            ids: excelDatas
        }; 
        this.setState({
            submitData:true
        });
        this.props.exportExcel(data);


    }

    exportList = () => {
        const { submitData } = this.state;
        if (submitData) return;
        if (this.selectedLogIds.length <= 0) {
            window.LadiUI.toastr('error', '', 'Please select at least one record');
            return;
        }
        const data = {
            ids: this.selectedLogIds
        }; 
        this.setState({
            submitData:true
        });
        this.props.exportExcel(data);
    }

    /****************************************************************/


    render() {

        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, creditLogs, code } = this.state;
        let fromItem = 0;
        let toItem = 0;
        let lastBalance = 0;
        if (creditLogs.length > 0) {
            lastBalance = creditLogs[0].before_balance ? creditLogs[0].before_balance: 0;
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + creditLogs.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.REPORT_CREDIT_LOGS);
        return (
            <div>
                <h1 className="page-title">Đối soát credit</h1>
                <h3 className="page-title">Số dư đầu kì: {baseHelper.numberWithCommas(lastBalance)}</h3>
                <div>
                    <div className="ladiui-table-actions">

                        <div className="action-item">
                            <ul className="ladiui-navbar -horizontal">
                                <li className="ladiui-dropdown">
                                <a href="#" className={
                                    "ladiui button outline-primary" +(this.state.submitData ? " loader" : "")
                                    
                                    }>Thao tác <i
                                    className="ladi-icon icon-down-arrow"></i></a>
                                    <ul>
                                        <li><a onClick={this.exportExcelAll}>Lưu tất cả</a></li>
                                        <li><a onClick={this.exportList}>Lưu file các dòng đã chọn</a></li>
                                    </ul>
                                </li>
                            </ul>
                            
                        </div>
                        {/* <div className="action-item">
                            {
                                (this.state.downloadUrl != '') &&
                            <a href={this.state.downloadUrl}><button className="ladiui button primary">{this.state.downloadUrl != '' ?'Download':''}</button>
                            </a>
                            }
                        </div> */}
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="code"
                                        validationName="Code"
                                        placeholder="Mã code"
                                        value={code}
                                        onChange={this.onChangeInput}
                                        onKeyDown={this.onKeyDownInput}
                                        validations={{ isRequired: true }}
                                        id="partner-code"
                                    />
                                    <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>
                        <div className="action-item">

                        </div>
                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={this.state.toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div>

                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <div>

                                <div className="ladi-card">

                                    <table className="ladiui table">
                                        <thead>
                                            <tr>
                                                <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th>
                                                {/* <th name="store_id" onClick={this.sort} className="text-left sorting">ID</th> */}
                                                <th name="created_at" onClick={this.sort} className="text-left sorting">Ngày</th>
                                                <th className="text-left">Email user</th>
                                                <th className="text-left">Cộng credit</th>
                                                <th className="text-left">Trừ credit</th>
                                                <th className="text-left">Credit còn lại</th>
                                                <th className="text-left">Mô tả</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(creditLogs, log => {
                                                    let desc = log.description;
                                                    let email = '';
                                                    let de = '';
                                                    if (desc.indexOf('Thanh toán order: ') >= 0) {
                                                        desc = desc.split(': ');
                                                        de = desc[0];
                                                        email = desc[1];
                                                    }else {
                                                        de = log.description;
                                                    }
                                                    const cred = baseHelper.numberWithCommas(log.credit);
                                                    let aft_bl = '';
                                                    if (log.after_balance && log.after_balance != '') {
                                                        aft_bl = baseHelper.numberWithCommas(log.after_balance.toFixed(2));
                                                    }
                                                    const isAdded = (log.type !== 'USER_BUY_ORDER');
                                                    return <tr key={log._id}>
                                                        <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, log._id)} className="ladiui checkbox" />
                                                        </td>
                                                        <td>{baseHelper.formatStrToDate(log.created_at)}</td>
                                                        <td>{email}</td>
                                                        <td>{isAdded ? cred : ''}</td>
                                                        
                                                        <td>{!isAdded ? cred : ''}</td>
                                                        <td>{aft_bl}</td>
                                                        <td>{de}</td>
                                                        {/* <td>
                                                            
                                                            <button className="ladiui button primary open-modal" onClick={() => this.openEditForm(commission)} modal="modal-commissiondetail" >View</button>
                                                             

                                                        </td> */}
                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                                totalRecord <= 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="100%">Không có bản ghi nào</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    {
                        totalRecord > 0 &&
                        <div className="ladiui pagination-block">
                            <div className="ladiui search-actions">
                                <div className="ladiui form-filter">
                                    <div className="ladiui data-limit">
                                        <span>Show</span>
                                        <div className="ladiui per-page">
                                            <div className="ladiui-select-options">
                                                <span className="ladiui-select-init">{selectedLimit}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui showing">{msgShowItem}</div>
                                </div>
                            </div>
                            {
                                totalRecord > 0 && <div className="ladiui pagination">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={selectedLimit}
                                        totalItemsCount={totalRecord}
                                        pageRangeDisplayed={5}
                                        onChange={this.onPageChange}
                                        prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                        nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                        firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                        lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                    />
                                </div>
                            }

                        </div>
                    }
                </div>
                
                {
                    this.state.loadingScene &&
                    <LoadingScene />
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(partnerActions.reportCredit(data)),
        exportExcel: (data) => dispatch(partnerActions.exportExcel(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.partner });

export default connect(mapStateToProps, mapDispatchToProps)(Partner);