import React from 'react';
import PropTypes from 'prop-types';
import userActions from '../../../redux/futures/user/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import Input from '../../../components/Input';


import * as types from '../../../redux/futures/user/types';
const FormData = require('form-data');



class Form extends React.Component {

    static propTypes = {
        currentUser: PropTypes.object
    }

    constructor(props) {
        super(props);
         
    
        this.state = {
            searchStatus: {
                name: appConst.USER_STATUS.ACTIVE.name,
                value: appConst.USER_STATUS.ACTIVE.value,
                selected: appConst.USER_STATUS.ACTIVE,
            },
            currentUser: {
                 
                _id: "",
                fullname: "",

                email: "",

                phone: "",
                status: 1,
                 
                 
            },
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"

        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
       
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.currentUser && this.state.currentUser !== nextProps.currentUser) {
           
            if (nextProps.currentUser && nextProps.currentUser._id){
               
                this.setState({
                    currentUser: nextProps.currentUser
                });
            }
        }
        if (nextProps.action == types.ADMIN_UPDATE_USER) {
            this.setState({
                loading : false,
                classApply : this.state.classNormal
            });

            if (nextProps.status) { 
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                },1000);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const statusSelected = this.state.searchStatus.selected;
        const { currentUser } = this.state;
        
        const jsonData = {
            "full_name": currentUser.fullname,
            "phone": currentUser.phone,
            "time_zone": currentUser.tiomezone,
            "language": currentUser.language,
            "status": currentUser.status,
            "id": currentUser._id
        }
        this.props.update(jsonData);
        
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangeInput = (event) => {
        
        this.setState({
            currentUser: {
                ...this.state.currentUser,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeSearchStatus = (option) => {
        // console.log(option);
        this.setState({
            currentUser: { ...this.state.currentUser, status: option.value },
        }, () => {
             
        });
         
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            affilateInfo: baseHelper.resetState(this.state.affilateInfo),
        });
 
    }

    render() {
        const { searchStatus,currentUser } = this.state;
        
        const title = 'Chỉnh sửa thông tin khách hàng';       

        return (
            <div ref={this.ref} id="modal-unserinfoform" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-unserinfoform"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content">
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-unserinfoform"><i className="ladi-icon icon-e-remove" data-dismiss="modal-unserinfoform" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>
                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                               
                                    
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Email</label>

                                            <input name="email" className="ladiui input" defaultValue={currentUser.email} disabled="disable" />
                                        </div>
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Tên</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="fullname"
                                                validationName="FullName"
                                                placeholder="Tên"
                                                value={currentUser.fullname}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                            />
                                            {/* <input name="full_name" className="ladiui input" value={currentUser.fullname} /> */}
                                        </div>
                                    </div>
                                        
                                        <div className="col-50">
                                            <div className="ladiui form-group">
                                            <label className="ladiui-label">Trạng thái</label>
                                            <div className="ladiui-select-options">
                                            
                                                <span className="ladiui-select-init">{searchStatus.name}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.USER_STATUS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeSearchStatus(option)}><a data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            </div> 
                                            <div className="ladiui form-group">
                                                <label className="ladiui-label">Phone</label>
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="phone"
                                                    validationName="Phone"
                                                    placeholder="Điện thoại"
                                                    value={currentUser.phone || ''}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: false }}
                                                /> 
                                            </div>
                                        </div> 
                                         
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-unserinfoform">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-unserinfoform" />Đóng
                        </button>
                        <button 
                        ref={this.ref}
                        className={this.state.classApply} 
                        onClick={this.submit}
                        disabled={this.state.loading}
                        >
                        <i className="ladi-icon icon-floppy-disk" style={{display:(this.state.loading)?'none':'', paddingRight:'5px'}}></i>  
                            Áp dụng
                         </button>
                       
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (currentUser) => dispatch(userActions.updateUser(currentUser))
    }
}

const mapStateToProps = (state) => ({ ...state.user });

export default connect(mapStateToProps, mapDispatchToProps)(Form);