import React from 'react';
import toolActions from '../../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../../helpers/BaseHelper';
import Input from '../../../components/Input';
import appConst from '../../../config/const';

import * as types from '../../../redux/futures/tools/types';




class AddStorage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            actionObj: {
                add_size: 0,
            },
            selectedAction: appConst.EMAIL_VERIFY_STATUS.ACTIVE,
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: ''
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {


            if (nextProps.action == types.ADD_STORAGE) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    const _seft = this;
                    window.LadiUI.toastr('success', '', nextProps.message);
                    setTimeout(function () {
                        window.LadiUI.closeModal(_seft.ref.current);
                    }, 1000);

                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { actionObj } = this.state;
        console.log('this.props.store_id: ', this.props.store_id);
        const jsonData = {
            store_id: this.props.store_id,

            add_size: actionObj.add_size,
        }
        this.props.addStorage(jsonData);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }


    onChangeInput = (event) => {

        this.setState({
            actionObj: {
                ...this.state.actionObj,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeAction = (option) => {
        // console.log(option);
        this.setState({
            selectedAction: option,
        })
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            user: baseHelper.resetState(this.state.user),
        });
        window.LadiUI.closeModal(this.ref.current);

        // window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
        //     e.classList.remove('error');
        // });
    }

    render() {
        const { actionObj, selectedAction } = this.state;


        const title = 'Mua dữ liệu upload';

        return (
            <div ref={this.ref} id="modal-buy-storage" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-buy-storage"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-buy-storage"><i className="ladi-icon icon-e-remove" data-dismiss="modal-buy-storage" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body" >
                        <div className="ladiui-tab-content">
                            <div className="ladiui form-group">
                                <label className="ladiui-label">Số GB cộng thêm </label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="add_size"
                                    validationName="GB"
                                    placeholder="Tên miền"
                                    value={actionObj.add_size}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true, min: 0, max: 1000 }}
                                />

                            </div>

                        </div>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-buy-storage">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-buy-storage" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                        </button>

                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addStorage: (data) => dispatch(toolActions.addStorage(data))
    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(AddStorage);