import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import * as typesAuth from '../../redux/futures/auth/types';

import EditProfile from './components/EditProfile';
import ChangePassword from './components/ChangePassword';



class Profile extends React.Component {
    constructor(props) {
      super(props);
      const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
      
      this.state = {
        userInfo: userInfo,
        passwordObj: {
          newpass: '',
          confirmpass: ''
        }
      }
    }
    
    componentDidMount() {
        window.LadiUI.init();
       
    }

    componentWillReceiveProps(nextProps) {
      
      if (nextProps.action == typesAuth.UPDATE_ADMIN_INFO) {
        if (nextProps.status) { 
          this.setState({
              userInfo: nextProps.userInfo,
              
          });
        } 
        return;
      }
     
    }
    
    resetForm() {
      window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
          e.classList.remove('error');
      });
    }
    openCreateForm = () => {
        // const userInfo = this.state.userInfo;
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        this.resetForm();

        this.setState({
            userInfo: userInfo,
            passwordObj: {
              newpass: '',
              confirmpass: ''
            }
        })
    }
    onError = () => {
         
      this.setState({
          userInfo: {
              ...this.state.userInfo,
              avatar: appConst.DEFAULT_AVATAR
          }
      });
  }

  copyToClipboard = () => {
    // console.log(store.store_id);
    
      this.textAreaRef.select();
      document.execCommand('copy');
      window.LadiUI.toastr('success', '', 'Đã copy vào clipboard');
    
    
  };

    render() {
        const { userInfo } = this.state;
        const name = (userInfo && userInfo.fullname) ? userInfo.fullname : '';
        const email = (userInfo && userInfo.email) ? userInfo.email : '';
        
        // console.log(baseHelper.getTimeZoneInfo(timezone));
        var date = "September 21, 2011 00:00:00";
        var targetTime = new Date(date);
        var timeZoneFromDB = -7.00; //time zone value from database
        //get the timezone offset from local time in minutes
        var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
        // console.log(offsetTime.toLocaleString());
        // console.log(moment().tz(offsetTime.toString()));
        // console.log(moment.tz.names());
        // console.log(userInfo);
        return (
            <div>
            <h1 className="page-title">Thông tin tài khoản</h1>
            <div className="page-profile ladiui-row flex">
              <div className="box-profile ladiui card text-center col">
                <div className="profile-avatar">
                <img src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR} onError={this.onError} className="ladi-profile-avatar"/>
                </div>
                <div className="profile-info">
                  <h3>{ (name =='') ? email : name } <a className="ladiui open-modal" modal="modal-profile"><i modal="modal-profile" className="ladiui ladi-icon icon-edit open-modal" onClick={this.openCreateForm}/></a>
                  </h3>
                  <ul>
                    <li><i className="ladi-icon icon-phone" /> {(userInfo.phone)? userInfo.phone: 'No Phone'}</li>
                    <li><i className="ladi-icon icon-mail-2" /> {userInfo.email}</li>
                  </ul>
                  <span className="change-password"><a className="ladiui open-modal" modal="modal-password">Đổi mật khẩu</a></span>
                  <div style={{verticalAlign: "middle",textAlign: "center",marginBottom: "20px"}}
                  className ="ladiui button sm outline-primary"
                  onClick={()=>this.copyToClipboard()}>Lấy token Admin</div>              

                  
                </div> 
              </div>
               
            </div>
            <EditProfile 
              userInfo={userInfo}
            />
            <ChangePassword 
              userInfo={userInfo}
            />
            <input 
            readOnly
            type="text" 
            className="ladiui input" 
            value={this.state.userInfo.token} 
            ref={(textarea) => this.textAreaRef = textarea}
            style={{position:"absolute",
                left:"-9999px"}}
            />
          </div>
          
        )
    }
}
 

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, null)(Profile);