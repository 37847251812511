export const MOVE_LADIPAGE = 'MOVE_LADIPAGE';
export const MOVE_ALL_LADIPAGE = 'MOVE_ALL_LADIPAGE';
export const RESTORE_LADIPAGE = 'RESTORE_LADIPAGE';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHECK_DOMAIN = 'CHECK_DOMAIN';
export const DELETE_MEMBER_LP = 'DELETE_MEMBER_LP';
export const RESET_MEMBER_LP = 'RESET_MEMBER_LP';
export const DELETE_DOMAIN = 'DELETE_DOMAIN';
export const BLACK_LIST_DOMAIN = 'BLACK_LIST_DOMAIN';
export const ACTION_SSL = 'ACTION_SSL';
export const CHECK_DOMAIN_SSL = 'CHECK_DOMAIN_SSL';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_LADIFLOW = 'VERIFY_EMAIL_LADIFLOW';
export const VERIFY_DOMAIN = 'VERIFY_DOMAIN';
export const VERIFY_DOMAIN_NEW = 'VERIFY_DOMAIN_NEW';
export const RELOAD_CONFIG_SSL = 'RELOAD_CONFIG_SSL';
export const RECONNECT_REDIS = 'RECONNECT_REDIS';
export const REMOVE_KEY_REDIS = 'REMOVE_KEY_REDIS';
export const BLOCK_DOMAIN = 'BLOCK_DOMAIN';
export const BLOCK_IP_FORM = 'BLOCK_IP_FORM';
export const ADD_DOMAIN_WHITELIST = 'ADD_DOMAIN_WHITELIST';
export const BLOCK_URL = 'BLOCK_URL';
export const BLOCK_PUBLISH = 'BLOCK_PUBLISH';
export const ADD_STORAGE = 'ADD_STORAGE';
export const CHECK_LADI_LINK = 'CHECK_LADI_LINK';
export const BLOCK_LADI_LINK = 'BLOCK_LADI_LINK';
export const EXPORT_REPORT_APOTA = 'EXPORT_REPORT_APOTA';
export const REPORT_TRAFFIC = 'REPORT_TRAFFIC';
export const STOP_URL = 'STOP_URL';
export const ACTIVE_COURSE = 'ACTIVE_COURSE';