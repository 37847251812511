import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../components/Spinner';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import Input from '../../components/Input';
import queryString from 'query-string';


import * as types from '../../redux/futures/auth/types';




class GoogleCallback extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props);
        const callbackObj = queryString.parse(this.props.location.search);
        this.signin(callbackObj);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action == types.OAUTH2_GOOGLE) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);
                this.props.history.push('/');
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    componentWillMount() {
        this.redirectAuth(this.props);
      }

    redirectAuth(props) {
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        if (userInfo) {
            props.history.push('/');
        }
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    signin = (callbackObj) => {
        const jsonData = {
            "code":"4/QAE47Y-MDtocYuxtniSwZT1ID_4BK6fa-wGEJZ3o_Bhj6Qj_CXYT6gofjkJUguLQKK6ovzKUdXr7yr5DrY7OF08",
	        "state":"eyJ1cmxfY2FsbGJhY2siOiJodHRwOi8vbG9jYWxob3N0Ojg4ODgvZG93bmxvYWQuaHRtbCIsImFwcF9jb2RlIjoibHAiLCJwYWNrYWdlX25hbWUiOiJUcmFpbCIsIm1vbnRoIjoiMyIsImFmZmlsYXRlX2lkIjoiNWFnMjM0MjM0YTNxIiwibGFuZ3VhZ2UiOiJ2aSJ9"
        }
        this.props.signin(callbackObj);

    }

    render() {
         
        return (
            <div className="ladiui auth">
                 
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signin: (callbackObj) => dispatch(authActions.oauth2Facebook(callbackObj))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(GoogleCallback);