import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import Input from '../../../components/Input';


import * as types from '../../../redux/futures/auth/types';




class ChangePassword extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            typeInput: 'password',
            iconClass: 'icon-preview',
            passwordObj: {
                oldpass: '',
                newpass: '',
                confirmpass: '',

            }
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.passwordObj && this.state.passwordObj !== nextProps.passwordObj) {
            this.setState({
                passwordObj: nextProps.passwordObj
            });
        }
        if (nextProps.action == types.CHANGE_PASSWORD) {

            if (nextProps.status) {
                window.LadiUI.closeModal(this.ref.current);
                window.LadiUI.toastr('success', '', nextProps.message);
                const that = this;
                setTimeout(() => {
                    that.props.signOut();
                    that.props.history.push('/signin');
                }, 500);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { passwordObj } = this.state;
        if (passwordObj.newpass != passwordObj.confirmpass) {
            window.LadiUI.toastr('error', '', 'Mật khẩu đã nhập không khớp với xác nhận mật khẩu');
            return;
        }
        const jsonData = {
            "old_password": passwordObj.oldpass,
            "new_password": passwordObj.newpass,
            "store_id": "",
            "app_code": "luid"
        }
        this.props.update(jsonData);

    }

    onChangeInput = (event) => {

        this.setState({
            passwordObj: {
                ...this.state.passwordObj,
                [event.target.name]: event.target.value
            }
        });
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.state = {
            passwordObj: {
                newpass: '',
                confirmpass: ''
            }
        }

        window.LadiUI.forEach(window.LadiUI.querySelector("#modal-store .ladiui input"), function (e) {
            e.classList.remove('error');
        });
    }
    showPassword = () => {
        const { typeInput } = this.state;
        if (typeInput == 'password') {
            this.setState({
                typeInput: 'text',
                iconClass: 'icon-b-preview'
            });
        }
        if (typeInput == 'text') {
            this.setState({
                typeInput: 'password',
                iconClass: 'icon-preview'
            });

        }
    }

    render() {
        const { passwordObj, typeInput, iconClass } = this.state;

        const title = 'Đổi mật khẩu đăng nhập';

        return (
            <div ref={this.ref} id="modal-password" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-password"
                onClick={this.resetForm}>
                <div className="ladiui-modal-content">
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-password"><i className="ladi-icon icon-e-remove" data-dismiss="modal-password" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <form>

                            <div className="ladiui-tab-content">
                                <div className="ladiui-row">
                                    <div>
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Mật khẩu cũ</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="oldpass"
                                                validationName="OldPassword"
                                                placeholder="Mật khẩu cũ"
                                                value={passwordObj.oldpass}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                                type={typeInput}
                                            />
                                            <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                                        </div>

                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Mật khẩu mới</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="newpass"
                                                validationName="NewPassword"
                                                placeholder="Mật khẩu mới"
                                                value={passwordObj.newpass}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                                type={typeInput}
                                            />
                                            <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                                        </div>

                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Xác nhận mật khẩu</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="confirmpass"
                                                validationName="ConfirmPassword"
                                                placeholder="Xác nhận mật khẩu"
                                                value={passwordObj.confirmpass}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: false }}
                                                type={typeInput}
                                            />
                                            <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-password">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-password" />Huỷ
                        </button>
                        <button className="ladiui button primary icon-floppy-disk" onClick={this.submit}>
                            Áp dụng
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (passwordObj) => dispatch(authActions.changePassword(passwordObj)),
        signOut: () => dispatch(authActions.signOut())
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);