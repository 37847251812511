import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.INVOICE_LIST: {
            return {
                ...state,
                action: types.INVOICE_LIST,
                invoices: action.status ? action.payload.invoices : [],
                excelDatas: action.status ? action.payload.excelDatas : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.INVOICE_DETAIL: {
            return {
                ...state,
                action: types.INVOICE_DETAIL,
                commissions: action.status ? action.payload.commissions : { },
                status: action.status,
                message: action.message,
            }
        } 
        case types.EXPORT_INVOICE: {
            return {
                ...state,
                action: types.EXPORT_INVOICE,
                url: action.status ? action.payload.url : '',
                status: action.status,
                message: action.message,
            }
        } 
        case types.PAID_INVOICE: {
            return {
                ...state,
                action: types.PAID_INVOICE,
                status: action.status,
                message: action.message,
            }
        } 
        case types.PAID_ALL_INVOICE: {
            return {
                ...state,
                action: types.PAID_ALL_INVOICE,
                status: action.status,
                message: action.message,
            }
        } 
        default:
            return state;
    }
}

