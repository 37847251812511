import React, { Component } from 'react';
import './assets/css/reset.css';
import './assets/css/fonts.css';
import './assets/css/ladiui.css';

import Signin from './pages/auth/Signin';
import ForgottenPass from './pages/auth/ForgottenPassword';
import ResetPass from './pages/auth/ResetPassword';
import FacebookCallback from './pages/auth/FacebookCallback';
import GoogleCallback from './pages/auth/GoogleCallback';
import CompleteRegLayout from './layout/default/apps/CompleteRegLayout';


import Layout from './layout/default/apps/Layout';
import { Switch, Route } from 'react-router-dom';

// import './assets/js/ladiui';
import { connect } from 'react-redux';
import authActions from './redux/futures/auth/actions';
import appConst from './config/const';
import _ from 'lodash';
import baseHelper from './helpers/BaseHelper';
import * as types from './redux/futures/auth/types';


class App extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
    if (userInfo && userInfo.token) {
      const token = userInfo.token;
      // if (this.state.location.pathname == '/')
      this.getAdminInfo(token);
    }
  }

  componentWillMount() {
    this.redirectAuth(this.props);
  }

  redirectAuth(props) {

    const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

    if (!userInfo || !userInfo.token) {
      if (!_.includes(['/signin', '/signup', '/forgottenpass', '/resetpass'], props.location.pathname)) {
        props.history.push('/signin');
      }
      return;
    }

    if (userInfo) {
      if (_.includes(['/signin', '/signup'], props.location.pathname)) {
        props.history.push('/');
      }
    }

  }

  getAdminInfo = (token) => {
    this.props.getAdminInfo(token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      this.props.history.push(nextProps.redirectTo);
    }

    this.redirectAuth(nextProps);
  }


  render() {
    const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
    // console.log('userInfo = ', userInfo);
    if (!userInfo) {
      return (
        <Switch>
          <Route path="/signin" component={Signin} />

          <Route path="/forgottenpass" component={ForgottenPass} />
          <Route path="/resetpass" component={ResetPass} />
          <Route path="/oauth2-facebook" component={FacebookCallback} />
          <Route path="/oauth2-google" component={GoogleCallback} />
        </Switch>
      )
    }
    if (userInfo && !userInfo.is_change_password) {
      // this.props.history.push('/complete-reg');
      if (!_.includes(['/complete-reg'], this.props.location.pathname)) {

        this.props.history.push('/complete-reg');
      }
      // console.log(' need change passsword');
      return <CompleteRegLayout />
    }
    if (userInfo && userInfo.is_change_password) {
      return <Switch>
        <Route path="/" component={Layout} />
      </Switch>
    }
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getAdminInfo: (token) => dispatch(authActions.getAdminInfo(token))
  }
}

const mapStateToProps = (state) => ({
  auth: { ...state.auth }
});


export default connect(mapStateToProps, mapDispatchToProps)(App);
