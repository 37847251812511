import React from 'react';
import PropTypes from 'prop-types';
import commissionActions from '../../../redux/futures/commission/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';


import * as types from '../../../redux/futures/commission/types';




class Detail extends React.Component {

    static propTypes = {
        currentCommission: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            currentCommission: {
                affilate_id: "",
                commission_value: "",
                created_at: "",
                date_invoice: "",
                detail_id: "",
                email: "",
                invoice_id: "",
                ladi_uid: "",
                order_amount: "",
                status: "",
                updated_at: "",
                userInfo: {

                },
                _id: "",

            },
            details: {
                status: 0,
                _id: "",
                detail_id: "",
                affilate_id: "",
                ladi_uid: "",
                invoice_id: "",
                date_invoice: "",
                order_amount: "",
                commission_value: "",
                created_at: "",
                updated_at: "",
                orderID: "",
                orderNo: "",
                packageName: "",
                price: ""
            }
        };

    }


    componentDidMount() {
        window.LadiUI.init();
        this.commissionDetail();
    }

    componentWillReceiveProps(nextProps) {


        if (nextProps.action == types.COMMISSION_DETAIL) {
            if (nextProps.status) {
                this.setState({
                    details: nextProps.commission,
                });
            } else {
                this.setState({
                    details: false,

                });
            }
            return;
        }

        if (nextProps.currentCommission && this.props.currentCommission !== nextProps.currentCommission) {
            this.setState({
                currentCommission: _.pick(nextProps.currentCommission, _.keys(this.state.currentCommission))
            }, () => {
                this.commissionDetail();
            });
        }
    }

    commissionDetail = () => {
        // console.log(this.state.currentCommission);
        const ID = this.state.currentCommission._id;
        const data = {
            id: ID
        }
        this.props.commissionDetail(data);
    }

    render() {
        const { details } = this.state;

        const title = 'Affiliate commission detail';

        return (
            <div id="modal-commissiondetail" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-commissiondetail">
                <div className="ladiui-modal-content" style={{ maxWidth: '800px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-commissiondetail"><i className="ladi-icon icon-e-remove" data-dismiss="modal-commissiondetail" /></button>
                        <h3>Affiliate commission id: {details._id}</h3>
                    </div>
                    <div className="ladiui-modal-body" >

                        <div className="order-box" style={{ width: '750px', margin: '15px' }}>
                            {/* <ul> */}
                            <div className="ladiui-row" style={{ height: '50px' }}>

                                <div className="col-50">
                                    <strong>Giá trị đơn hàng: </strong>
                                    {
                                        details.order_amount && details.order_amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                    }
                                </div>
                                
                                <div className="col-50">

                                    <strong>Ngày lập hoá đơn: </strong>
                                    {
                                        baseHelper.formatStrToDate(details.date_invoice)
                                    }
                                </div>
                            </div>
                            <div className="ladiui-row" style={{ height: '50px' }}>

                                <div className="col-50">
                                    <strong>Tiền hoa hồng: </strong>
                                    {
                                        details.commission_value && details.commission_value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                    }
                                </div>
                                <div className="col-50">
                                    <strong>Mã hoá đơn: </strong>
                                    {
                                        details.invoice_id
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div >
        )


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        commissionDetail: (data) => dispatch(commissionActions.commissionDetail(data))
    }
}

const mapStateToProps = (state) => ({ ...state.commission });

export default connect(mapStateToProps, mapDispatchToProps)(Detail);